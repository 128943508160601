import { useState, useEffect } from "react";
import { Grid, RenderEnderecoPrint } from "../../../../components";
import { SessionService } from "../../../../services";
import { format } from "date-fns";
import {
  formatCelular,
  formatCpf,
  formatPrice,
  formatCnpj,
  formatTelefone,
} from "../../../../utils";
import "./mainPrintLayout.style.css";

const MainPrintLayout = ({ data }) => {
  const sessionService = new SessionService();
  const [empresa, setEmpresa] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(0);
  const aspectLogo = (event) => {
    setAspectRatio(event.target.naturalWidth / event.target.naturalHeight);
  };

  useEffect(() => {
    const data = sessionService.getEmpresa();
    setEmpresa(data);
  }, []);

  return (
    empresa &&
    data && (
      <>
        <Grid
          item
          md={12}
          xs={12}
          className="d-flex justify-content-around align-items-center"
        >
          <div className="d-flex align-items-center">
            {empresa?.imagemLogo ? (
              <img
                className="me-3"
                src={empresa.imagemLogo}
                alt="logo da empresa"
                onLoad={aspectLogo}
                width={100}
                height={aspectRatio <= 1.5 ? 100 : 20}
              />
            ) : null}
            <div className="orcamento-print-fs-07">
              <b>{empresa.razaoSocial}</b>
              <br />
              cnpj - {formatCnpj(empresa.cnpj)}
              <br />
              {`${empresa.enderecoLogradouro} - ${empresa.enderecoLogradouroNumero} - ${empresa.enderecoBairro} - ${empresa.enderecoCidade}-${empresa.enderecoUF}`}
            </div>
          </div>
          <div className="orcamento-print-fs-07">
            {formatTelefone(empresa.telefone)}
            <br />
            {empresa.email}
            <br />
            {data?.vendedor ? <b>Vendedor - {data.vendedor}</b> : ""}
          </div>
        </Grid>
        <Grid item md={12} xs={12} className="mt-4">
          <table className="table">
            <thead style={{ background: "#E7E5E6" }}>
              <tr style={{ background: "#E7E5E6" }}>
                <th className="d-flex justify-content-between align-items-center">
                  <span className="orcamento-print-fs-11 fw-bold">{`CONDICIONAL Nº ${data.codigo}`}</span>
                  <span className="fw-bold">
                    {"Vencimento: "}
                    {data?.dataVencimento &&
                      format(
                        new Date(Date.parse(data?.dataVencimento)),
                        "dd/MM/yyyy"
                      )}
                  </span>
                </th>
              </tr>
            </thead>
          </table>
        </Grid>
        <Grid item md={12} xs={12} className="mt-4 orcamento-print-fs-07">
          <table className="table">
            <thead style={{ background: "#E7E5E6" }}>
              <tr>
                <th className="p-0">DADOS CLIENTE</th>
              </tr>
            </thead>
          </table>
        </Grid>
        <div className="flex-print-container orcamento-print-fs-07">
          <div className="container-item-50">
            <div>
              <div>
                <b className="me-2 fs-7">Cliente:</b>
                <span className="fs-7 fw-normal">
                  {data.cliente?.nomeRazaoSocial}
                </span>
              </div>
              <div>
                <b className="me-2 fs-7">Endereço:</b>
                <span className="fs-7 fw-normal">
                  <RenderEnderecoPrint
                    enderecoCliente={data?.enderecoCliente}
                  />
                </span>
              </div>
              <div>
                <b className="me-2 fs-7">Telefone:</b>
                <span className="fs-7 fw-normal">
                  {`${formatTelefone(data.cliente?.telefone) ?? ""} ${
                    data.cliente?.celular
                      ? " | " + formatCelular(data.cliente?.celular)
                      : ""
                  }`}
                </span>
              </div>
            </div>
          </div>
          <div className="container-item-40">
            <div>
              <div>
                <b className="me-2 fs-7">CNPJ/CPF:</b>
                <span className="fs-7 fw-normal">
                  {data.cliente?.naturezaSocial === "fisica"
                    ? formatCpf(data.cliente?.cnpjCpf)
                    : formatCnpj(data.cliente?.cnpjCpf)}
                </span>
              </div>
              <div>
                <b className="me-2 fs-7">Estado:</b>
                <span className="fs-7 fw-normal">
                  {`${data.enderecoCliente?.estado || ""}`}
                </span>
              </div>
              <div>
                <b className="me-2 fs-7">E-mail:</b>
                <span className="fs-7 fw-normal">{data.cliente?.email}</span>
              </div>
            </div>
          </div>
        </div>
        <Grid item md={12} xs={12} className="mt-4 orcamento-print-fs-07">
          <table className="table">
            <thead style={{ background: "#E7E5E6" }}>
              <tr>
                <th className="p-0">PRODUTOS</th>
              </tr>
            </thead>
          </table>
        </Grid>
        <Grid item md={12} xs={12}>
          <table className="table table-striped">
            <thead>
              <tr className="orcamento-print-fs-07">
                <th>ITEM</th>
                <th style={{ width: "40%" }}>DESCRIÇÃO</th>
                <th className="text-end">UND.</th>
                <th className="text-center">QTD.</th>
                <th style={{ width: "12%" }} className="text-end">
                  VR. UNIT.
                </th>
                <th className="text-end">SUBTOTAL</th>
              </tr>
            </thead>
            <tbody>
              {data?.itens?.length &&
                data?.itens.map((item) => (
                  <tr key={item.id} className="orcamento-print-fs-07">
                    <td>{item.codigo}</td>
                    <td>{item.descricao}</td>
                    <td className="text-end">{item.unidade}</td>
                    <td className="text-center">{item.quantidade}</td>
                    <td className="text-end">
                      {typeof item.valorUnitario == "number"
                        ? item.valorUnitario.toFixed(2)
                        : 0}
                    </td>
                    <td className="text-end">
                      {typeof item.totalItem == "number"
                        ? item.totalItem.toFixed(2)
                        : 0}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          className="mt-4 d-flex flex-column align-items-end orcamento-print-fs-07"
        >
          <div className="d-flex">
            <b className="me-2 fs-7">TOTAL DA NOTA:</b>
            <span className="fs-7 fw-normal">
              {data.totalCondicional
                ? formatPrice(data.totalCondicional)
                : "R$ 0,00"}
            </span>
          </div>
        </Grid>
        <Grid item md={12} xs={12} className="mt-4 orcamento-print-fs-07">
          <table className="table">
            <thead style={{ background: "#E7E5E6" }}>
              <tr>
                <th className="p-0">OBSERVAÇÕES</th>
              </tr>
            </thead>
          </table>
        </Grid>
        <Grid item md={12} xs={12} className="mt-2 orcamento-print-fs-07 px-2">
          <span className="fw-normal">{data.informacaoAdicional}</span>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          className="mt-5 d-flex flex-column justify-content-center"
        >
          <div className="orcamento-line-assinatura"></div>
          <span className="orcamento-print-fs-07 fw-normal text-center">
            Assinatura do Cliente
          </span>
        </Grid>
        <Grid item md={12} xs={12} className="mt-5 d-flex justify-content-end">
          <span className="orcamento-print-fs-07 fw-light">
            Condicional emitida no SIAF LITE – www.siaflite.com.br
          </span>
        </Grid>
      </>
    )
  );
};

export default MainPrintLayout;
