import { useEffect } from "react";
import { ProdutosService } from "../../../../services";
import { formatPrice, responseErros } from "../../../../utils";
import { DashboardCard } from "../../../main/components";

function RelatorioDashboardCmv({
  filtrosAvancados,
  relatoriosDashborad,
  setRelatoriosDashborad,
}) {
  const produtosService = new ProdutosService();

  useEffect(() => {
    dashboardCmv({
      ...filtrosAvancados,
      porIntervalo: [
        {
          ...filtrosAvancados.porIntervalo[0],
          coluna: "data",
        },
      ],
    });
  }, [
    filtrosAvancados.porIntervalo[0].de,
    filtrosAvancados.porIntervalo[0].ate,
  ]);

  async function dashboardCmv(body) {
    const result = await produtosService.getRelatorioDashboardCmv(body);
    if (!result.isAxiosError) {
      setRelatoriosDashborad((prevValue) => ({
        ...prevValue,
        cmv: result.data || 0,
      }));
    } else {
      responseErros(result);
    }
  }

  return (
    <DashboardCard
      title="CMV"
      ActionIcon={
        <i
          className="ph-fill ph-coins"
          style={{ color: "#494C62", fontSize: 22 }}
        ></i>
      }
    >
      <b className="fs-5">{formatPrice(relatoriosDashborad.cmv)}</b>
    </DashboardCard>
  );
}

export default RelatorioDashboardCmv;
