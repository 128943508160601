import { useState, useRef, useEffect } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
} from "../../../../components";
import { PessoaService } from "../../../../services";
import { responseErros } from "../../../../utils";
import { useFiltrosRelatoriosHandlers } from "../../components/use-filtros-relatorios-handlers";
import { value } from "../../../../helpers";

const pessoaService = new PessoaService();

const FormFiltroAvancadoComissaoVendedores = ({
  filtrosAvancados,
  setFiltrosAvancados,
  setFiltrosAplicadosImpressao,
}) => {
  const inputRef = useRef();
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [vendedorList, setVendedorList] = useState([]);
  const { onChangeAutocomplete, onChangeDate } = useFiltrosRelatoriosHandlers(
    setFiltrosAvancados,
    setFiltrosAplicadosImpressao
  );

  const buscarVendedores = () => {
    const filtro = {
      nonPaginated: true,
      vendedor: true,
      tiposPessoaId: 4,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setVendedorList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  useEffect(() => {
    buscarVendedores();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="vendedorId"
              name="Vendedor"
              options={vendedorList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Vendedor"
                  inputRef={inputRef}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("vendedorId", value, "Vendedor")
              }
              value={value.autoComplete(
                vendedorList,
                filtrosAvancados?.vendedorId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoStart"
            name="Data-Emissão-De"
            label="Data Emissão De"
            margin
            format="dd/MM/yyyy"
            value={filtrosAvancados?.dataEmissaoStart || null}
            onChange={(date) =>
              onChangeDate("dataEmissaoStart", date, "Data-Emissão-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoEnd"
            name="Data-Emissão-Até"
            label="Data Emissão Até"
            margin
            format="dd/MM/yyyy"
            value={filtrosAvancados?.dataEmissaoEnd || null}
            onChange={(date) =>
              onChangeDate("dataEmissaoEnd", date, "Data-Emissão-Até")
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoComissaoVendedores;
