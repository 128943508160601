import { TablePrintResumidoComponent } from "../../../../components";
import { format } from "date-fns";
import { formatDate, formatNomes, formatPrice } from "../../../../utils";

const PrintCondicionalResumido = ({
  filtrosAplicadosImpressao,
  relatoriosTotais,
  tituloRelatorio,
  print,
}) => {
  const columns = [
    {
      header: "Código",
      field: "codigo",
    },
    {
      header: "Data Lanç.",
      field: "dataLancamento",
      render: ({ dataLancamento }) =>
        format(formatDate.received(dataLancamento), "dd/MM/yyyy"),
    },
    {
      header: "Data Venc.",
      field: "dataVencimento",
      render: ({ dataVencimento }) =>
        format(formatDate.received(dataVencimento), "dd/MM/yyyy"),
    },
    {
      header: "Cliente",
      field: "cliente",
      render: ({ cliente }) => cliente && formatNomes(cliente.substring(0, 30)),
    },
    {
      header: "Vendedor",
      field: "vendedor",
      render: ({ vendedor }) =>
        vendedor && formatNomes(vendedor.substring(0, 20)),
    },
    {
      header: "Total",
      field: "valorTotal",
      fieldTotal: "total",
      render: ({ valorTotal }) => formatPrice(valorTotal, false),
    },
  ];
  return (
    <TablePrintResumidoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais.movimentacao}
      totais={relatoriosTotais.totais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      itensPorPagina={31}
    />
  );
};

export default PrintCondicionalResumido;
