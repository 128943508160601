import { useState, useEffect } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "../../../../components";
import { PessoaService } from "../../../../services";
import { responseErros } from "../../../../utils";
import { listaStatus, listaTiposContasReceber } from "./entities/constantes";
import { value } from "../../../../helpers";
import { useFiltrosRelatoriosHandlers } from "../../components/use-filtros-relatorios-handlers";

const pessoaService = new PessoaService();

const FormFiltroAvancadoHistoricoPagamentosReceber = ({
  filtrosAvancados,
  setFiltrosAvancados,
  setFiltrosAplicadosImpressao,
}) => {
  const [clienteList, setClienteList] = useState([]);
  const [listaStatusFiltrado, setListaStatusFiltrado] = useState(listaStatus);
  const { onChangeAutocomplete, onChangeDate, onChangeInput } =
    useFiltrosRelatoriosHandlers(
      setFiltrosAvancados,
      setFiltrosAplicadosImpressao
    );

  useEffect(() => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      status: [],
      tipo: "TITULO_NORMAL",
    });
  }, []);

  function resetarFiltrosDeStatus() {
    setFiltrosAvancados((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp.status;
      return prevStateTemp;
    });
    setFiltrosAplicadosImpressao((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp["STATUS"];
      return prevStateTemp;
    });
    setListaStatusFiltrado(listaStatus);
  }

  const isStatusParcial = (status) =>
    status.value !== "BAIXADO_PARCIAL" &&
    (filtrosAvancados.status?.length || 0) <= 1 &&
    (status.value === "ABERTO" || status.value === "BAIXADO");

  const onChangeMultipleStatus = (valores = []) => {
    if (!valores.length) {
      resetarFiltrosDeStatus();
      return;
    }
    const addValorParcial = valores.every((status) => isStatusParcial(status));
    const updatedValores = addValorParcial
      ? [
          ...valores,
          {
            id: 2,
            label: "Baixado Parcial",
            value: "BAIXADO_PARCIAL",
            cor: "#EA9D2B",
          },
        ]
      : valores;
    updateFilters(updatedValores);
    updateListaStatus(updatedValores);
  };

  const updateFilters = (valores) => {
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      status: valores,
    }));
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      STATUS: valores.map((valor) => {
        return (
          <div key={valor.id} className="d-flex align-items-center">
            <i
              className="ph-fill ph-circle"
              style={{ color: `${valor.cor}`, fontSize: 10 }}
            ></i>
            {valor.label}
          </div>
        );
      }),
    }));
  };

  const updateListaStatus = (valores) => {
    const listaTemp = listaStatus.filter(
      (status) => !valores.some((updateStatus) => updateStatus.id === status.id)
    );
    setListaStatusFiltrado(listaTemp);
  };

  useEffect(() => {
    buscarClientes();
  }, []);

  const buscarClientes = () => {
    const filtros = {
      nonPaginated: true,
      tiposPessoaId: 1,
    };
    pessoaService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setClienteList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            id="pessoaClienteId"
            name="Pagador"
            options={clienteList}
            noOptionsText="Sem opções"
            autoHighlight
            margin="normal"
            getOptionLabel={(option) =>
              option.nomeRazaoSocial ? option.nomeRazaoSocial : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pagador"
                variant="outlined"
                margin="normal"
              />
            )}
            onChange={(e, value) =>
              onChangeAutocomplete("pessoaClienteId", value, "Pagador")
            }
            value={value.autoComplete(
              clienteList,
              filtrosAvancados?.pessoaClienteId
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Tipo"
            id="tipo"
            name="tipo"
            margin="normal"
            variant="outlined"
            value={value.text(filtrosAvancados?.tipo)}
            onChange={(event) => onChangeInput(event, "Tipo")}
          >
            {listaTiposContasReceber.map((tipo, index) => (
              <MenuItem key={index} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoStart"
            name="Data-Lançamento-De"
            label="Data Lançamento De"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataLancamentoStart)}
            onChange={(date) =>
              onChangeDate("dataLancamentoStart", date, "Data-de-Lançamento-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoEnd"
            name="Data-Lançamento-Até"
            label="Data Lançamento Até"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataLancamentoEnd)}
            onChange={(date) =>
              onChangeDate("dataLancamentoEnd", date, "Data-Lançamento-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataVencimentoStart"
            name="Data-Vencimento-De"
            label="Data Vencimento De"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataVencimentoStart)}
            onChange={(date) =>
              onChangeDate("dataVencimentoStart", date, "Data-Vencimento-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataVencimentoEnd"
            name="Data-Vencimento-Até"
            label="Data Vencimento Até"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataVencimentoEnd)}
            onChange={(date) =>
              onChangeDate("dataVencimentoEnd", date, "Data-Vencimento-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataPagamentoStart"
            name="Data-Pagamento-De"
            label="Data Pagamento De"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataPagamentoStart)}
            onChange={(date) =>
              onChangeDate("dataPagamentoStart", date, "Data-Pagamento-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataPagamentoEnd"
            name="Data-Pagamento-Até"
            label="Data Pagamento Até"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataPagamentoEnd)}
            onChange={(date) =>
              onChangeDate("dataPagamentoEnd", date, "Data-Pagamento-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              multiple
              id="status"
              name="status"
              options={listaStatusFiltrado}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => {
                return (
                  <div>
                    <i
                      className="ph-fill ph-circle"
                      style={{ color: `${option.cor}`, marginRight: "10px" }}
                    ></i>
                    {option.label}
                  </div>
                );
              }}
              onChange={(_, newValue) => onChangeMultipleStatus(newValue)}
              value={filtrosAvancados?.status ? filtrosAvancados.status : []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  label="Status"
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default FormFiltroAvancadoHistoricoPagamentosReceber;
