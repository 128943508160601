import React, { useRef, useState } from "react";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "../../../../components";

const FiltroCustom = ({
  buscaFiltro,
  setBuscaFiltro,
  listaPessoasFiltro,
  setListaPessoasFiltro,
  pessoaFiltro,
  setPessoaFiltro,
  buscarPessoas,
  filtrarListaPorPessoa,
  sendServer,
  columns,
}) => {
  const inputCliente = useRef();
  const [tipoBusca, setTipoBusca] = useState("cliente");
  const filtros = {
    orderBy: "updatedAt",
    order: "desc",
    page: "1",
  };
  let colunaFiltroSimples = columns.find((column) => column.filtrar);

  const handleChange = (event) => {
    setTipoBusca(event.target.value);
  };

  const onSearchChangePessoas = (event) => {
    if (
      event.key === "Enter" &&
      inputCliente.current === document.activeElement
    ) {
      if (event.target?.value?.length > 2) {
        const eventValue = event.target?.value;
        let filtros = {
          nomeRazaoSocial: eventValue,
          apelidoFantasia: eventValue,
        };
        buscarPessoas(filtros);
      }
    }
  };

  const labelPesquisar = () => {
    return colunaFiltroSimples
      ? `Pesquisar por ${colunaFiltroSimples.headerName}`
      : "Pesquisar";
  };

  const pesquisar = (e) => {
    e.preventDefault();
    const tipoFiltro = "simples";
    filtros.page = "1";
    if (buscaFiltro) {
      let queryBusca = {};
      if (colunaFiltroSimples) {
        queryBusca[colunaFiltroSimples.field] = buscaFiltro;
      }
      const retorno = {
        tipoFiltro,
        filtros: {
          ...filtros,
          ...queryBusca,
        },
      };
      sendServer(retorno);
    } else {
      sendServer({ tipoFiltro, filtro: filtros });
    }
    setPessoaFiltro(null);
    setListaPessoasFiltro([]);
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <div>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={tipoBusca}
            onChange={handleChange}
          >
            <FormControlLabel
              value="cliente"
              control={<Radio />}
              label="Cliente"
            />
            <FormControlLabel
              value={colunaFiltroSimples.field}
              control={<Radio />}
              label={colunaFiltroSimples.headerName}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="p-2">
        {tipoBusca === "cliente" ? (
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              sx={{ width: "16.6rem" }}
              id="clienteId"
              name="clienteId"
              options={listaPessoasFiltro}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial}
              loadingText="Carregando"
              noOptionsText={
                document.getElementById("clienteId")?.value.length >= 1 ? (
                  <Grid container spacing={1}>
                    <Grid item xs={7} className="mt-2" fullWidth>
                      Nenhum cliente encontrado
                    </Grid>
                  </Grid>
                ) : (
                  "Digite e pressione Enter"
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pesquisar por Cliente"
                  variant="outlined"
                  margin="normal"
                  onKeyDown={(e) => onSearchChangePessoas(e)}
                  inputRef={inputCliente}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      </>
                    ),
                  }}
                />
              )}
              onChange={(_, newValue) => {
                filtrarListaPorPessoa(newValue);
                setPessoaFiltro(newValue);
              }}
              value={pessoaFiltro}
            />
          </FormControl>
        ) : (
          <form onSubmit={(e) => pesquisar(e)}>
            <TextField
              id="pesquisar"
              label={labelPesquisar()}
              fullWidth
              value={buscaFiltro}
              margin="normal"
              onChange={(e) => setBuscaFiltro(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={pesquisar}>
                      <i
                        className="ph-bold ph-magnifying-glass"
                        style={{ color: "#494C62", fontSize: 18 }}
                      ></i>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        )}
      </div>
    </div>
  );
};

export default FiltroCustom;
