import { useState, useRef } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "../../../../components";
import { PessoaService } from "../../../../services";
import { responseErros, timeoutBuscaAutocomplete } from "../../../../utils";
import { useFiltrosRelatoriosHandlers } from "../../components/use-filtros-relatorios-handlers";
import { value } from "../../../../helpers";

const pessoaService = new PessoaService();

const FormFiltroAvancadoCondicional = ({
  filtrosAvancados,
  setFiltrosAvancados,
  setFiltrosAplicadosImpressao,
}) => {
  const inputRefCliente = useRef();
  const inputRefVendedor = useRef();
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const { onChangeInput, onChangeAutocomplete, onChangeDate } =
    useFiltrosRelatoriosHandlers(setFiltrosAvancados, setFiltrosAplicadosImpressao);

  const listaStatusCondicional = [
    {
      label: "Aberta",
      value: "ABERTA",
      cor: "#4FBB53",
    },
    {
      label: "Fechada",
      value: "FECHADA",
      cor: "#004780",
    },
    {
      label: "Expirada",
      value: "EXPIRADA",
      cor: "#EA9D2B",
    },
  ];

  const buscarClientes = () => {
    const filtros = {
      nonPaginated: true,
      tiposPessoaId: 1,
    };
    pessoaService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setClienteList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const buscarVendedores = () => {
    const filtro = {
      nonPaginated: true,
      vendedor: true,
      tiposPessoaId: 4,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setVendedorList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const onInputChangePessoa = (event, tiposPessoaId) => {
    const inputRefMap = {
      1: {
        inputRef: inputRefCliente,
        buscar: buscarClientes,
      },
      4: {
        inputRef: inputRefVendedor,
        buscar: buscarVendedores,
      },
    };
    const { inputRef, buscar } = inputRefMap[tiposPessoaId] || {};
    if (
      event.key === "Enter" &&
      inputRef?.current === document.activeElement &&
      event.target?.value?.length > 2
    ) {
      setLoadingAutoComplete(true);
      const filtros = {
        nomeRazaoSocial: event.target.value,
        apelidoFantasia: event.target.value,
      };
      timeoutBuscaAutocomplete(buscar?.(filtros));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="codigo"
            name="Código"
            label="Código"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={(e) => onChangeInput(e, "Código")}
            value={value.text(filtrosAvancados?.codigo)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="clienteId"
              name="Cliente"
              options={clienteList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  variant="outlined"
                  onKeyDown={(e) => onInputChangePessoa(e, 1)}
                  inputRef={inputRefCliente}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("clienteId", value, "Cliente")
              }
              value={value.autoComplete(
                clienteList,
                filtrosAvancados?.clienteId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="vendedorId"
              name="Vendedor"
              options={vendedorList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendedor"
                  variant="outlined"
                  onKeyDown={(e) => onInputChangePessoa(e, 4)}
                  inputRef={inputRefVendedor}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("vendedorId", value, "Vendedor")
              }
              value={value.autoComplete(
                vendedorList,
                filtrosAvancados?.vendedorId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoStart"
            name="Data-Lançamento-De"
            label="Data Lançamento De"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataLancamentoStart)}
            onChange={(date) =>
              onChangeDate("dataLancamentoStart", date, "Data-Lançamento-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoEnd"
            name="Data-Lançamento-Até"
            label="Data Lançamento Até"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataLancamentoEnd)}
            onChange={(date) =>
              onChangeDate("dataLancamentoEnd", date, "Data-Lançamento-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataVencimentoStart"
            name="Data-Vencimento-De"
            label="Data Vencimento De"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataVencimentoStart)}
            onChange={(date) =>
              onChangeDate("dataVencimentoStart", date, "Data-Vencimento-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataVencimentoEnd"
            name="Data-Vencimento-Até"
            label="Data Vencimento Até"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataVencimentoEnd)}
            onChange={(date) =>
              onChangeDate("dataVencimentoEnd", date, "Data-Vencimento-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            id="status"
            name="status"
            label="Status"
            margin="normal"
            fullWidth
            onChange={(event) => onChangeInput(event, "Status")}
            value={value.text(filtrosAvancados?.status)}
          >
            {listaStatusCondicional.map((status, index) => (
              <MenuItem key={index} value={status.value}>
                <i
                  className="ph-fill ph-circle"
                  style={{ color: `${status.cor}` }}
                ></i>
                &nbsp;{status.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoCondicional;
