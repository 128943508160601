import { useState } from "react";
import {
  Popper,
  Grow,
  Container,
  Loading,
  MenuCustom,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardContent,
  Paper,
  ClickAwayListener,
  MenuItem,
  MenuList,
} from "../../components";
import { history } from "../../services";
import "./list-layout.css";
import { useImportContext } from "../../contexts/import.context";

const ListLayout = ({
  children,
  loading,
  onClickCadastrar,
  title,
  ButtonMenu,
  buttonSelectOpcoes,
  permissaoButton = false,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { initialDtos, initialCollapses } = useImportContext();

  const handleClick = (descricao) => {
    onClickCadastrar(descricao);
    setOpen(false);
  };

  const handleToggle = (event) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const voltarInitial = () => {
    initialDtos();
    initialCollapses();
    history.push("/dashboard");
  };

  return (
    <Container>
      <MenuCustom ButtonMenu={ButtonMenu} onClickVoltar={voltarInitial} />
      <Card>
        <CardHeader
          title={title}
          action={
            <div className="px-2">
              {buttonSelectOpcoes ? (
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  disabled={!permissaoButton}
                >
                  <Button
                    className="button-list"
                    onClick={() => {
                      handleClick();
                      initialCollapses();
                    }}
                  >
                    Adicionar
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <i
                      className="ph-fill ph-caret-down"
                      style={{ color: "#fff", fontSize: 15 }}
                    ></i>
                  </Button>
                </ButtonGroup>
              ) : (
                <Button
                  onClick={() => {
                    handleClick();
                    initialCollapses();
                  }}
                  color="primary"
                  className="button-list"
                  variant="contained"
                  disabled={!permissaoButton}
                >
                  Adicionar
                </Button>
              )}
            </div>
          }
        />
        <Popper
          className="menu-selecao"
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "center botton"
                    ? "center top"
                    : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem>
                    {buttonSelectOpcoes.map((descricao) => (
                      <MenuItem
                        key={descricao}
                        onClick={() => {
                          handleClick(descricao);
                          initialCollapses();
                        }}
                      >
                        {descricao}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <CardContent>{children}</CardContent>
      </Card>
      <Loading loading={loading} />
    </Container>
  );
};

export default ListLayout;
