import { forwardRef, useEffect, useRef } from "react";
import { TextField, InputAdornment } from "@mui/material";
import NumberFormat from "react-number-format";

const TextFieldDecimalNumbers = (props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef?.current) {
      const currentInputRef = inputRef.current;
      const handleFocus = (event) => {
        const length = event.target.value?.length;
        event.target.setSelectionRange(length, length);
      };

      const handleMouseDown = (event) => {
        event.preventDefault();
        currentInputRef.focus();
        handleFocus(event);
      };

      currentInputRef.addEventListener("mousedown", handleMouseDown);
      return () => {
        currentInputRef.removeEventListener("mousedown", handleMouseDown);
      };
    }
  }, []);

  return (
    <TextField
      id={props.id}
      inputRef={inputRef}
      label={props.label}
      name={props.name}
      className={props.className}
      size={props.size}
      variant={props.variant}
      margin={props.margin ?? "normal"}
      disabled={props.disabled}
      error={props.error}
      required={props.required}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onKeyDown={(event) => {
        const length = event.target?.value?.length || 0;
        const acoes = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"];
        if (acoes.includes(event.key)) {
          event.preventDefault();
          event.target.setSelectionRange(length, length);
        } else if (event.key === "Backspace") {
          const value = inputRef.current.value;
          const ultimoDigito = value.lastIndexOf(/\d/);
          const newValue =
            value.substring(0, ultimoDigito) +
            "0" +
            value.substring(ultimoDigito + 1);
          inputRef.current.value = newValue;
          inputRef.current.setSelectionRange(ultimoDigito, ultimoDigito);
        }
        if (props.onKeyDown) props.onKeyDown(event);
      }}
      InputLabelProps={{
        shrink: props.shrink,
        ...props.InputLabelProps,
      }}
      onChange={props.onChange}
      value={props.value ? Number(props.value).toFixed(2) : ""}
      InputProps={{
        inputComponent: CurrencyFormatInput,
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        ...props.InputProps,
      }}
      fullWidth={true}
    />
  );
};

const CurrencyFormatInput = forwardRef((props, ref) => {
  const formatCurrency = (value) => {
    if (!Number(value)) return "0,00";
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    })
      .format(parseFloat(value) / 100)
      .replace("R$", "")
      .trim();
  };

  const handleValueChange = (inputValue) => {
    return parseFloat(inputValue.replace(/[^0-9]/g, "")) || 0;
  };

  return (
    <NumberFormat
      {...props}
      getInputRef={ref}
      fixedDecimalScale
      decimalScale={2}
      inputMode="numeric"
      displayType="input"
      format={(value) => formatCurrency(handleValueChange(value).toString())}
      isNumericString
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === "" || floatValue <= (props?.max || 10000);
      }}
    />
  );
});

export default TextFieldDecimalNumbers;
