import { useState, useEffect } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
} from "../../../../components";
import { responseErros } from "../../../../utils";
import { value } from "../../../../helpers";
import { BancosCaixasService } from "../../../../services";
import { useFiltrosRelatoriosHandlers } from "../../components/use-filtros-relatorios-handlers";

const FormFiltroAvancadoCaixa = ({
  filtrosAvancados,
  setFiltrosAvancados,
  setFiltrosAplicadosImpressao,
}) => {
  const bancosCaixasService = new BancosCaixasService();
  const [contaLista, setContaLista] = useState([]);
  const { onChangeAutocomplete, onChangeDate } = useFiltrosRelatoriosHandlers(
    setFiltrosAvancados,
    setFiltrosAplicadosImpressao
  );

  const buscaContas = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      limite: 20,
    };
    bancosCaixasService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        if (result.data?.rows) {
          setContaLista(result.data?.rows);
        }
      } else {
        responseErros(result);
      }
    });
  };

  useEffect(() => {
    buscaContas({});
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DatePicker
          id="dataReferencia"
          name="Data-Referência"
          label="Data Referência"
          format="dd/MM/yyyy"
          value={value.date(filtrosAvancados?.dataReferencia)}
          onChange={(date) =>
            onChangeDate("dataReferencia", date, "Data-Referência")
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="bancoCaixaId"
          name="Banco-Caixa"
          options={contaLista}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) => (option.nome ? option.nome : "")}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Conta"
              margin="normal"
              variant="outlined"
            />
          )}
          onChange={(_e, value) =>
            onChangeAutocomplete("bancoCaixaId", value, "Banco-Caixa")
          }
          value={value.autoComplete(contaLista, filtrosAvancados?.bancoCaixaId)}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoCaixa;
