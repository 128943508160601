import { useState, useEffect } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "../../../../components";
import {
  ColecoesService,
  EtiquetasService,
  GrupoProdutosService,
  MarcasService,
} from "../../../../services";
import { InputErros, value } from "../../../../helpers";
import { responseErros } from "../../../../utils";
import { useFiltrosRelatoriosHandlers } from "../../components/use-filtros-relatorios-handlers";

const colecoesService = new ColecoesService();
const marcasService = new MarcasService();
const etiquetasService = new EtiquetasService();
const grupoProdutosService = new GrupoProdutosService();

const FormFiltroAvancadoInventario = ({
  filtrosAvancados,
  setFiltrosAvancados,
  setFiltrosAplicadosImpressao,
}) => {
  const [grupoProdutoList, setGrupoProdutoList] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [colecoes, setColecoes] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const {
    onChangeInput,
    onChangeAutocomplete,
    onChangeDate,
    onChangeCheckbox,
  } = useFiltrosRelatoriosHandlers(
    setFiltrosAvancados,
    setFiltrosAplicadosImpressao
  );
  const listaTipos = [
    {
      label: "Preço de Compra",
      value: "precoCompra",
    },
    {
      label: "Preço de Custo",
      value: "precoCusto",
    },
  ];

  useEffect(() => {
    buscarEtiquetas();
    buscarMarcas();
    buscarColecoes();
    buscarGruposProduto();
  }, []);

  const buscarGruposProduto = () => {
    const filtros = {
      nonPaginated: true,
    };
    grupoProdutosService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setGrupoProdutoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarEtiquetas = async function () {
    const filtros = {
      nonPaginated: true,
      ativado: true,
      tipo: "PRODUTO",
    };
    const result = await etiquetasService.getAll(filtros);
    if (!result.isAxiosError) {
      setEtiquetas(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscarMarcas = async function (filter) {
    const result = await marcasService.getAll(filter);
    if (!result.isAxiosError) {
      setMarcas(result.data.rows);
    } else {
      responseErros(result);
    }
  };

  const buscarColecoes = async function (filter) {
    const result = await colecoesService.getAll(filter);
    if (!result.isAxiosError) {
      setColecoes(result.data.rows);
    } else {
      responseErros(result);
    }
  };

  const onChangeMultiple = (valores, name) => {
    if (!valores?.length) {
      resetarFiltros(name);
      return;
    }
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      [name]: valores.map(({ id }) => id),
    }));
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [formatChaveFiltroImpressao(name)]: formatValorFiltroImpressao(
        valores,
        name
      ),
    }));
  };

  function resetarFiltros(name) {
    setFiltrosAvancados((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp[name];
      return prevStateTemp;
    });

    setFiltrosAplicadosImpressao((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp[formatChaveFiltroImpressao(name)];
      return prevStateTemp;
    });
  }

  function formatChaveFiltroImpressao(name) {
    return name.endsWith("Id") ? name.slice(0, -2).toUpperCase() : name;
  }

  function formatValorFiltroImpressao(valores, name) {
    switch (name) {
      case "etiquetasId":
        return valores.map((valor) => renderInputEtiqueta(valor));
      case "marcasId":
        return valores.map((valor) => valor.nome);
      case "colecoesId":
        return valores.map((valor) => valor.descricao);
      default:
        return valores;
    }
  }

  const renderInputEtiqueta = (option) => {
    if (!option) return;
    return (
      <div className="d-flex align-items-center">
        <i
          className="ph-fill ph-bookmark-simple"
          style={{
            color: option?.cor,
            marginRight: "10px",
            fontSize: "21px",
          }}
        ></i>
        {option?.descricao}
      </div>
    );
  };

  const getMultipleAutocompleteValues = (valores, lista) => {
    if (valores) {
      return valores.map((valor) => lista.find((item) => item.id === valor));
    }
    return [];
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataReferencia"
            name="dataReferencia"
            label="Data Referência"
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataReferencia)}
            margin="normal"
            onChange={(date) =>
              onChangeDate("dataReferencia", date, "Data-Referência")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Tipo Preço"
            id="tipoPreco"
            name="tipoPreco"
            variant="outlined"
            value={value.text(filtrosAvancados?.tipoPreco)}
            margin="normal"
            onChange={(event) => onChangeInput(event, "Tipo")}
          >
            <MenuItem value={null}>
              <p></p>
            </MenuItem>
            {listaTipos.map((tipo) => (
              <MenuItem key={tipo.value} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="etiquetasId"
          name="etiquetasId"
          options={etiquetas}
          noOptionsText="Sem opções"
          autoHighlight
          filterSelectedOptions
          getOptionLabel={(option) => {
            return (
              <div>
                <i
                  className="ph-fill ph-bookmark-simple"
                  style={{ color: `${option?.cor}`, marginRight: "10px" }}
                ></i>
                {option?.descricao}
              </div>
            );
          }}
          onChange={(_, newValue) => onChangeMultiple(newValue, "etiquetasId")}
          value={getMultipleAutocompleteValues(
            filtrosAvancados?.etiquetasId,
            etiquetas
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              margin="normal"
              label="Etiquetas"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="marcasId"
          name="marcasId"
          options={marcas}
          noOptionsText="Sem opções"
          autoHighlight
          filterSelectedOptions
          getOptionLabel={(option) => option?.nome || ""}
          onChange={(_, newValue) => onChangeMultiple(newValue, "marcasId")}
          value={getMultipleAutocompleteValues(
            filtrosAvancados?.marcasId,
            marcas
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              margin="normal"
              label="Marcas"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="colecoesId"
          name="colecoesId"
          options={colecoes}
          noOptionsText="Sem opções"
          autoHighlight
          filterSelectedOptions
          getOptionLabel={(option) => option?.descricao || ""}
          onChange={(_, newValue) => onChangeMultiple(newValue, "colecoesId")}
          value={getMultipleAutocompleteValues(
            filtrosAvancados?.colecoesId,
            colecoes
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              margin="normal"
              label="Coleções"
            />
          )}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="grupoProdutoId"
              name="grupoProdutoId"
              options={grupoProdutoList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option.descricao : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Grupo de Produto"
                  variant="outlined"
                  margin="normal"
                  error={inputErros.get("grupoProdutoId")}
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                onChangeAutocomplete(
                  "grupoProdutoId",
                  newValue,
                  "Grupo de Produtos"
                );
              }}
              value={value.autoComplete(
                grupoProdutoList,
                filtrosAvancados?.grupoProdutoId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Ordenar Por"
            id="ordenacao"
            name="ordenacao"
            variant="outlined"
            value={value.text(filtrosAvancados?.ordenacao)}
            margin="normal"
            onChange={(event) => {
              setFiltrosAvancados({
                ...filtrosAvancados,
                [event.target.name]: event.target.value ?? null,
              });
            }}
          >
            <MenuItem value="codigo">Código</MenuItem>
            <MenuItem value="produto">Descrição</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            id="numeroPagina"
            name="numeroPagina"
            label="Nº da Página"
            variant="outlined"
            type="number"
            fullWidth
            onChange={(e) => onChangeInput(e, "Nº-da-Página")}
            margin="normal"
            value={value.text(filtrosAvancados?.numeroPagina)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label="Ignorar Estoque Zerado"
            labelPlacement="end"
            control={
              <Checkbox
                name="ignorarEstoqueZero"
                checked={filtrosAvancados?.ignorarEstoqueZero}
                onChange={(event) =>
                  onChangeCheckbox(event, "Ignorar-Estoque-Zerado")
                }
                color="primary"
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormFiltroAvancadoInventario;
