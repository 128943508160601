import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  TextField,
  Button,
  Chip,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  Fab,
  Autocomplete,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import { notification, ModeloGradeService } from "../../../services";
import { InputErros, PermisoesHelper } from "../../../helpers";
import { responseErros, userLogs } from "../../../utils";
import { modeloGradeValidator } from "./middlewares";
import "./addModeloGrade.styles.css";

const AddGrupoPessoas = ({ match }) => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("modelos-grade-visualizar");
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [initialModeloGradeState, setInitialModeloGradeState] = useState(null);
  const [modeloGrade, setModeloGrade] = useState({});
  const [initialVariacaoModeloGradeState, setInitialVariacaoModeloGradeState] =
    useState([]);
  const [variacaoModeloGrade, setVariacaoModeloGrade] = useState([]);
  const [valorVariacaoModeloGrade, setValorVariacaoModeloGrade] = useState({
    valor: null,
  });
  const [deletedVariacaoIds, setDeleteVariacaoIds] = useState([]);
  const [deletedValorVariacao, setDeleteValorVariacao] = useState([]);
  const [userLog, setUserLog] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [variacaoModeloGradeId, setVariacaoModeloGradeId] = useState(null);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const modeloGradeService = new ModeloGradeService();

  const listaVariacoes = [
    { id: 1, descricao: "TAMANHO" },
    { id: 2, descricao: "COR" },
  ];

  useEffect(() => {
    if (!id) {
      return setVariacaoModeloGrade((prevState) => [
        ...prevState,
        {
          id: prevState.length + 1,
          descricao: "",
          tipoEixo:
            prevState.length === 0
              ? "x"
              : prevState.length === 1
              ? "y"
              : prevState.length === 2
              ? "z"
              : prevState.length > 2
              ? `z-${prevState.length - 2}`
              : "",
          valorVariacaoModeloGrade: [],
        },
      ]);
    }
    buscaModeloGrade();
  }, [id]);

  const buscaModeloGrade = async () => {
    setLoading(true);
    const result = await modeloGradeService.getById(id);
    setInitialModeloGradeState(result.data);
    setModeloGrade(result.data);
    setInitialVariacaoModeloGradeState(result.data?.variacaoModeloGrade);
    setVariacaoModeloGrade(result.data?.variacaoModeloGrade);
    setLoading(false);
  };

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(modeloGrade);
    setUserLog(logs);
  }

  const handleInputChange = (e) => {
    e.persist();
    setModeloGrade((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleVariacaoChange = (descricao, id) => {
    const index = variacaoModeloGrade.findIndex((x) => x.id === id);
    setVariacaoModeloGrade((prevState) => [
      ...prevState.map((item, _index) =>
        _index === index
          ? {
              ...item,
              descricao,
            }
          : item
      ),
    ]);
  };

  const handleValorVariacaoInputChange = (e) => {
    e.persist();
    setValorVariacaoModeloGrade({ valor: e.target.value.toUpperCase() });
  };

  const handleAddValorVariacao = () => {
    const index = variacaoModeloGrade.findIndex(
      (x) => x.id === variacaoModeloGradeId
    );

    setVariacaoModeloGrade((prevState) => [
      ...prevState.map((item, _index) =>
        _index === index
          ? {
              ...item,
              valorVariacaoModeloGrade: [
                ...item.valorVariacaoModeloGrade,
                valorVariacaoModeloGrade,
              ],
            }
          : item
      ),
    ]);

    setValorVariacaoModeloGrade(null);
    handleCloseDialog();
  };

  const handleDeleteVariacao = (id) => {
    if (variacaoModeloGrade.length === 1) {
      return notification.erroValidacao("Ter ao menos 1 variação");
    }
    setDeleteVariacaoIds((prevState) => [...prevState, id]);
    const index = variacaoModeloGrade.findIndex((x) => x.id === id);
    setVariacaoModeloGrade((prevState) => [
      ...prevState.filter((item, _index) => _index !== index),
    ]);
  };

  const handleChipDeleteValorVariacao = (variacaoId, valor) => {
    setDeleteValorVariacao((prevState) => [
      ...prevState,
      { variacaoId, valor },
    ]);
    const index = variacaoModeloGrade.findIndex((x) => x.id === variacaoId);
    setVariacaoModeloGrade((prevState) => [
      ...prevState.map((item, _index) =>
        _index === index
          ? {
              ...item,
              valorVariacaoModeloGrade: item?.valorVariacaoModeloGrade.filter(
                (x) => x.valor !== valor
              ),
            }
          : item
      ),
    ]);
  };

  useEffect(() => {
    setModeloGrade((prevState) => ({
      ...prevState,
      variacaoModeloGrade,
      deletedVariacaoIds,
      deletedValorVariacao,
    }));
  }, [deletedVariacaoIds, deletedValorVariacao, variacaoModeloGrade]);

  const handleClickOpenDialog = (id) => {
    setVariacaoModeloGradeId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setValorVariacaoModeloGrade(null);
    setOpenDialog(false);
  };

  const handleCreateNewVariation = () => {
    if (variacaoModeloGrade.length >= 2) {
      return notification.alertaGenericos("Limite de variações excedido");
    }
    if (
      variacaoModeloGrade.length &&
      !variacaoModeloGrade[variacaoModeloGrade.length - 1]?.descricao
    ) {
      return notification.alertaGenericos(
        "Inserir descrição da variação antes de adicionar uma nova"
      );
    }
    setVariacaoModeloGrade((prevState) => [
      ...prevState,
      {
        id: prevState.length + 1,
        newVariation: true,
        descricao:
          listaVariacoes.find(
            (value) => value?.descricao !== variacaoModeloGrade?.[0]?.descricao
          )?.descricao || "",
        tipoEixo:
          prevState.length === 0
            ? "x"
            : prevState.length === 1
            ? "y"
            : prevState.length === 2
            ? "z"
            : prevState.length > 2
            ? `z-${prevState.length - 2}`
            : "",
        valorVariacaoModeloGrade: [],
      },
    ]);
  };

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    if (modeloGrade)
      modeloGradeValidator
        .validate(modeloGrade, { abortEarly: false })
        .then(async () => {
          if (id) {
            const response = await modeloGradeService.atualizar(
              id,
              modeloGrade
            );
            setLoading(false);
            if (!response.isAxiosError) {
              buscaModeloGrade();
              notification.alteracaoSucesso();
              history.back();
            } else {
              responseErros(response);
            }
          } else {
            const response = await modeloGradeService.cadastrar(modeloGrade);
            setLoading(false);
            if (!response.isAxiosError) {
              notification.cadastroSucesso();
              if (indiceSelecionado === 0) {
                history.back();
              } else {
                handleCancelar();
              }
            } else {
              responseErros(response);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          inputErros.set(err);
        });
  };

  const handleCancelar = () => {
    setModeloGrade(initialModeloGradeState);
    setVariacaoModeloGrade(initialVariacaoModeloGradeState);
    setValorVariacaoModeloGrade({ valor: null });
    setDeleteVariacaoIds([]);
    setDeleteValorVariacao([]);
  };

  return (
    <AddLayout
      id={id}
      title={
        modeloGrade?.id ? "Editar modelo de grade" : "Cadastrar modelo de grade"
      }
      onClickSalvar={handleSubmit}
      loading={loading}
      codigo={id ? `Código Nº ${modeloGrade.codigo}` : null}
      showUpdate={permissoesHelper.temPermisao("modelos-grade-editar")}
      userLog={modeloGrade?.id ? userLog : null}
      onClickLog={onClickLog}
    >
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            id="nome"
            name="nome"
            label="Nome"
            variant="outlined"
            value={modeloGrade?.nome || ""}
            onChange={(e) => {
              setInputErro([]);
              handleInputChange(e);
            }}
            sx={{ ml: 2, mb: 2 }}
            fullWidth
            required
            error={inputErros.get("nome")}
          />
        </Grid>
      </Grid>
      <CardHeader title={"Variações da grade"} />
      <Button
        className="addVariantButton"
        variant="contained"
        color="primary"
        onClick={handleCreateNewVariation}
      >
        <i
          className="ph-fill ph-plus-circle"
          style={{ fontSize: 22, marginRight: 10 }}
        ></i>
        Adicionar variação grade
      </Button>
      <CardContent>
        {variacaoModeloGrade?.map((variacao) => (
          <Grid key={variacao?.id} container spacing={2}>
            <Grid item xs={2}>
              <Autocomplete
                id="variacao"
                name="variacao"
                options={listaVariacoes}
                noOptionsText="Sem opções"
                autoHighlight
                disabled={variacaoModeloGrade?.length >= 2}
                filterSelectedOptions
                getOptionLabel={(option) => option?.descricao || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Variação"
                    variant="outlined"
                    margin="normal"
                    disabled={variacaoModeloGrade?.length >= 2}
                  />
                )}
                onChange={(_, newValue) =>
                  handleVariacaoChange(newValue?.descricao, variacao?.id)
                }
                value={listaVariacoes.find(
                  ({ descricao }) => descricao === variacao?.descricao
                )}
              />
              {/* <TextField
                id={variacao?.descricao}
                name="variacao"
                label="Variação"
                variant="outlined"
                margin="normal"
                value={variacao?.descricao || ""}
                onChange={(e) => handleVariacaoInputChange(e, variacao?.id)}
                fullWidth
                required
              /> */}
            </Grid>
            <Grid item xs={6}>
              <Paper component="ul" variant="outlined" className="paper">
                {variacao?.valorVariacaoModeloGrade.length > 0 ? (
                  variacao?.valorVariacaoModeloGrade.map((data) => {
                    return (
                      <Chip
                        key={data.valor}
                        className="chip"
                        label={data.valor}
                        onDelete={() => {
                          handleChipDeleteValorVariacao(
                            variacao?.id,
                            data.valor
                          );
                        }}
                      />
                    );
                  })
                ) : (
                  <Typography className="placeholderGrade">
                    Clique no botão ao lado
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid item>
              <Fab
                className="addVariantValueButton"
                color="primary"
                size="small"
                ariaLabel="remove"
                onClick={() => handleClickOpenDialog(variacao?.id)}
              >
                <i
                  className="ph-fill ph-plus-circle"
                  style={{ fontSize: 22 }}
                ></i>
              </Fab>
            </Grid>
            <Grid item>
              <Fab
                className="addVariantValueButton"
                color="default"
                size="small"
                ariaLabel="remove"
                onClick={() => handleDeleteVariacao(variacao?.id)}
              >
                <i
                  className="ph-fill ph-trash"
                  style={{ color: "#494C62", fontSize: 18 }}
                ></i>
              </Fab>
            </Grid>
          </Grid>
        ))}
      </CardContent>
      <Dialog
        disableEscapeKeyDown
        fullWidth
        PaperComponent="div"
        open={openDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Card className="card">
            <CardHeader title={"Valor de variação"} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleAddValorVariacao();
                    }}
                  >
                    <TextField
                      id="valorVariacaoModeloGrade"
                      name="valorVariacaoModeloGrade"
                      label="Valor de variação"
                      variant="outlined"
                      margin="normal"
                      value={valorVariacaoModeloGrade?.valor || ""}
                      onChange={handleValorVariacaoInputChange}
                      fullWidth
                      required
                    />
                  </form>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                className="adicionarButton"
                variant="contained"
                color="primary"
                onClick={handleAddValorVariacao}
              >
                <i
                  className="ph-fill ph-check-circle"
                  style={{ fontSize: 22, marginRight: 10 }}
                ></i>
                Salvar
              </Button>
              <Button
                className="cancelButton"
                variant="contained"
                color="padrao"
                onClick={handleCloseDialog}
              >
                <i
                  className="ph-fill ph-x-circle"
                  style={{ fontSize: 22, marginRight: 10 }}
                ></i>
                Fechar
              </Button>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
    </AddLayout>
  );
};

export default AddGrupoPessoas;
