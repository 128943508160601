import { useEffect, useState } from "react";
import { NfSaidaService } from "../../../../services";
import { formatPrice, responseErros } from "../../../../utils";
import { DashboardCard, DashboardChart } from "../../../main/components";

function RelatorioDashboardVendasMeioPagamentos({
  filtrosAvancados,
  relatoriosDashborad,
  setRelatoriosDashborad,
}) {
  const [loading, setLoading] = useState(false);
  const nfSaidaService = new NfSaidaService();

  useEffect(() => {
    dashboardVendasMeioPagamento({
      ...filtrosAvancados,
      porIntervalo: [
        {
          ...filtrosAvancados.porIntervalo[0],
          coluna: "dataLancamento",
        },
      ],
    });
  }, [
    filtrosAvancados.porIntervalo[0].de,
    filtrosAvancados.porIntervalo[0].ate,
  ]);

  async function dashboardVendasMeioPagamento(body) {
    setLoading(true);
    const result =
      await nfSaidaService.getRelatorioDashboardVendasPorMeioPagamento(body);
    if (!result.isAxiosError) {
      if (!result.data?.length) return;
      setRelatoriosDashborad((prevValue) => ({
        ...prevValue,
        meioPagamento: {
          categories: [],
          series: [
            {
              name: "pagamentos",
              colorByPoint: true,
              data: result.data || [],
            },
          ],
        },
      }));
    } else {
      responseErros(result);
    }
    setLoading(false);
  }

  return (
    <DashboardCard
      title="Vendas por meio de pagamentos"
      chart={true}
      ActionIcon={
        <i
          className="ph-fill ph-credit-card"
          style={{ color: "#494C62", fontSize: 22 }}
        ></i>
      }
    >
      <DashboardChart
        loading={loading}
        data={relatoriosDashborad.meioPagamento}
        options={{
          chart: {
            type: "pie",
          },
          tooltip: {
            shared: true,
            useHTML: true,
            backgroundColor: "#fff",
            borderWidth: 0,
            borderColor: null,
            borderRadius: 4,
            formatter: function () {
              const { point, y } = this;
              return `
                <div className="chart-tip">
                  <span className="title">${point.name}</span>
                    <div className='content'>
                      <span style="color: ${point.color
                }; font-size: 20px;">●</span>
                      <b>${y !== 0 ? formatPrice(y) : formatPrice("0")}</b>
                    </div>
                </div>
              `;
            },
          },
          xAxis: {
            labels: {
              style: {
                fontSize: 10,
              },
            },
          },
          plotOptions: {
            pie: {
              startAngle: 30,
            },
            series: {
              borderRadius: 5,
              dataLabels: {
                style: {
                  fontSize: 10,
                },
                enabled: true,
                distance: 20,
              },
            },
          },
        }}
      />
    </DashboardCard>
  );
}

export default RelatorioDashboardVendasMeioPagamentos;
