import { useState } from "react";
import { Container, DatePicker, Grid } from "../../../components";
import { formatDate } from "../../../utils";
import {
  RelatorioDashboardClientesFaturamento,
  RelatorioDashboardCmv,
  RelatorioDashboardFaturamento,
  RelatorioDashboardProdutosVendidos,
  RelatorioDashboardQtdVendas,
  RelatorioDashboardTicketMedio,
  RelatorioDashboardVendasMeioPagamentos,
} from "./components";

const RelatoriosDashboard = () => {
  const [filtrosAvancados, setFiltrosAvancados] = useState({
    porIntervalo: [
      {
        de: "2025-02-01T13:10:18.128Z",
        ate: new Date().toISOString(),
      },
    ],
  });
  const [relatoriosDashborad, setRelatoriosDashborad] = useState({
    faturamento: 0,
    qtdVendas: 0,
    cmv: 0,
    meioPagamento: { categories: [], series: [] },
    produtosVendidos: { categories: [], series: [] },
    clientesFaturamento: { categories: [], series: [] },
  });

  const handleDateChange = (fieldName, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    const filtro = {
      ...filtrosAvancados,
      porIntervalo: [
        {
          ...filtrosAvancados.porIntervalo[0],
          [fieldName]:
            date != "Invalid Date" ? formatDate.toSend(date) : undefined,
        },
      ],
    };
    setFiltrosAvancados(filtro);
  };

  return (
    <Container>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={6} md={3}>
          <DatePicker
            id="de"
            name="de"
            label="Data Inicial"
            format="dd/MM/yyyy"
            value={filtrosAvancados.porIntervalo[0].de}
            onChange={(date) => handleDateChange("de", date)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DatePicker
            id="ate"
            name="ate"
            label="Data Final"
            format="dd/MM/yyyy"
            value={filtrosAvancados.porIntervalo[0].ate}
            onChange={(date) => handleDateChange("ate", date)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="mt-3">
        <Grid item xs={12} md={3}>
          <RelatorioDashboardFaturamento
            filtrosAvancados={filtrosAvancados}
            relatoriosDashborad={relatoriosDashborad}
            setRelatoriosDashborad={setRelatoriosDashborad}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <RelatorioDashboardQtdVendas
            filtrosAvancados={filtrosAvancados}
            relatoriosDashborad={relatoriosDashborad}
            setRelatoriosDashborad={setRelatoriosDashborad}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <RelatorioDashboardCmv
            filtrosAvancados={filtrosAvancados}
            relatoriosDashborad={relatoriosDashborad}
            setRelatoriosDashborad={setRelatoriosDashborad}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <RelatorioDashboardTicketMedio
            filtrosAvancados={filtrosAvancados}
            relatoriosDashborad={relatoriosDashborad}
            setRelatoriosDashborad={setRelatoriosDashborad}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RelatorioDashboardProdutosVendidos
            filtrosAvancados={filtrosAvancados}
            relatoriosDashborad={relatoriosDashborad}
            setRelatoriosDashborad={setRelatoriosDashborad}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RelatorioDashboardClientesFaturamento
            filtrosAvancados={filtrosAvancados}
            relatoriosDashborad={relatoriosDashborad}
            setRelatoriosDashborad={setRelatoriosDashborad}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RelatorioDashboardVendasMeioPagamentos
            filtrosAvancados={filtrosAvancados}
            relatoriosDashborad={relatoriosDashborad}
            setRelatoriosDashborad={setRelatoriosDashborad}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default RelatoriosDashboard;
