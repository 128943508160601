import { useState, useEffect } from "react";
import { ContasReceberService } from "../../../services";
import { formatPrice, responseErros } from "../../../utils";
import { DashboardCard, DashboardChart } from "./";

const ResumoContasReceber = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ categories: [], series: [] });
  const contasReceberService = new ContasReceberService();

  const buscarResumoContasReceber = () => {
    contasReceberService.getResumoContasReceber().then((result) => {
      if (!result.isAxiosError) {
        gerarDadosDashboard(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  function gerarDadosDashboard(data) {
    setData({
      categories: data?.contas
        ? data.contas.map((item) => `${item.mes}/${item.ano}`)
        : [],
      series: [
        {
          name: "A Vencer",
          color: "#2196F3",
          type: "column",
          borderColor: null,
          data: data.aVencer,
        },
        {
          name: "Vencidas",
          type: "column",
          data: data.vencidas,
          borderColor: null,
          color: "#e44a42",
        },
      ],
    });
  }

  useEffect(() => {
    setLoading(true);
    buscarResumoContasReceber();
    setLoading(false);
  }, []);

  return (
    <>
      <DashboardCard
        title="Contas a Receber"
        chart={true}
        ActionIcon={
          <i
            className="ph-bold ph-currency-dollar"
            style={{ color: "#494C62", fontSize: 22 }}
          ></i>
        }
      >
        <DashboardChart
          loading={loading}
          data={data}
          options={{
            chart: {
              type: "column",
            },
            plotOptions: {
              column: {
                dataLabels: {
                  style: {
                    color: "#666",
                    fontWeight: 400,
                    border: "none",
                  },
                  enabled: true,
                  formatter: function () {
                    if (this.y > 0) {
                      return formatPrice(this.y);
                    }
                  },
                },
              },
            },
            xAxis: {
              labels: {
                style: {
                  fontSize: 10,
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  fontSize: 10,
                },
                formatter: function ({ value }) {
                  if (value >= 1000) {
                    return value / 1000 + "k";
                  }
                  return value;
                },
              },
              stackLabels: {
                style: {
                  color: "#fff",
                  fontWeight: 600,
                  border: "none",
                },
                enabled: true,
              },
            },
            tooltip: {
              formatter: function () {
                const { x, y, series } = this;
                return `
                <div class="chart-tip">
                  <span class="title">${x}</span>
                  <div class='content'>
                    <span style="color: ${
                      series.color
                    }; font-size: 20px;">●</span>
                    <b>${series.name}: </b> ${formatPrice(y)}
                  </div>  
                </div>`;
              },
            },
          }}
        />
      </DashboardCard>
    </>
  );
};

export default ResumoContasReceber;
