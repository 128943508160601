import { Grid, TextareaAutosize } from "../../../../../components";

const CondicionaisCampos = ({ condicional, handleChangeInput }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-3">
          <span className="fw-bold fw-bolder">OBSERVAÇÕES</span>
        </Grid>
        <Grid item xs={12}>
          <TextareaAutosize
            id="informacaoAdicional"
            name="informacaoAdicional"
            disabled={condicional.status !== "ABERTO"}
            className="textRolagem bg-transparent"
            label="Observacao"
            maxLength={550}
            value={condicional?.informacaoAdicional}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CondicionaisCampos;
