import { useState } from "react";
import { RelatoriosLayout } from "../../../layouts";
import {
  FormFiltroAvancado,
FormFiltroAvancadoCmvAnaliseProdutosVendidos,
  FormFiltroAvancadoInventario,
  FormFiltroAvancadoNfEntrada,
} from "./components";
import { NfEntradaService, ProdutosService } from "../../../services";
import { responseErros } from "../../../utils";
import {
  PrintEstoque,
  PrintEstoqueDetalhado,
  PrintEstoqueFinanceiro,
  PrintEstoqueInventario,
  PrintNfEntradaResumido,
  PrintCustoMercadoriaVendida,
  PrintAnaliseProdutosVendidos,
} from "../layouts";
import { PermisoesHelper } from "../../../helpers";
const produtosService = new ProdutosService();
const nfEntradaService = new NfEntradaService();

const RelatoriosEstoque = () => {
  const permissoesHelper = new PermisoesHelper();
  const [loading, setLoading] = useState(false);
  const [filtrosAplicadosImpressao, setFiltrosAplicadosImpressao] = useState(
    {}
  );
  const [filtrosAvancados, setFiltrosAvancados] = useState({});
  const [relatorioEstoque, setRelatorioEstoque] = useState({});

  const subgruposRelatorios = [
    {
      subgrupo: "NF Entrada",
      propriedades: [
        {
          name: "100 - NF Entrada Resumido",
          layoutImpressao: PrintNfEntradaResumido,
          permissao: permissoesHelper.temPermisao(
            "relatorio-nf-entrada-resumido"
          ),
          funcaoBuscarDadosRelatorio: buscarRelatorioNfEntradaResumido,
          formulario: FormFiltroAvancadoNfEntrada,
        },
      ],
    },
    {
      subgrupo: "Produtos",
      propriedades: [
        {
          name: "200 - Movimentação de Produto Resumido",
          layoutImpressao: PrintEstoque,
          permissao: permissoesHelper.temPermisao(
            "relatorio-movimentacao-produtos-resumido"
          ),
          funcaoBuscarDadosRelatorio: buscarRelatorioEstoqueResumido,
          formulario: FormFiltroAvancado,
        },
        {
          name: "201 - Movimentação de Produto Detalhado",
          layoutImpressao: PrintEstoqueDetalhado,
          permissao: permissoesHelper.temPermisao(
            "relatorio-movimentacao-produtos-detalhado"
          ),
          funcaoBuscarDadosRelatorio: buscarRelatorioEstoqueDetalhado,
          formulario: FormFiltroAvancado,
        },
        {
          name: "202 - Inventário de Estoque",
          layoutImpressao: PrintEstoqueInventario,
          permissao: permissoesHelper.temPermisao(
            "relatorio-inventario-estoque"
          ),
          funcaoBuscarDadosRelatorio: buscarInventarioDeEstoque,
          formulario: FormFiltroAvancadoInventario,
        },
        {
          name: "203 - Estoque Financeiro",
          layoutImpressao: PrintEstoqueFinanceiro,
          permissao: permissoesHelper.temPermisao(
            "relatorio-estoque-financeiro"
          ),
          funcaoBuscarDadosRelatorio: buscarRelatorioEstoqueFinanceiro,
          formulario: FormFiltroAvancado,
        },
        {
          name: "204 - Custo Mercadoria Vendida",
          layoutImpressao: PrintCustoMercadoriaVendida,
          permissao: permissoesHelper.temPermisao(
            "relatorio-custo-mercadoria-vendida"
          ),
          funcaoBuscarDadosRelatorio: buscarCustoMercadoriaVendida,
          formulario: FormFiltroAvancadoCmvAnaliseProdutosVendidos,
        },
        {
          name: "205 - Análise de Produtos Vendidos",
          layoutImpressao: PrintAnaliseProdutosVendidos,
          permissao: permissoesHelper.temPermisao(
            "relatorio-analise-produtos-vendidos"
          ),
          funcaoBuscarDadosRelatorio: buscarAnaliseProdutosVendidos,
          formulario: FormFiltroAvancadoCmvAnaliseProdutosVendidos,
        },
      ],
    },
  ];

  async function buscarRelatorioNfEntradaResumido(filtros) {
    setLoading(true);
    const result = await nfEntradaService.getRelatorioResumido(filtros);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarRelatorioEstoqueResumido(filtros) {
    setLoading(true);
    const result = await produtosService.getRelatorioResumido(filtros);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarRelatorioEstoqueDetalhado(filtros) {
    setLoading(true);
    const result = await produtosService.getRelatorioDetalhado(filtros);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarInventarioDeEstoque(filtros) {
    setLoading(true);
    const result = await produtosService.getInventarioDeEstoque(filtros);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarAnaliseProdutosVendidos(filtros) {
    setLoading(true);
    const filtro = {
      ...filtros,
      nonPaginated: true,
    };
    const result = await produtosService.getAnaliseProdutosVendidos(filtro);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarCustoMercadoriaVendida(filtros) {
    setLoading(true);
    const filtro = {
      ...filtros,
      nonPaginated: true,
    };
    const result = await produtosService.getCustoMercadoriaVendida(filtro);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarRelatorioEstoqueFinanceiro(filtros) {
    setLoading(true);
    const result = await produtosService.getRelatorioEstoqueFinanceiro(filtros);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  return (
    <RelatoriosLayout
      subgruposRelatorios={subgruposRelatorios}
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      dadosRelatorioResumido={relatorioEstoque}
      setFiltrosAplicadosImpressao={setFiltrosAplicadosImpressao}
      filtrosAvancados={filtrosAvancados}
      setFiltrosAvancados={setFiltrosAvancados}
      loading={loading}
    />
  );
};

export default RelatoriosEstoque;
