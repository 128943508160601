import { format } from "date-fns";
import { TablePrintResumidoComponent } from "../../../../components";
import { formatDate, formatNomes, formatPrice } from "../../../../utils";

const PrintAnaliseProdutosVendidos = ({
  tituloRelatorio,
  relatoriosTotais,
  filtrosAplicadosImpressao,
  print,
}) => {
  const columns = [
    {
      header: "Cod.",
      field: "codigo",
    },
    {
      header: "Produto",
      field: "descricao",
      width: "37%",
      render: ({ descricao }) =>
        descricao && formatNomes(descricao.substring(0, 50)),
    },
    {
      header: "Data Venda",
      align: "center",
      hide: Object.keys(filtrosAplicadosImpressao || {}).some((key) => {
        if (key) {
          return key.toLowerCase().includes("agrupar");
        }
      }),
      render: ({ data }) => format(formatDate.received(data), "dd/MM/yyyy"),
    },
    {
      header: "Quantidade",
      field: "quantidade",
      align: "center",
    },
    {
      header: "Total Custo (CMV)",
      field: "totalCusto",
      render: ({ totalCusto }) => formatPrice(totalCusto, false),
      fieldTotal: "totalCusto",
      align: "end",
    },
    {
      header: "Total Venda",
      field: "totalVenda",
      render: ({ totalVenda }) => formatPrice(totalVenda, false),
      fieldTotal: "totalVenda",
      align: "end",
    },
    {
      header: "Lucro (R$)",
      field: "lucro",
      render: ({ lucroReal }) => formatPrice(lucroReal, false),
      fieldTotal: "totalLucro",
      align: "end",
    },
    {
      header: "Lucro (%)",
      field: "lucro",
      render: ({ lucro }) => parseFloat(lucro).toFixed(2) + " %",
      align: "end",
    },
  ];

  return (
    <TablePrintResumidoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais.movimentacao}
      totais={relatoriosTotais.totais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      itensPorPagina={31}
    />
  );
};

export default PrintAnaliseProdutosVendidos;
