import { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  CardHeader,
  Dialog,
} from "../../../../../components";
import {
  NfEntradaService,
  OperacaoFiscalService,
  OrigemMercadoriaService,
  PessoaService,
  UnidadeComercialService,
  history,
  notification,
} from "../../../../../services";
import { responseErros, formatDate, formatPrice } from "../../../../../utils";
import { DevolucaoMercadoriaFornecedorStepperContent } from "..";
import { NfSaida, NfSaidaItem } from "../../../entities";

const DevolverMercadoriaFornecedorDialog = ({
  openDevolverMercadoriaFornecedorDialog,
  setOpenDevolverMercadoriaFornecedorDialog,
  filtrosDialog,
  setFiltrosDialog,
}) => {
  const nfEntradaService = new NfEntradaService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const pessoaService = new PessoaService();
  const unidadeComercialService = new UnidadeComercialService();
  const origemMercadoriaService = new OrigemMercadoriaService();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [nfsEntrada, setNfsEntrada] = useState([]);
  const [nfSelecionada, setNfSelecionada] = useState({});
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [documentosSelecionadosIds, setDocumentosSelecionadosIds] = useState(
    []
  );
  const [listaFornecedores, setListaFornecedores] = useState([]);
  const [totalListaNfsEntrada, setTotalListaNfsEntrada] = useState(0);
  const [listaOperacoesFiscais, setListaOperacoesFiscais] = useState([]);
  const [unidadeList, setUnidadeList] = useState([]);
  const [origensMercadoriaList, setOrigensMercadoriaList] = useState([]);
  const [operacaoFiscal, setOperacaoFiscal] = useState(null);
  const steps = [
    "Selecionar Nota de Entrada",
    "Selecionar Itens e Gerar Devolução",
    "Selecionar Operação Fiscal",
  ];
  const colunas = [
    {
      field: "numeroNota",
      headerName: "Nº Documento",
      width: 140,
    },
    {
      field: "fornecedorId",
      headerName: "Fornecedor",
      flex: 400,
      valueGetter: (params) => {
        const fornecedor = listaFornecedores.find(
          (fornecedor) => fornecedor.id == params.value
        );
        if (fornecedor?.nomeRazaoSocial) return fornecedor.nomeRazaoSocial;
        return params.value;
      },
      width: 300,
    },
    {
      field: "dataEmissao",
      headerName: "Data Emissão",
      width: 150,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "dataChegada",
      headerName: "Data Chegada",
      width: 150,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "totalNfEntrada",
      headerName: "Total Compra",
      flex: 300,
      valueGetter: (params) => {
        const totalNfEntrada =
          params.row.subtotalProdutos +
          params.row.frete +
          params.row.subtotalAcrescimo -
          params.row.subtotalDesconto +
          params.row.subtotalDespesas;
        return formatPrice(totalNfEntrada);
      },
    },
  ];

  const buscarOperacoesFiscaisDevolucao = () => {
    const filtro = {
      tipoOperacao: "SAIDA",
      finalidade: "DEVOLUCAO",
      ativado: true,
      nonPaginated: true,
      restritiva: true,
      porIntervalo: [],
    };
    operacaoFiscalService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaOperacoesFiscais(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarOrigensMercadoria = async () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriaList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarUnidadesComerciais = async () => {
    const filtros = {
      nonPaginated: true,
    };
    unidadeComercialService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setUnidadeList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  useEffect(() => {
    if (openDevolverMercadoriaFornecedorDialog) {
      buscarNfsEntrada();
      buscarFornecedores();
      buscarOperacoesFiscaisDevolucao();
      buscarOrigensMercadoria();
      buscarUnidadesComerciais();
    }
  }, [openDevolverMercadoriaFornecedorDialog]);

  const buscarNfsEntrada = async (filtros) => {
    setLoading(true);
    const filtro = {
      ...filtros,
      devolucao: "false",
    };
    const result = await nfEntradaService.getAll(filtro);
    if (!result.isAxiosError) {
      setNfsEntrada(result.data.rows);
      setTotalListaNfsEntrada(result.data.rows.length);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarNfsEntradaAvancado = async (filtros) => {
    setLoading(true);
    const porIntervalo = [];
    if (filtros.dataInicial && filtros.dataFinal) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtros.dataInicial,
        ate: filtros.dataFinal,
      });
    }
    const result = await nfEntradaService.getAllFiltroAvancado({
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      if (filtros.fornecedorId) {
        const resultado = result.data.rows.filter((nfEntrada) => {
          return nfEntrada.fornecedorId == filtros.fornecedorId
            ? nfEntrada
            : null;
        });
        setNfsEntrada(resultado);
        setTotalListaNfsEntrada(resultado.length);
      } else {
        setNfsEntrada(result.data.rows);
        setTotalListaNfsEntrada(result.data.rows.length);
      }
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarFornecedores = async () => {
    const filtros = {
      nonPaginated: true,
      tiposPessoaId: 2,
    };
    pessoaService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setListaFornecedores(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const handleInputChange = (e) => {
    e.persist();
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (date, fieldName) => {
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    }));
  };

  const handleDevolverMercadorias = async () => {
    if (!operacaoFiscal) {
      notification.alertaGenericos("Operação de Devolução deve ser informada");
      return;
    }
    window.nfSaidaEntity = await createNfSaida(
      nfSelecionada,
      itensSelecionados,
      operacaoFiscal
    );
    setOpenDevolverMercadoriaFornecedorDialog(false);
    resetarDadosDevolucao();
    history.push("/faturamento/nf-saida/importar-devolucao-fornecedor");
  };

  const createNfSaida = async (nfEntrada, itens, operacaoFiscal) => {
    let [acrescimo, desconto] = [0, 0];
    const nfSaidaItens = itens.map((item, index) => {
      const nfSaidaItem = new NfSaidaItem(
        item,
        index,
        operacaoFiscal,
        origensMercadoriaList,
        unidadeList
      );
      nfSaidaItem.setDescricao(item.produto);
      nfSaidaItem.setImposto(item);
      nfSaidaItem.setValor(item.precoCompra);
      nfSaidaItem.setSubtotal(item.quantidade);
      acrescimo += item.acrescimo || 0;
      desconto += item.desconto || 0;
      return nfSaidaItem;
    });
    const informacoesAdicionais = nfsEntrada
      .filter((nfEntrada) => documentosSelecionadosIds.includes(nfEntrada.id))
      .map((nfEntrada) => {
        return `Importado do documento de entrada de Série/Número - ${nfEntrada.serie}/${nfEntrada.numeroNota}`;
      })
      .join("\n");
    const dadosNfSaida = {
      documentoDevolucaoFornecedor: true,
      devolucoesFornecedorIds: documentosSelecionadosIds,
      operacaoFiscalId: operacaoFiscal.id,
      operacaoFiscal: operacaoFiscal,
      serieDocumento: operacaoFiscal?.serieDocumento,
      clienteId: nfEntrada.fornecedorId,
      modalidadeFrete: nfEntrada.modalidadeFrete,
      frete: nfEntrada.frete,
      freteCompoeNota: nfEntrada.freteCompoeNota,
      nfSaidaItens: nfSaidaItens,
      informacoesAdicionais,
      acrescimo,
      desconto,
      nfSaidaPagamentos: [],
    };
    return new NfSaida(dadosNfSaida, true);
  };

  const resetarDadosDevolucao = () => {
    setNfSelecionada({});
    setDocumentosSelecionadosIds([]);
    setActiveStep(0);
  };

  const handleCancelarDevolucao = () => {
    resetarDadosDevolucao();
    setOpenDevolverMercadoriaFornecedorDialog(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      if (props.filtros?.status) {
        delete props.filtros.status;
      }
      buscarNfsEntrada(props.filtros);
    } else {
      buscarNfsEntradaAvancado(props.filtros);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={openDevolverMercadoriaFornecedorDialog}
    >
      <Box sx={{ m: 2 }}>
        <CardHeader title="Devolução de Mercadorias ao Fornecedor" />
      </Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <DevolucaoMercadoriaFornecedorStepperContent
        sendServer={sendServerDatagrid}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleDevolverMercadorias={handleDevolverMercadorias}
        handleCancelarDevolucao={handleCancelarDevolucao}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
        setOperacaoFiscal={setOperacaoFiscal}
        operacaoFiscal={operacaoFiscal}
        colunas={colunas}
        nfsEntrada={nfsEntrada}
        totalListaNfsEntrada={totalListaNfsEntrada}
        nfSelecionada={nfSelecionada}
        setNfSelecionada={setNfSelecionada}
        setItensSelecionados={setItensSelecionados}
        loading={loading}
        listaFornecedores={listaFornecedores}
        buscarDocumentosEntradaAvancado={buscarNfsEntradaAvancado}
        listaOperacoesFiscais={listaOperacoesFiscais}
        documentosSelecionadosIds={documentosSelecionadosIds}
        setDocumentosSelecionadosIds={setDocumentosSelecionadosIds}
      />
    </Dialog>
  );
};

export default DevolverMercadoriaFornecedorDialog;
