import { useState, useEffect } from "react";
import {
  Grid,
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  DatePicker,
  DataGrid,
  Loading,
} from "../../../../../components";
import { InputErros, value } from "../../../../../helpers";
import { notification } from "../../../../../services";
import { formatPrice } from "../../../../../utils";
import { editarQuantidadeItemDevolucao } from "../../list-nf-saida";

const DevolucaoMercadoriaFornecedorStepperContent = ({
  sendServer,
  activeStep,
  setActiveStep,
  handleDevolverMercadorias,
  handleCancelarDevolucao,
  handleDateChange,
  filtrosDialog,
  setFiltrosDialog,
  operacaoFiscal,
  setOperacaoFiscal,
  listaOperacoesFiscais,
  colunas,
  nfsEntrada,
  nfSelecionada,
  setNfSelecionada,
  setItensSelecionados,
  loading,
  listaFornecedores,
  buscarDocumentosEntradaAvancado,
  documentosSelecionadosIds,
  setDocumentosSelecionadosIds,
  handleInputChange,
}) => {
  const [listaItens, setListaItens] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const colunasItens = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 150,
    },
    {
      field: "produto",
      headerName: "Produto",
      flex: 500,
    },
    {
      field: "precoCompra",
      headerName: "Preço Compra (R$)",
      flex: 300,
      valueGetter: (params) => formatPrice(parseFloat(params.value)),
    },
    {
      field: "quantidade",
      headerName: "Qtd.",
      flex: 200,
      headerAlign: "start",
      align: "start",
      type: "number",
      editable: true,
    },
    {
      field: "subtotal",
      headerName: "Total Ítem (R$)",
      flex: 300,
      valueGetter: (params) => formatPrice(parseFloat(params.value)),
    },
  ];

  useEffect(() => {
    if (nfSelecionada && nfSelecionada.nfEntradaItens) {
      const listaIdItens = nfSelecionada.nfEntradaItens.map((item) => item.id);
      setSelectionModel(listaIdItens);
      setItensSelecionados(nfSelecionada.nfEntradaItens);
      setListaItens(nfSelecionada.nfEntradaItens);
    }
  }, [nfSelecionada]);

  const filtrarNfSelecionado = (nfsEntradaIds) => {
    let nfEntradaImportacao = null;
    let itensImportacao = [];
    const nfEntradaFiltrada = nfsEntrada
      .filter((nf) => nfsEntradaIds.includes(nf.id))
      .map((nfEntrada) => {
        if (nfEntradaImportacao === null) {
          nfEntradaImportacao = { ...nfEntrada };
        }
        const nfEntradaFiltrada = {};
        for (const key in nfEntrada) {
          if (key === "cnpj" && nfEntradaImportacao[key] !== nfEntrada[key]) {
            throw "NF's devem ser do mesmo fornecedor";
          }
          if (key === "nfEntradaItens") {
            itensImportacao.push(...nfEntrada[key]);
            nfEntradaFiltrada[key] = [...nfEntrada[key]];
          } else {
            nfEntradaFiltrada[key] = nfEntradaImportacao[key];
          }
        }
        return nfEntradaFiltrada;
      });
    setNfSelecionada(() => ({
      ...nfEntradaFiltrada[0],
      nfEntradaItens: itensImportacao,
    }));
  };

  const handleAvancarStep = () => {
    if (activeStep == 0) {
      if (!documentosSelecionadosIds?.length) {
        return notification.alertaGenericos(
          "Selecione ao menos um documento para importar"
        );
      }
      try {
        filtrarNfSelecionado(documentosSelecionadosIds);
      } catch (error) {
        return notification.alertaGenericos(error);
      }
    }
    if (!selectionModel?.length && activeStep == 1) {
      return notification.alertaGenericos(
        "Selecione ao menos um produto para importar"
      );
    }
    setActiveStep(activeStep + 1);
  };

  const handleVoltarStep = () => {
    setActiveStep(activeStep - 1);
  };

  const filtrarItensSelecionados = (itemIds) => {
    let selecionados = [];
    for (let itemId of itemIds) {
      const itemEncontrado = listaItens.find((item) => item.id === itemId);
      selecionados.push(itemEncontrado);
    }
    setItensSelecionados(selecionados);
  };

  const onChangeAutocomplete = (name, value) => {
    setFiltrosDialog({
      ...filtrosDialog,
      [name]: value ? value.id : undefined,
    });
  };

  switch (activeStep) {
    case 2:
      return (
        <>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  id="operacaoFiscalId"
                  name="operacaoFiscalId"
                  required
                  options={listaOperacoesFiscais}
                  noOptionsText="Sem opções"
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.descricao ? `${option.descricao}` : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Operação Fiscal"
                      variant="outlined"
                      margin="normal"
                      error={inputErros.get("operacaoFiscalId")}
                      required
                    />
                  )}
                  onChange={(e, value) => {
                    setOperacaoFiscal(value);
                  }}
                  value={value.autoComplete(
                    listaOperacoesFiscais,
                    operacaoFiscal?.id
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="m-3">
            <Button
              onClick={handleCancelarDevolucao}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleVoltarStep}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-left"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Anterior
            </Button>
            <Button
              onClick={handleDevolverMercadorias}
              color="primary"
              variant="contained"
            >
              Devolver
            </Button>
          </DialogActions>
        </>
      );
    case 1:
      return (
        <>
          <DialogContent>
            <DataGrid
              rows={listaItens}
              columns={colunasItens}
              rowCount={listaItens.length}
              checkboxSelection
              selectionModel={selectionModel}
              onRowSelectionModelChange={(selectedIds) => {
                setSelectionModel(selectedIds);
                filtrarItensSelecionados(selectedIds);
              }}
              onCellKeyDown={(params, event) =>
                editarQuantidadeItemDevolucao(
                  params,
                  event,
                  setListaItens,
                  setItensSelecionados
                )
              }
              disableAcoes
            />
          </DialogContent>
          <DialogActions className="m-3">
            <Button
              onClick={handleVoltarStep}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-left"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Anterior
            </Button>
            <Button
              onClick={handleAvancarStep}
              color="primary"
              variant="contained"
            >
              Próximo
              <i
                className="ph-fill ph-caret-right"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
            </Button>
          </DialogActions>
        </>
      );
    default:
      return (
        <>
          <DialogContent>
            <Grid container spacing={2} className="mt-1 mb-4">
              <Grid item xs={2}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  label="Data Inicial"
                  format="dd/MM/yyyy"
                  value={
                    filtrosDialog?.dataInicial
                      ? filtrosDialog.dataInicial
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "dataInicial")}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  label="Data Final"
                  format="dd/MM/yyyy"
                  value={
                    filtrosDialog?.dataFinal ? filtrosDialog.dataFinal : null
                  }
                  onChange={(date) => handleDateChange(date, "dataFinal")}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="fornecedorId"
                  name="fornecedorId"
                  options={listaFornecedores}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.nomeRazaoSocial
                      ? `${option.id}` + ` - ` + `${option.nomeRazaoSocial}`
                      : ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.nomeRazaoSocial === value?.nomeRazaoSocial
                  }
                  noOptionsText="Sem opções"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Fornecedor"
                      variant={"outlined"}
                      error={inputErros.get("fornecedorId")}
                    />
                  )}
                  onChange={(e, value) =>
                    onChangeAutocomplete("fornecedorId", value)
                  }
                  value={value.autoComplete(
                    listaFornecedores,
                    filtrosDialog?.pessoaFornecedorId
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="numeroNota"
                  name="numeroNota"
                  label="Nº Documento"
                  variant="outlined"
                  fullWidth
                  onChange={handleInputChange}
                  value={filtrosDialog?.numeroNota}
                />
              </Grid>
              <Grid item xs={2} className="d-flex align-items-center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  sx={{ height: "51px" }}
                  onClick={() => buscarDocumentosEntradaAvancado(filtrosDialog)}
                >
                  <i
                    className="ph-bold ph-magnifying-glass"
                    style={{ color: "#fff", fontSize: 18, marginRight: 10 }}
                  ></i>
                  Filtrar
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              rows={nfsEntrada}
              columns={colunas}
              checkboxSelection
              onRowSelectionModelChange={(selectedIds) => {
                setDocumentosSelecionadosIds(selectedIds);
              }}
              paginationMode={"server"}
              sendServer={sendServer}
              disableAcoes
              noFilter
            />
          </DialogContent>
          <DialogActions className="m-3">
            <Button
              onClick={handleCancelarDevolucao}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleAvancarStep}
              color="primary"
              variant="contained"
            >
              Próximo
              <i
                className="ph-fill ph-caret-right"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
            </Button>
          </DialogActions>
          <Loading loading={loading} />
        </>
      );
  }
};

export default DevolucaoMercadoriaFornecedorStepperContent;
