import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import {
  PessoaService,
  VinculoPessoaService,
  history,
  notification,
} from "../../../services";
import { FiltroCustom, FormFiltroAvancado } from "./components";
import { filtrosUrl, PermisoesHelper } from "../../../helpers";
import {
  responseErros,
  formatCnpj,
  formatCpf,
  formatTelefone,
} from "../../../utils";

const ListPessoasView = () => {
  const [pessoas, setPessoas] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buscaFiltro, setBuscaFiltro] = useState("");
  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 150,
    },
    {
      field: "nomeRazaoSocial",
      headerName: "Nome / Razão Social",
      flex: 700,
      filtrar: true,
    },
    {
      field: "cnpjCpf",
      headerName: "CNPJ / CPF",
      sortable: false,
      flex: 300,
      valueGetter: (params) =>
        params.row.naturezaSocial === "fisica"
          ? formatCpf(params.value)
          : formatCnpj(params.value),
    },
    { field: "email", headerName: "Email", flex: 350 },
    {
      field: "telefone",
      headerName: "Telefone",
      flex: 300,
      valueGetter: (params) => formatTelefone(params.value),
    },
  ];
  const pessoaService = new PessoaService();
  const vinculoPessoaService = new VinculoPessoaService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("pessoas-visualizar");

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscaPessoas(filtros);
  }, []);

  const buscaPessoas = async (filter) => {
    setLoading(true);
    const result = await pessoaService.getAll(filter);
    if (!result.isAxiosError) {
      setPessoas(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCadastrarPessoa = function () {
    history.push("pessoas/create");
  };

  const onClickEditPessoa = function (id) {
    history.push(`pessoas/${id}`);
  };

  const desativarPessoa = async (id, pessoa) => {
    if (!pessoa.ativado) {
      return notification.alertaGenericos(
        "Pessoa não pode ser excluída e já está desativada"
      );
    }
    const retornoAlerta = await notification.confirmacao(
      "Desativar!",
      `Não é possível excluir pessoa, pois já possui vinculos e registros. 
        Deseja desativar?`
    );
    if (retornoAlerta.isConfirmed) {
      const data = {
        ...pessoa,
        ativado: false,
      };
      setLoading(true);
      const res = await pessoaService.atualizar(id, data);
      setLoading(false);
      if (!res.isAxiosError) {
        notification.alteracaoSucesso();
      } else {
        responseErros(res);
      }
    }
  };

  const removerPessoa = async (id, pessoa) => {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${pessoa.nomeRazaoSocial}?`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await pessoaService.deletar(id);
      if (!result.isAxiosError) {
        buscaPessoas();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const onClickDesativarRemoverPessoa = async function (id) {
    setLoading(true);
    const pessoa = pessoas.find((item) => item.id === id);
    if (pessoa) {
      const query = {
        pessoaId: pessoa.id,
        clienteId: pessoa?.clientes[0]?.id ?? undefined,
        fornecedorId: pessoa?.fornecedores[0]?.id ?? undefined,
        colaboradorId: pessoa?.colaboradores[0]?.id ?? undefined,
        prestadorSevicoId: pessoa?.prestadorSevico[0]?.id ?? undefined,
        transportadoraId: pessoa?.transportadoras[0]?.id ?? undefined,
      };
      const result = await vinculoPessoaService.verificarVinculoPessoa(query);
      if (!result.isAxiosError) {
        setLoading(false);
        if (result.data) {
          desativarPessoa(id, pessoa);
        } else {
          removerPessoa(id, pessoa);
        }
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    for (const key in filtrosAvancadosTemp) {
      if (filtrosAvancadosTemp[key] == null) {
        delete filtrosAvancadosTemp[key];
      }
    }
    const result = await pessoaService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setPessoas(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscaPessoas(props.filtros);
    } else {
      if (props.filtros.cnpjCpf) {
        props.filtros.cnpjCpf = props.filtros.cnpjCpf.replace(/[^0-9]/g, "");
      }
      buscarPesquisaAvancada(props.filtros);
    }
  };

  const filtrarListaPorCodigo = (value) => {
    if (value) {
      buscaPessoas({ codigo: value });
      setBuscaFiltro("");
    } else {
      buscaPessoas();
    }
  };


  return (
    <ListLayout
      title="Pessoas"
      loading={loading}
      onClickCadastrar={onClickCadastrarPessoa}
      permissaoButton={permissoesHelper.temPermisao("pessoas-cadastrar")}
    >
      <DataGrid
        rows={pessoas}
        columns={colunas}
        rowCount={rowCount}
        onClickEdit={onClickEditPessoa}
        onClickDelete={onClickDesativarRemoverPessoa}
        editShow={permissoesHelper.temPermisao("pessoas-editar")}
        visualizarShow={permissoesHelper.temPermisao("pessoas-visualizar")}
        deleteShow={permissoesHelper.temPermisao("pessoas-excluir")}
        paginationMode="server"
        sendServer={sendServerDatagrid}
        FormFiltroAvancado={FormFiltroAvancado}
        FiltroCustom={
          <FiltroCustom
            buscaFiltro={buscaFiltro}
            setBuscaFiltro={setBuscaFiltro}
            sendServer={sendServerDatagrid}
            columns={colunas}
            filtrarListaPorCodigo={filtrarListaPorCodigo}
          />
        }
      />
    </ListLayout>
  );
};

export default ListPessoasView;
