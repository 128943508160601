import { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  CardHeader,
  Dialog,
} from "../../../../../components";
import {
  NfSaidaService,
  OperacaoFiscalService,
  OrigemMercadoriaService,
  UnidadeComercialService,
  history,
  notification,
} from "../../../../../services";
import { responseErros, formatDate, formatPrice } from "../../../../../utils";
import { DevolucaoMercadoriaStepperContent } from "..";
import { NfSaida, NfSaidaItem } from "../../../entities";

const DevolverMercadoriaDialog = ({
  openDevolverMercadoriaDialog,
  setOpenDevolverMercadoriaDialog,
  filtrosDialog,
  setFiltrosDialog,
  buscarNfSaida,
  listaPessoas,
  nfsSaida,
  listaOperacoesFiscaisIniciais,
}) => {
  const nfSaidaService = new NfSaidaService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const unidadeComercialService = new UnidadeComercialService();
  const origemMercadoriaService = new OrigemMercadoriaService();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [nfSaida, setNfSaida] = useState([]);
  const [documentoSelecionado, setDocumentoSelecionado] = useState({});
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [documentosSelecionadosIds, setDocumentosSelecionadosIds] = useState(
    []
  );
  const [listaNfSaida, setListaNfSaida] = useState([]);
  const [listaOperacoesFiscais, setListaOperacoesFiscais] = useState([]);
  const [origensMercadoriaList, setOrigensMercadoriaList] = useState([]);
  const [unidadeList, setUnidadeList] = useState([]);
  const [operacaoFiscal, setOperacaoFiscal] = useState(null);
  const steps = [
    "Selecionar Nota de Saída",
    "Selecionar Itens",
    "Selecionar Operação Fiscal",
  ];
  const colunas = [
    {
      field: "serieDocumento",
      headerName: "Série",
      width: 90,
    },
    {
      field: "numeroDocumento",
      headerName: "Nº Documento",
      width: 140,
    },
    {
      field: "dataEmissao",
      headerName: "Data de Emissão",
      width: 150,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "operacaoFiscalId",
      headerName: "Operação Fiscal",
      width: 300,
      valueGetter: (params) => {
        const opFiscal = listaOperacoesFiscaisIniciais.find(
          (item) => item.id == params.value
        );
        if (!opFiscal) return params.value;
        return opFiscal.descricao;
      },
    },
    {
      field: "clienteId",
      headerName: "Cliente",
      width: 250,
      valueGetter: (params) => {
        const cliente = listaPessoas.find((item) => item.id == params.value);
        if (!cliente) return params.value;
        return cliente.nomeRazaoSocial;
      },
    },
    {
      field: "vendedorId",
      headerName: "Vendedor",
      width: 250,
      valueGetter: (params) => {
        const vendedor = listaPessoas.find((item) => item.id == params.value);
        if (!vendedor) return params.value;
        return vendedor.nomeRazaoSocial;
      },
    },
    {
      field: "nfSaidaItens",
      headerName: "Total da Venda",
      width: 250,
      valueGetter: (params) => {
        const totalNfSaida =
          params.value
            .map((item) => item.valor * item.saldoExportar)
            .reduce((acumulador, total) => acumulador + total, 0) +
          (params.row.frete || 0) +
          (params.row.acrescimo || 0) -
          (params.row.desconto || 0) +
          (params.row.despesas || 0);
        return formatPrice(totalNfSaida);
      },
    },
  ];

  const buscarOperacoesFiscaisDevolucao = () => {
    const filtro = {
      tipoOperacao: "ENTRADA",
      finalidade: "DEVOLUCAO",
      ativado: true,
      nonPaginated: true,
      restritiva: true,
      porIntervalo: [],
    };
    operacaoFiscalService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaOperacoesFiscais(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarUnidadesComerciais = async () => {
    const filtros = {
      nonPaginated: true,
    };
    unidadeComercialService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setUnidadeList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  useEffect(() => {
    if (openDevolverMercadoriaDialog) {
      buscarOperacoesFiscaisDevolucao();
      buscarOrigensMercadoria();
      buscarUnidadesComerciais();
    }
  }, [openDevolverMercadoriaDialog]);

  useEffect(() => {
    const nfsFiltradas = nfsSaida.filter((nf) => {
      if (
        nf.nfSaidaDevolucaoId == null &&
        (nf.status === "FATURADA" || nf.status === "ENVIADA") &&
        nf.nfSaidaItens?.length &&
        nf.nfSaidaItens.some((item) => item.saldoExportar > 0)
      )
        return true;
    });
    setNfSaida(nfsFiltradas);
  }, [nfsSaida]);

  const buscarNfSaidaAvancado = async (filtros) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filtros,
    };
    if (
      filtrosAvancadosTemp.dataEmissaoStart &&
      filtrosAvancadosTemp.dataEmissaoEnd
    ) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataEmissaoStart,
        ate: filtrosAvancadosTemp.dataEmissaoEnd,
      });
    }
    delete filtrosAvancadosTemp.dataEmissaoStart;
    delete filtrosAvancadosTemp.dataEmissaoEnd;
    setLoading(true);
    const result = await nfSaidaService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setNfSaida(result.data.rows);
    } else {
      responseErros(result);
    }
    setFiltrosDialog({});
    setLoading(false);
  };

  const buscarOrigensMercadoria = async () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriaList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const handleInputChange = (e) => {
    e.persist();
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (date, fieldName) => {
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    }));
  };

  const handleDevolverMercadorias = async () => {
    if (!operacaoFiscal) {
      notification.alertaGenericos("Operação de Devolução deve ser informada");
      return;
    }
    window.nfSaidaEntity = await createNfSaida(
      documentoSelecionado,
      itensSelecionados,
      operacaoFiscal
    );
    setOpenDevolverMercadoriaDialog(false);
    resetarDadosDevolucao();
    history.push("/faturamento/nf-saida/importar-devolucao");
  };

  const createNfSaida = async (documento, itens, operacaoFiscal) => {
    let [acrescimo, desconto] = [0, 0];
    const nfSaidaItens = itens.map((item, index) => {
      const nfSaidaItem = new NfSaidaItem(
        item,
        index,
        operacaoFiscal,
        origensMercadoriaList,
        unidadeList
      );
      acrescimo += item?.acrescimo || 0;
      desconto += item?.desconto || 0;
      return {
        ...nfSaidaItem,
        produto: item.produto,
        valor: parseFloat(item.valor.toFixed(2)),
        subtotal: parseFloat(item.valor.toFixed(2)) * item.quantidade,
      };
    });
    const informacoesAdicionais = listaNfSaida
      .map((nfSaida) => {
        return `Importado do documento de saída de Série/Número - ${nfSaida.serieDocumento}/${nfSaida.numeroDocumento}`;
      })
      .join("\n");
    const dadosNfSaida = {
      ...documento,
      dataEmissao: formatDate.toSend(new Date()),
      modalidadeFrete: "mfSemFrete",
      freteCompoeNota: false,
      documentoDevolucaoCliente: true,
      id: undefined,
      numeroDocumento: undefined,
      devolucoesClienteIds: documentosSelecionadosIds,
      operacaoFiscalId: operacaoFiscal.id,
      operacaoFiscal: operacaoFiscal,
      serieDocumento: operacaoFiscal?.serieDocumento,
      modeloDocumento: operacaoFiscal?.modeloDocumento,
      nfSaidaItens,
      informacoesAdicionais,
      desconto,
      acrescimo,
      nfSaidaPagamentos: [],
    };
    return new NfSaida(dadosNfSaida, true);
  };

  const resetarDadosDevolucao = () => {
    setDocumentoSelecionado({});
    setDocumentosSelecionadosIds([]);
    setActiveStep(0);
  };

  const handleCancelarDevolucao = () => {
    resetarDadosDevolucao();
    setOpenDevolverMercadoriaDialog(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      if (props.filtros?.status) {
        delete props.filtros.status;
      }
      buscarNfSaida(props.filtros);
    } else {
      buscarNfSaidaAvancado(props.filtros);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={openDevolverMercadoriaDialog}
      onClose={handleCancelarDevolucao}
    >
      <Box sx={{ m: 2 }}>
        <CardHeader title="Devolução de Mercadoria do Cliente" />
      </Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <DevolucaoMercadoriaStepperContent
        sendServer={sendServerDatagrid}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleDevolverMercadorias={handleDevolverMercadorias}
        handleCancelarDevolucao={handleCancelarDevolucao}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
        setOperacaoFiscal={setOperacaoFiscal}
        operacaoFiscal={operacaoFiscal}
        colunas={colunas}
        nfSaida={nfSaida}
        documentoSelecionado={documentoSelecionado}
        setDocumentoSelecionado={setDocumentoSelecionado}
        setItensSelecionados={setItensSelecionados}
        loading={loading}
        buscarNfSaidaAvancado={buscarNfSaidaAvancado}
        listaClientes={listaPessoas}
        listaOperacoesFiscais={listaOperacoesFiscais}
        setListaOperacoesFiscais={setListaOperacoesFiscais}
        documentosSelecionadosIds={documentosSelecionadosIds}
        setDocumentosSelecionadosIds={setDocumentosSelecionadosIds}
        setListaNfSaida={setListaNfSaida}
      />
    </Dialog>
  );
};

export default DevolverMercadoriaDialog;
