import { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "../../../../components";
import {
  OperacaoFiscalService,
  PessoaService,
  CondicoesPagamentoService,
  MeioPagamentoService,
} from "../../../../services";
import { InputErros, value } from "../../../../helpers";
import { responseErros, timeoutBuscaAutocomplete } from "../../../../utils";
import { useFiltrosRelatoriosHandlers } from "../../components/use-filtros-relatorios-handlers";

const operacaoFiscalService = new OperacaoFiscalService();
const pessoaService = new PessoaService();
const condicaoPagamentoService = new CondicoesPagamentoService();
const meioPagamentoService = new MeioPagamentoService();

const FormFiltroAvancadoNfSaida = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const inputRefCliente = useRef();
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [filteredOperacaoFiscalList, setFilteredOperacaoFiscalList] = useState(
    []
  );
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [filteredVendedorList, setFilteredVendedorList] = useState([]);
  const [condicaoPagamentoList, setCondicaoPagamentoList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const { onChangeInput, onChangeAutocomplete, onChangeDate } =
    useFiltrosRelatoriosHandlers(setFiltrosAvancados, setFiltrosAplicadosImpressao);

  const listaStatusNf = [
    {
      label: "Pendente",
      value: "PENDENTE",
      cor: "#EA9D2B",
    },
    {
      label: "Enviada",
      value: "ENVIADA",
      cor: "#4FBB53",
    },
    {
      label: "Em Contingência",
      value: "CONTINGENCIA",
      cor: "#BFBFBF",
    },
    {
      label: "Denegada",
      value: "DENEGADA",
      cor: "#70309F",
    },
  ];

  const listaFinalidade = [
    { label: "NFE NORMAL", value: "NFE_NORMAL" },
    { label: "NFE COMPLEMENTAR", value: "NFE_COMPLEMENTAR" },
    { label: "NFE AJUSTE", value: "NFE_AJUSTE" },
    { label: "NFE DEVOLUCAO", value: "DEVOLUCAO" },
    { label: "NÃO SE APLICA", value: "NAO_SE_APLICA" },
  ];

  const onChangeSelectNfSaida = (event, name) => {
    onChangeInput(event, name);
    if (name === "finalidade") {
      resetarFiltrosChave("operacoesFiscais");
    }
  };

  useEffect(() => {
    setEstadoInicialFiltrosNfSaida();
    buscarOperacoesFiscais();
    buscarCondicoesPagamento();
    buscarMeiosPagamento();
    buscarVendedores();
  }, []);

  function setEstadoInicialFiltrosNfSaida() {
    const filtros = { ...filtrosAvancados };
    const filtrosImpressao = { ...filtrosAplicadosImpressao };

    if (!filtros?.canceladas) {
      filtros.canceladas = "false";
      filtrosImpressao["Notas Canceladas"] = "Não";
    }

    if (!filtros?.tipoOperacao) {
      filtros.tipoOperacao = "SAIDA";
      filtrosImpressao["Tipo Operação"] = "Saída";
    }

    setFiltrosAvancados(filtros);
    setFiltrosAplicadosImpressao(filtrosImpressao);
  }

  const buscarOperacoesFiscais = (tipo) => {
    const filtrosOperacoesFiscais = {
      ativado: true,
      tipoOperacao: tipo || filtrosAvancados?.tipoOperacao,
    };
    operacaoFiscalService.getAll(filtrosOperacoesFiscais).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data.rows);
        setFilteredOperacaoFiscalList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarClientes = () => {
    const filtros = {
      nonPaginated: true,
      tiposPessoaId: 1,
    };
    pessoaService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setClienteList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const buscarVendedores = () => {
    const filtro = {
      nonPaginated: true,
      vendedor: true,
      tiposPessoaId: 4,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setVendedorList(result.data);
        setFilteredVendedorList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const buscarCondicoesPagamento = () => {
    condicaoPagamentoService.getAll({ ativado: true }).then((result) => {
      if (!result.isAxiosError) {
        setCondicaoPagamentoList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = () => {
    meioPagamentoService.getAll({ ativado: true }).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const onInputChangePessoa = (event, tiposPessoaId) => {
    const inputRefMap = {
      1: {
        inputRef: inputRefCliente,
        buscar: buscarClientes,
      },
    };
    const { inputRef, buscar } = inputRefMap[tiposPessoaId] || {};
    if (
      event.key === "Enter" &&
      inputRef?.current === document.activeElement &&
      event.target?.value?.length > 2
    ) {
      setLoadingAutoComplete(true);
      const filtros = {
        nomeRazaoSocial: event.target.value,
        apelidoFantasia: event.target.value,
      };
      timeoutBuscaAutocomplete(buscar?.(filtros));
    }
  };

  const onChangeMultipleVendedores = (valores) => {
    if (!valores?.length) {
      resetarFiltrosChave("vendedores");
      return;
    }
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      int_vendedor_id: valores.map((valor) => valor.id),
      vendedores: valores.map(({ id, nomeRazaoSocial }) => ({
        id,
        nomeRazaoSocial,
      })),
    }));
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      Vendedores: valores.map((valor) => {
        return (
          <div key={valor.id} className="d-flex align-items-center">
            {valor.nomeRazaoSocial}
          </div>
        );
      }),
    }));
    setFilteredVendedorList(
      vendedorList.filter(
        (vendedor) => !valores.map(({ id }) => id).includes(vendedor.id)
      )
    );
  };

  const onChangeMultipleOperacoes = (valores) => {
    if (!valores?.length) {
      resetarFiltrosChave("operacoesFiscais");
      return;
    }
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      operacoesFiscais: valores,
      operacaoFiscalId: valores.map((valor) => valor.id),
    }));
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      "Operações-Fiscais": valores.map((valor) => {
        return (
          <div key={valor.id} className="d-flex align-items-center">
            {valor.descricao}
          </div>
        );
      }),
    }));
    setFilteredOperacaoFiscalList(
      operacaoFiscalList.filter(
        (operacao) => !valores.map(({ id }) => id).includes(operacao.id)
      )
    );
    resetarFiltrosChave("finalidade");
  };

  function resetarFiltrosChave(chaveFiltro) {
    let chaveFiltroImpressao = chaveFiltro;
    switch (chaveFiltro) {
      case "vendedores":
        setFilteredVendedorList(vendedorList);
        chaveFiltroImpressao = "Vendedores";
        break;
      case "operacoesFiscais":
        setFilteredOperacaoFiscalList(operacaoFiscalList);
        chaveFiltroImpressao = "Operações-Fiscais";
        break;
      default:
        break;
    }
    setFiltrosAvancados((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp[chaveFiltro];
      return prevStateTemp;
    });
    setFiltrosAplicadosImpressao((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp[chaveFiltroImpressao];
      return prevStateTemp;
    });
  }

  function handleChangeTipoOperacao(e) {
    resetarFiltrosChave("operacoesFiscais");
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      tipoOperacao: e.target.value,
    }));
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      ["Tipo Operação"]: e.target.value === "SAIDA" ? "Saída" : "Entrada",
    }));
    buscarOperacoesFiscais(e.target.value);
  }

  function handleChangeFiltroCanceladas(e) {
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      canceladas: e.target.value,
    }));
    if (e.target.value !== "null") {
      setFiltrosAplicadosImpressao((prevState) => ({
        ...prevState,
        ["Notas Canceladas"]: e.target.value === "true" ? "Sim" : "Não",
      }));
    } else {
      const filtrosImpressao = { ...filtrosAplicadosImpressao };
      delete filtrosImpressao["Notas Canceladas"];
      setFiltrosAplicadosImpressao(filtrosImpressao);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid
        container
        spacing={2}
        className="d-flex justify-content-start align-items-center"
      >
        <Grid item xs={5}>
          <FormControl className="p-2">
            <RadioGroup
              row
              aria-labelledby="row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={filtrosAvancados?.tipoOperacao || "SAIDA"}
              onChange={handleChangeTipoOperacao}
            >
              <FormControlLabel
                value="ENTRADA"
                control={<Radio />}
                label="Entrada"
              />
              <FormControlLabel
                value="SAIDA"
                control={<Radio />}
                label="Saída"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={7}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              multiple
              id="operacoesFiscais"
              name="Operações-Fiscais"
              options={filteredOperacaoFiscalList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option.descricao : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`Operação Fiscal - ${filtrosAvancados?.tipoOperacao}`}
                  variant="outlined"
                  error={inputErros.get("operacaoFiscalId")}
                />
              )}
              onChange={(_, newValue) => onChangeMultipleOperacoes(newValue)}
              value={filtrosAvancados?.operacoesFiscais || []}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            label="Finalidade"
            id="finalidade"
            name="finalidade"
            margin="normal"
            value={value.text(filtrosAvancados?.finalidade)}
            onChange={(event) => onChangeSelectNfSaida(event, "finalidade")}
            error={inputErros.get("finalidade")}
          >
            {listaFinalidade.map((finalidade, index) => (
              <MenuItem key={`${index}-finalidade`} value={finalidade.value}>
                {finalidade.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
      <Grid item xs={6}>
          <TextField
            id="numeroDocumento"
            name="Número-Documento"
            label="Nº Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={(e) => onChangeInput(e, "Número-Documento")}
            value={value.text(filtrosAvancados?.numeroDocumento)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="serieDocumento"
            name="Série-Documento"
            label="Série Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={(e) => onChangeInput(e, "Série-Documento")}
            value={value.text(filtrosAvancados?.serieDocumento)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="clienteId"
              name="Cliente"
              options={clienteList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  variant="outlined"
                  onKeyDown={(e) => onInputChangePessoa(e, 1)}
                  inputRef={inputRefCliente}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("clienteId", value, "Cliente")
              }
              value={value.autoComplete(
                clienteList,
                filtrosAvancados?.clienteId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              multiple
              id="vendedores"
              name="Vendedores"
              options={filteredVendedorList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendedores"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(_, newValue) => {
                onChangeMultipleVendedores(newValue);
              }}
              value={filtrosAvancados?.vendedores || []}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="condicaoPagamentoId"
              name="Condição-de-Pagamento"
              options={condicaoPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.descricao
                  ? `${option.id} - ${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Condição de Pagamento"
                  variant="outlined"
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete(
                  "condicaoPagamentoId",
                  value,
                  "Condição-de-Pagamento"
                )
              }
              value={value.autoComplete(
                condicaoPagamentoList,
                filtrosAvancados?.condicaoPagamentoId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="meioPagamentoId"
              name="Meio-de-Pagamento"
              options={meioPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.descricao
                  ? `${option.id} - ${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meios de pagamento"
                  variant="outlined"
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete(
                  "meioPagamentoId",
                  value,
                  "Meio-de-Pagamento"
                )
              }
              value={value.autoComplete(
                meioPagamentoList,
                filtrosAvancados?.meioPagamentoId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoStart"
            name="Data-Lançamento-De"
            label="Data Lançamento De"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataLancamentoStart)}
            onChange={(date) =>
              onChangeDate("dataLancamentoStart", date, "Data-Lançamento-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoEnd"
            name="Data-Lançamento-Até"
            label="Data Lançamento Até"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataLancamentoEnd)}
            onChange={(date) =>
              onChangeDate("dataLancamentoEnd", date, "Data-Lançamento-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            id="status"
            name="status"
            label="Status NF-e"
            margin="normal"
            fullWidth
            onChange={(event) => onChangeSelectNfSaida(event, "Status")}
            value={value.text(filtrosAvancados?.status)}
          >
            {listaStatusNf.map((status, index) => (
              <MenuItem key={index} value={status.value}>
                <i
                  className="ph-fill ph-circle"
                  style={{ color: `${status.cor}` }}
                ></i>
                &nbsp;{status.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          className="d-flex justify-content-end align-items-center m-2"
        >
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={filtrosAvancados?.canceladas || "false"}
              onChange={handleChangeFiltroCanceladas}
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="Canceladas"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio sx={{ ml: "4rem" }} />}
                label="Não Canceladas"
              />
              <FormControlLabel
                value={"null"}
                control={<Radio sx={{ ml: "4rem" }} />}
                label="Ambas"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoNfSaida;
