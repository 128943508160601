import SiafLiteApiRepository from "../../external/siafLiteApi";

class ContasReceberService {
  constructor() {
    this.urlBase = "/financeiro/contas-receber";
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  cadastrarLista(body) {
    return new SiafLiteApiRepository(`${this.urlBase}/cadastro-lista`).post(
      body
    );
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }

  deletar(id) {
    return new SiafLiteApiRepository(this.urlBase).delete(id);
  }

  getAllFiltroAvancado(body) {
    return new SiafLiteApiRepository(this.urlBase).patch(body);
  }

  baixaEmLote(body) {
    return new SiafLiteApiRepository(`${this.urlBase}/lista`).post(body);
  }

  getResumoTotaisAReceber() {
    return new SiafLiteApiRepository(
      `${this.urlBase}/estatistica/resumo-contas-receber-totais`
    ).get();
  }

  getResumoContasReceber() {
    return new SiafLiteApiRepository(
      `${this.urlBase}/estatistica/resumo-contas-receber`
    ).get();
  }

  getRelatorioResumido(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/resumido`
    ).patch(body);
  }

  getRelatorioDetalhado(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/detalhado`
    ).patch(body);
  }
  
  getRelatorioHistoricoPagamentos(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/historico-pagamentos`
    ).patch(body);
  }

  cadastrarPagamentos(id, body) {
    return new SiafLiteApiRepository(`${this.urlBase}/${id}/pagamentos`).post(
      body
    );
  }

  desdobrarTitulo(body) {
    return new SiafLiteApiRepository(`${this.urlBase}/desdobrar-titulos`).post(
      body
    );
  }
}

export default ContasReceberService;
