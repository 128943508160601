import React, { useState } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  CircularProgress,
  Select,
  MenuItem,
} from "../../../../components";
import { PessoaService } from "../../../../services";
import { responseErros, timeoutBuscaAutocomplete } from "../../../../utils";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  valueDate,
  onChangeDate,
  valueAutocomplete,
  onChangeAutocomplete,
}) => {
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const pessoaService = new PessoaService();
  const tiposPessoa = [
    {
      tipoId: 1,
      setLista: (dados) => setClienteList(dados),
    },
    {
      tipoId: 4,
      setLista: (dados) => setVendedorList(dados),
    },
  ];

  const listaStatus = [
    {
      label: "Aberta",
      value: "ABERTA",
      cor: "#4FBB53",
    },
    {
      label: "Fechada",
      value: "FECHADA",
      cor: "#004780",
    },
    {
      label: "Expirada",
      value: "EXPIRADA",
      cor: "#EA9D2B",
    },
  ];

  const buscarPessoas = async (filtros, tiposPessoaId) => {
    const filtro = {
      ...filtros,
      tiposPessoaId,
      nonPaginated: true,
      ativado: true,
    };
    const tipoPessoaTemp = tiposPessoa.find(
      ({ tipoId }) => tipoId === tiposPessoaId
    );
    pessoaService.getAll(filtro).then((res) => {
      setLoadingAutoComplete(false);
      if (!res.isAxiosError) {
        tipoPessoaTemp.setLista(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const onInputChangePessoas = (event, value, tiposPessoaId) => {
    if (event?.type != "click" && event?.key != "Enter" && value?.length >= 2) {
      let filtros = {
        nomeRazaoSocial: value,
        apelidoFantasia: value,
      };
      if (tiposPessoaId === 4) {
        filtros = { ...filtros, vendedor: "true" };
      }
      setLoadingAutoComplete(true);
      timeoutBuscaAutocomplete(buscarPessoas(filtros, tiposPessoaId));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="codigo"
          name="codigo"
          label="Código"
          variant="outlined"
          fullWidth
          onChange={(event) => onChangeInput(event)}
          value={valueInput("codigo")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            id="pessoaClienteId"
            name="pessoaClienteId"
            options={clienteList}
            autoHighlight
            getOptionSelected={(option, value) =>
              option?.nomeRazaoSocial === value?.nomeRazaoSocial
            }
            getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
            loading={loadingAutoComplete}
            loadingText="Carregando"
            noOptionsText="Digite para buscar"
            onInputChange={(event, value) =>
              onInputChangePessoas(event, value, 1)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Cliente"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingAutoComplete ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            onChange={(e, value) =>
              onChangeAutocomplete("pessoaClienteId", value)
            }
            value={valueAutocomplete(clienteList, "pessoaClienteId")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            id="pessoaVendedorId"
            name="pessoaVendedorId"
            options={vendedorList}
            autoHighlight
            getOptionSelected={(option, value) =>
              option?.nomeRazaoSocial === value?.nomeRazaoSocial
            }
            getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
            loading={loadingAutoComplete}
            loadingText="Carregando"
            noOptionsText="Digite para buscar"
            onInputChange={(event, value) =>
              onInputChangePessoas(event, value, 4)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vendedor"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingAutoComplete ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            onChange={(e, value) =>
              onChangeAutocomplete("pessoaVendedorId", value)
            }
            value={valueAutocomplete(vendedorList, "pessoaVendedorId")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Select
          id="status"
          name="status"
          value={valueInput("status")}
          label="Status"
          onChange={onChangeInput}
        >
          {listaStatus.map((status, index) => (
            <MenuItem key={index} value={status.value}>
              <i
                className="ph-fill ph-circle"
                style={{ color: `${status.cor}` }}
              ></i>
              &nbsp;{status.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataLancamentoStart"
          name="dataLancamentoStart"
          label="Data Lançamento De"
          format="dd/MM/yyyy"
          value={valueDate("dataLancamentoStart")}
          onChange={(date) => onChangeDate("dataLancamentoStart", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataLancamentoEnd"
          name="dataLancamentoEnd"
          label="Data Lançamento Até"
          format="dd/MM/yyyy"
          value={valueDate("dataLancamentoEnd")}
          onChange={(date) => onChangeDate("dataLancamentoEnd", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataVencimentoStart"
          name="dataVencimentoStart"
          label="Data Vencimento De"
          format="dd/MM/yyyy"
          value={valueDate("dataVencimentoStart")}
          onChange={(date) => onChangeDate("dataVencimentoStart", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataVencimentoEnd"
          name="dataVencimentoEnd"
          label="Data Vencimento Até"
          format="dd/MM/yyyy"
          value={valueDate("dataVencimentoEnd")}
          onChange={(date) => onChangeDate("dataVencimentoEnd", date)}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
