import * as Yup from "yup";

const condicionalValidator = Yup.object().shape({
  pessoaClienteId: Yup.number().required("Cliente"),
  pessoaVendedorId: Yup.number().nullable(),
  dataVencimento: Yup.date().required("Data Vencimento"),
  informacaoAdicional: Yup.string().nullable(),
  itens: Yup.array().min(1, "incluir ao menos 1 produto"),
  condicionalEnderecoCliente: Yup.object().nullable(),
});

export default condicionalValidator;
