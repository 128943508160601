import { Grid as GridMui } from "@mui/material";
import React from "react";

const Grid = React.forwardRef(
  (
    {
      id,
      children,
      className,
      classes,
      columns,
      columnSpacing,
      fullWidth,
      container,
      direction,
      item,
      lg,
      md,
      sm,
      xl,
      rowSpacing,
      spacing,
      sx,
      xs,
      wrap,
      zeroMinWidth,
      onClick,
      onBlur,
      alignContent,
      justifyContent,
      alignItems,
      style,
      onMouseOver,
      onMouseLeave,
      ...rest
    },
    ref
  ) => {
    return (
      <GridMui
        id={id}
        ref={ref}
        className={className}
        classes={classes}
        columns={columns}
        columnSpacing={columnSpacing}
        container={container ? true : false}
        direction={direction}
        fullWidth={fullWidth}
        item={item ? true : false}
        lg={lg}
        md={md}
        sm={sm}
        xl={xl}
        rowSpacing={rowSpacing}
        spacing={spacing}
        sx={sx}
        xs={xs}
        wrap={wrap}
        alignContent={alignContent}
        alignItems={alignItems}
        justifyContent={justifyContent}
        zeroMinWidth={zeroMinWidth}
        onClick={onClick}
        onBlur={onBlur}
        style={style}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        {...rest}
      >
        {children}
      </GridMui>
    );
  }
);

export default Grid;
