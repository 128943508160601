import React, { useEffect, useState } from "react";
import { CabecalhoMainPrint, Grid } from "..";
import { formatPrice } from "../../utils";
import "./table-print-agrupado.css";

const TablePrintAgrupadoComponent = ({
  filtrosAplicadosImpressao,
  relatoriosTotais,
  totais,
  tituloRelatorio,
  print,
  columns,
  subColumns,
  headerRelatorio,
  footerTotalizador,
  styleTable,
  alturaPersonalizada,
}) => {
  const [paginas, setPaginas] = useState([]);
  const arrayIdsRelatorio = Object.keys(relatoriosTotais);
  const altura = alturaPersonalizada || 1300;

  useEffect(() => {
    montaPaginas();
  }, []);

  const montaPaginas = () => {
    let alturaMaxima = 0;
    let itemIndexTemp = 0;
    let relatorioIndexTemp = 0;
    let elementosPagina = [];
    let paginasTemp = [];
    let relatorioItens = [];
    let cabecalhoTabela = true;
    let footerTotalizadorTabela = false;

    while (relatorioIndexTemp < arrayIdsRelatorio.length) {
      const idRelatorio = arrayIdsRelatorio[relatorioIndexTemp];
      const relatorio = relatoriosTotais[idRelatorio];
      const ultimoItem = itemIndexTemp === relatorio.itens.length;
      const ultimaInstancia =
        idRelatorio === arrayIdsRelatorio[arrayIdsRelatorio.length - 1] &&
        ultimoItem;
      const itens = relatorio.itens.slice(itemIndexTemp);

      if (cabecalhoTabela) {
        alturaMaxima += 60;
        elementosPagina.push(headerRelatorio(relatorio));
      }

      itens.forEach((item) => {
        relatorioItens.push(item);
        alturaMaxima += 45;

        if (item.subItens && item.subItens.length > 0) {
          const alturaCabecalhoSub = 45;
          const alturaLinhasSub = 165 * item.subItens.length;
          alturaMaxima += alturaCabecalhoSub + alturaLinhasSub;
        }

        itemIndexTemp++;

        if (alturaMaxima > altura) {
          elementosPagina.push(
            tabela(idRelatorio, relatorioItens, itemIndexTemp)
          );
          paginasTemp.push(pagina(elementosPagina));
          elementosPagina = [headerRelatorio(relatorio)];
          alturaMaxima = 60;
          relatorioItens = [];
          cabecalhoTabela = true;
        }
      });

      if (relatorioItens.length > 0 && alturaMaxima < altura) {
        elementosPagina.push(
          tabela(idRelatorio, relatorioItens, itemIndexTemp)
        );
        if (ultimoItem) {
          if (ultimaInstancia && footerTotalizador) {
            const alturaRestante = altura - alturaMaxima;
            const alturaFooter = 250;
            if (alturaRestante >= alturaFooter) {
              alturaMaxima += alturaFooter;
              elementosPagina.push(footerTotalizador);
              footerTotalizadorTabela = true;
            }
          } else {
            alturaMaxima += 180;
          }
        } else {
          alturaMaxima += 150;
        }
        if (alturaMaxima > altura) {
          paginasTemp.push(pagina(elementosPagina));
          elementosPagina = [headerRelatorio(relatorio)];
          alturaMaxima = 60;
        }
      }

      if (itemIndexTemp === relatorio.itens.length) {
        relatorioIndexTemp++;
        itemIndexTemp = 0;
        relatorioItens = [];
        cabecalhoTabela = true;
      }
    }

    if (!footerTotalizadorTabela) {
      elementosPagina.push(footerTotalizador);
    }
    if (elementosPagina.length > 0) {
      paginasTemp.push(pagina(elementosPagina));
    }
    setPaginas((prevPaginas) => [...prevPaginas, ...paginasTemp]);
  };

  function tabela(idRelatorio, itens, itemIndex) {
    const relatorio = relatoriosTotais[idRelatorio];
    const ultimoItem = itemIndex === relatorio.itens.length;
    return (
      <>
        <Grid item md={12} xs={12} sx={styleTable}>
          {itens.map((item, index) => (
            <React.Fragment key={index}>
              <table id="table-relatorio" className="table table-borderless">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th
                        key={index}
                        style={{
                          textAlign: column?.align || "start",
                          width: column?.width,
                          backgroundColor: styleTable?.headColor || "#cce0ff",
                        }}
                      >
                        {column.header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {columns.map((column, columnIndex) => (
                      <td key={columnIndex}>
                        <div style={{ textAlign: column.align || "start" }}>
                          {column.render
                            ? column.render(item)
                            : item[column.field]}
                        </div>
                      </td>
                    ))}
                  </tr>
                  {item.subItens && item.subItens.length > 0 && (
                    <tr>
                      <td colSpan={columns.length}>
                        {tabelaSubItens(item.subItens)}
                      </td>
                    </tr>
                  )}
                  {index + 1 === itens.length &&
                    ultimoItem &&
                    totais &&
                    totais?.[idRelatorio] && (
                      <tr className="table-primary">
                        {columns.map((column, columnIndex) => (
                          <td key={columnIndex}>
                            <div
                              style={{
                                textAlign: column.align || "start",
                              }}
                            >
                              {totais[idRelatorio][column.fieldTotal] >= 0 ? (
                                <b>
                                  {column.renderTotal
                                    ? column.renderTotal(totais[idRelatorio])
                                    : formatPrice(
                                        totais[idRelatorio][column.fieldTotal],
                                        false
                                      )}
                                </b>
                              ) : null}
                            </div>
                          </td>
                        ))}
                      </tr>
                    )}
                </tbody>
              </table>
            </React.Fragment>
          ))}
        </Grid>
      </>
    );
  }

  function tabelaSubItens(subItens) {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <table
          className="sub-item-table"
          style={{ width: "80%", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              {subColumns.map((subColumn, index) => (
                <th
                  key={index}
                  style={{
                    textAlign: subColumn.align || "start",
                    width: subColumn.width,
                    backgroundColor: "#eff5ff",
                  }}
                >
                  {subColumn.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {subItens.map((subItem, index) => (
              <tr key={index}>
                {subColumns.map((subColumn, colIndex) => (
                  <td key={colIndex}>
                    <div style={{ textAlign: subColumn.align || "start" }}>
                      {subColumn.render
                        ? subColumn.render(subItem)
                        : subItem[subColumn.field]}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  const pagina = (elementosPagina) => {
    return (
      <div className={!print ? "report-container" : "print-container"}>
        {elementosPagina.map((elem, index) => (
          <div key={`elem-${index}`}>{elem}</div>
        ))}
      </div>
    );
  };

  return (
    <>
      {paginas.map((pagina, index) => {
        const paginacaoInicial = filtrosAplicadosImpressao?.numeroPagina || 0;
        pagina.props.children.unshift(
          <CabecalhoMainPrint
            data={{
              tituloRelatorio,
              filtrosAplicadosImpressao,
              print,
              pagina: `${index + 1 + paginacaoInicial}/${
                paginas?.length + paginacaoInicial
              }`,
            }}
          />
        );
        return <div key={index}>{pagina}</div>;
      })}
    </>
  );
};

export default TablePrintAgrupadoComponent;
