import { useEffect, useState } from "react";
import { NfSaidaService } from "../../../../services";
import { formatPrice, responseErros } from "../../../../utils";
import { DashboardCard, DashboardChart } from "../../../main/components";

function RelatorioDashboardClientesFaturamento({
  filtrosAvancados,
  relatoriosDashborad,
  setRelatoriosDashborad,
}) {
  const [loading, setLoading] = useState(false);
  const nfSaidaService = new NfSaidaService();

  useEffect(() => {
    dashboardClientesFaturamento({
      ...filtrosAvancados,
      porIntervalo: [
        {
          ...filtrosAvancados.porIntervalo[0],
          coluna: "dataLancamento",
        },
      ],
    });
  }, [
    filtrosAvancados.porIntervalo[0].de,
    filtrosAvancados.porIntervalo[0].ate,
  ]);

  async function dashboardClientesFaturamento(body) {
    setLoading(true);
    const result =
      await nfSaidaService.getRelatorioDashboardClientesFaturamento(body);
    if (!result.isAxiosError) {
      setRelatoriosDashborad((prevValue) => ({
        ...prevValue,
        clientesFaturamento: {
          categories: result.data.categories,
          series: [
            {
              name: "Cliente",
              type: "bar",
              data: result.data.series,
              borderColor: null,
            },
          ],
        },
      }));
    } else {
      responseErros(result);
    }
    setLoading(false);
  }
  return (
    <DashboardCard
      title="Top 10 Clientes mais vendidos"
      chart={true}
      ActionIcon={
        <i
          className="ph-fill ph-package"
          style={{ color: "#494C62", fontSize: 22 }}
        ></i>
      }
    >
      <DashboardChart
        loading={loading}
        data={relatoriosDashborad.clientesFaturamento}
        heightChart={"70%"}
        options={{
          legend: {
            enabled: false,
          },
          xAxis: {
            type: "category",
            labels: {
              formatter: function () {
                return `<span title="${this.value}">
                  ${this.value.length > 20 ? this.value.substring(0, 20) + '...' : this.value}
                </span>`;
              },
              useHTML: true,
              style: {
                cursor: 'pointer',
                zIndex: -2,
                fontSize: '12px'
              }
            },
            crosshair: true
          },
          yAxis: {
            tickPixelInterval: 150,
            title: {
              text: null,
            },
            labels: {
              style: {
                fontSize: '12px'
              }
            },
            maxPadding: 0.2,
            endOnTick: true,
            alignTicks: true,
            overflow: 'allow',
          },
          plotOptions: {
            series: {
              animation: false,
              groupPadding: 0,
              pointPadding: 0.1,
              borderWidth: 0,
              colorByPoint: true,
              dataSorting: {
                enabled: true,
                matchByName: true,
              },
              type: "bar",
              dataLabels: {
                style: {
                  color: "#666",
                  fontWeight: 600,
                  border: "none",
                },
                align: 'left',
                enabled: true,
                formatter: function () {
                  return formatPrice(this.y);
                },
              },
            },
          },
          tooltip: {
            shared: true,
            useHTML: true,
            backgroundColor: "#fff",
            borderWidth: 0,
            borderRadius: 4,
            formatter: function () {
              return `
                <div style="padding: 3px">
                  <span style="font-size: 12px">${this.key}</span><br/>
                  <b>${formatPrice(this.points[0].y)}</b>
                </div>
              `;
            }
          },
        }}
      />
    </DashboardCard>
  );
}

export default RelatorioDashboardClientesFaturamento;
