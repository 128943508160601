import { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  CardHeader,
  Dialog,
  Loading,
} from "../../../../../components";
import {
  CondicionaisService,
  PessoaService,
  history,
  OrigemMercadoriaService,
  UnidadeComercialService,
  ProdutosService,
} from "../../../../../services";
import { responseErros, formatDate, formatPrice } from "../../../../../utils";
import { CondicionalStepperContent } from "..";
import { NfSaida, NfSaidaItem } from "../../../entities";
import {
  formatProdutosTotalizadoresDto,
  totalVenda,
} from "../../../add-nf-saida/add-nf-saida";

const ImportarCondicionalDialog = ({
  openImportarCondicionalDialog,
  setOpenImportarCondicionalDialog,
  filtrosDialog,
  setFiltrosDialog,
  listaOperacoesFiscais
}) => {
  const condicionaisService = new CondicionaisService();
  const pessoaService = new PessoaService();
  const unidadeComercialService = new UnidadeComercialService();
  const origemMercadoriaService = new OrigemMercadoriaService();
  const produtosService = new ProdutosService();

  const [loading, setLoading] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [condicionais, setCondicionais] = useState([]);
  const [condicionaisSelecionados, setCondicionaisSelecionados] = useState([]);
  const [condicionaisSelectionModel, setCondicionaisSelectionModel] = useState([]);
  const [itemSelectionModel, setItemSelectionModel] = useState([]);
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [totalListaCondicionais, setTotalListaCondicionais] = useState(0);
  const [unidadeList, setUnidadeList] = useState([]);
  const [origensMercadoriaList, setOrigensMercadoriaList] = useState([]);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [operacaoFiscal, setOperacaoFiscal] = useState({});
  const steps = ["Selecionar Orçamentos", "Selecionar Itens e Importar"];
  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      width: 100,
    },
    {
      field: "dataLancamento",
      headerName: "Data de Lançamento",
      width: 200,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "dataVencimento",
      headerName: "Data de Vencimento",
      width: 200,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "pessoaClienteId",
      headerName: "Cliente",
      width: 300,
      sortable: false,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id == params.value)?.nomeRazaoSocial,
    },
    {
      field: "itens",
      headerName: "Total",
      flex: 100,
      sortable: false,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => {
        const totalCondicional = params.value
          .map((item) => item.valorUnitario * item.quantidade)
          .reduce((acumulador, total) => acumulador + total, 0);
        return formatPrice(totalCondicional);
      },
    },
  ];

  useEffect(() => {
    if (openImportarCondicionalDialog) {
      buscarCondicionais();
      buscarUnidadesComerciais();
      buscarOrigensMercadoria();
    }
  }, [openImportarCondicionalDialog]);

  const buscarCondicionais = async (filtro) => {
    setLoading(true);
    const filtros = {
      ...filtro,
      status: ["ABERTA", "EXPIRADA"],
    };
    const result = await condicionaisService.getAllFiltroAvancado(filtros);
    if (!result.isAxiosError) {
      setTotalListaCondicionais(result.data.count);
      setCondicionais(result.data.rows);
      buscarRelacionamentosCondicional(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarCondicionaisAvancado = async (filtros) => {
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filtros,
      status: ["ABERTA", "EXPIRADA"],
      restritiva: true,
    };
    if (filtrosAvancadosTemp.dataInicial && filtrosAvancadosTemp.dataFinal) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataInicial,
        ate: filtrosAvancadosTemp.dataFinal,
      });
    }
    delete filtrosAvancadosTemp.dataInicial;
    delete filtrosAvancadosTemp.dataFinal;
    const result = await condicionaisService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setCondicionais(result.data.rows);
      setTotalListaCondicionais(result.data.rows.length);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarRelacionamentosCondicional = (listaCondicional) => {
    const idsPessoas = [];
    for (const objeto of listaCondicional) {
      if (objeto.pessoaClienteId) idsPessoas.push(objeto.pessoaClienteId);
      if (objeto.pessoaVendedorId) idsPessoas.push(objeto.pessoaVendedorId);
    }
    buscarPessoas([...new Set(idsPessoas)]);
  };

  const buscarPessoas = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        const listaPessoas = result.data;
        setListaPessoas(listaPessoas);
      } else {
        responseErros(result);
      }
    });
  };

  const handleDateChange = (date, fieldName) => {
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    }));
  };

  const handleInputChange = (e) => {
    e.persist();
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleImportarCondicional = async () => {
    window.nfSaidaEntity = await createNfSaida(
      condicionaisSelecionados[0],
      condicionaisSelecionados,
      itensSelecionados
    );
    setOpenImportarCondicionalDialog(false);
    resetarDadosImportacao();
    history.push("/faturamento/nf-saida/importar-condicional");
  };

  const buscarOrigensMercadoria = async () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriaList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarUnidadesComerciais = async () => {
    const filtros = {
      nonPaginated: true,
    };
    unidadeComercialService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setUnidadeList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const createNfSaida = async (condicional, condicionais, itens) => {
    const itensTemp = await buscarDadosProdutos(
      formatProdutosTotalizadoresDto(
        itens,
        totalVenda(condicional, itens),
        condicional
      )
    );
    const nfSaidaItens = itensTemp
      .filter((item) => itemSelectionModel.indexOf(item.id) > -1)
      .map((item, index) => {
        const nfSaidaItem = new NfSaidaItem(
          item,
          index,
          operacaoFiscal,
          origensMercadoriaList,
          unidadeList
        );
        return {
          ...nfSaidaItem,
          produto: item.produto,
          valor: parseFloat(item.valorUnitario.toFixed(2)),
          subtotal:
            parseFloat(item.valorUnitario.toFixed(2)) * item.saldoExportar,
        };
      });
    const informacoesAdicionais = condicionais
      .map((pedido) => {
        return `Importado do orçamento ${pedido.serieDocumento}/${pedido.numeroDocumento}`;
      })
      .join("\n");
    let cliente = {};
    if (condicional.pessoaClienteId) {
      cliente = listaPessoas.find(
        (item) => item.id == condicional.pessoaClienteId
      );
    }
    const dadosNfSaida = {
      dataEmissao: formatDate.toSend(new Date()),
      modalidadeFrete: "mfSemFrete",
      freteCompoeNota: false,
      condicionalImport: true,
      informacoesAdicionais,
      condicionalIds: condicionais.map((condicional) => condicional.id),
      clienteId: condicional?.pessoaClienteId ?? undefined,
      clienteEmail: cliente?.email,
      clienteCnpjCpf: cliente?.cnpjCpf,
      clienteNome: cliente?.nomeRazaoSocial,
      vendedorId: condicional?.pessoaVendedorId ?? undefined,
      prestadorServicoId: condicional.pessoaProficionalId,
      operacaoFiscal,
      operacaoFiscalId: operacaoFiscal?.id,
      serieDocumento: operacaoFiscal?.serieDocumento,
      modeloDocumento: operacaoFiscal?.modeloDocumento,
      meioPagamentoId: condicional.meioPagamentoId,
      condicaoPagamentoId: condicional.condicaoPagamentoId,
      desconto: condicional.desconto ?? 0,
      acrescimo: condicional.acrescimo ?? 0,
      despesas: condicional.despesas ?? 0,
      nfSaidaItens,
    };
    return new NfSaida(dadosNfSaida, true);
  };

  async function buscarDadosProdutos(itens) {
    const listaIds = itens.map(({ produtoId }) => produtoId);
    const res = await produtosService.getAll({
      id: listaIds,
      nonPaginated: true,
    });
    if (!res.isAxiosError) {
      const produtosMap = new Map(
        res.data.map((produto) => [produto.id, produto])
      );
      return itens.map((item) => ({
        ...item,
        ...produtosMap.get(item.produtoId),
        id: item.id,
      }));
    } else {
      responseErros(res);
    }
  }

  const resetarDadosImportacao = () => {
    setFiltrosDialog({});
    setCondicionaisSelecionados([]);
    setActiveStep(0);
  };

  const handleCancelarImportacao = () => {
    resetarDadosImportacao();
    setOpenImportarCondicionalDialog(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      if (props.filtros?.status) {
        delete props.filtros.status;
      }
      buscarCondicionais(props.filtros);
    } else {
      buscarCondicionaisAvancado(props.filtros);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xl" open={openImportarCondicionalDialog}>
      <Box sx={{ m: 2 }}>
        <CardHeader title="Importar Orçamento" />
      </Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <CondicionalStepperContent
        sendServer={sendServerDatagrid}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleImportarCondicional={handleImportarCondicional}
        handleCancelarImportacao={handleCancelarImportacao}
        handleDateChange={handleDateChange}
        handleInputChange={handleInputChange}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
        colunas={colunas}
        condicionais={condicionais}
        totalListaCondicionais={totalListaCondicionais}
        condicionaisSelecionados={condicionaisSelecionados}
        setCondicionaisSelecionados={setCondicionaisSelecionados}
        itensSelecionados={itensSelecionados}
        setItensSelecionados={setItensSelecionados}
        buscarCondicionaisAvancado={buscarCondicionaisAvancado}
        listaClientes={listaPessoas}
        itemSelectionModel={itemSelectionModel}
        setItemSelectionModel={setItemSelectionModel}
        condicionaisSelectionModel={condicionaisSelectionModel}
        setCondicionaisSelectionModel={setCondicionaisSelectionModel}
        listaOperacoesFiscais={listaOperacoesFiscais}
        operacaoFiscal={operacaoFiscal}
        setOperacaoFiscal={setOperacaoFiscal}
      />
      <Loading loading={loading} />
    </Dialog>
  );
};

export default ImportarCondicionalDialog;
