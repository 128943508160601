import { format } from "date-fns";
import { formatDate, formatPrice } from "../../../../utils";
import { TablePrintDetalhadoComponent } from "../../../../components";
import { FooterTotalizadoresRelatorioContas } from "./components";
import HeaderDadosPessoa from "../../components/header-dados-pessoa/header-dados-pessoa.component";

const PrintContasReceberDetalhado = ({
  filtrosAplicadosImpressao,
  relatoriosTotais,
  tituloRelatorio,
  print,
}) => {
  const columns = [
    {
      header: "Doc.",
      field: "numeroTitulo",
    },
    {
      header: "Data Lanç.",
      field: "dataLancamento",
      render: ({ dataLancamento }) =>
        format(formatDate.received(dataLancamento), "dd/MM/yyyy"),
    },
    {
      header: "Data Venc.",
      field: "dataVencimento",
      render: ({ dataVencimento }) =>
        format(formatDate.received(dataVencimento), "dd/MM/yyyy"),
    },
    {
      header: "Data Pag.",
      field: "dataPagamento",
      render: ({ dataPagamento }) =>
        dataPagamento
          ? format(formatDate.received(dataPagamento), "dd/MM/yyyy")
          : "",
    },
    {
      header: "Meio Pag.",
      field: "meioPagamento",
      align: "center",
      limit: true,
    },
    {
      header: "Valor",
      field: "valor",
      fieldTotal: "total",
      align: "end",
      render: ({ valor }) => formatPrice(valor, false),
    },
    {
      header: "Juros",
      field: "juros",
      fieldTotal: "totalJuros",
      align: "center",
      render: ({ juros }) => formatPrice(juros, false),
    },
    {
      header: "Multa",
      field: "multa",
      fieldTotal: "totalMulta",
      align: "center",
      render: ({ multa }) => formatPrice(multa, false),
    },
    {
      header: "Desconto",
      field: "descontos",
      fieldTotal: "totalDescontos",
      align: "center",
      render: ({ descontos }) => formatPrice(descontos, false),
    },
    {
      header: "Atraso",
      field: "atraso",
      align: "center",
      render: ({ atraso }) => atraso ?? 0,
    },
    {
      header: "Valor Pago",
      field: "valorpago",
      fieldTotal: "totalPago",
      align: "end",
      render: ({ valorpago }) => formatPrice(valorpago, false),
    },
    {
      header: "Saldo a Rec.",
      field: "valorReceber",
      fieldTotal: "totalReceber",
      align: "end",
      render: ({ valorReceber }) => formatPrice(valorReceber, false),
    },
  ];
  return (
    <TablePrintDetalhadoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais.contasReceber}
      totais={relatoriosTotais.totais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      headerRelatorio={HeaderDadosPessoa}
      footerTotalizador={
        <FooterTotalizadoresRelatorioContas
          totalizadores={relatoriosTotais.totalizadores}
        />
      }
    />
  );
};

export default PrintContasReceberDetalhado;
