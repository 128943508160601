import { useState, useEffect } from "react";
import { NfSaidaService } from "../../../services";
import { formatPrice, responseErros } from "../../../utils";
import { DashboardCard, DashboardChart } from ".";
import { Button, ButtonGroup } from "../../../components";

const ResumoVendasHoraMes = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ categories: [], series: [] });
  const [buscaHora, setBuscaHora] = useState(true);
  const [maxY, setMaxY] = useState();
  const nfSaidaService = new NfSaidaService();

  const gerarHorasDoDia = (vendasExtemporanea) => {
    let horas = [];
    for (let i = 0; i < 25; i++) {
      horas.push(`${i.toString()}h`);
    }
    return vendasExtemporanea ? horas : horas.slice(6, 21);
  };

  const gerarDadosPorHora = (dados, vendasExtemporanea) => {
    let dadosPorHora = [];
    dados.forEach((item) => {
      for (let i = 0; i < 25; i++) {
        if (i == item.hora) {
          dadosPorHora[i] = item.total;
        } else {
          dadosPorHora[i] = dadosPorHora[i] ? dadosPorHora[i] : 0;
        }
      }
    });
    return vendasExtemporanea ? dadosPorHora : dadosPorHora.slice(6, 21);
  };

  const gerarLegendaYDashboardHoras = (dados) => {
    const registrosTotais = dados.map(({ total }) => total);
    setMaxY(dados.length ? Math.max(...registrosTotais) : 100);
  };

  const gerarLegendaYDashboardMes = (dados) => {
    let totalGeral = 0;
    dados.forEach((dado) => {
      const maiorValor = Math.max(
        dado.totalEfetivadas,
        dado.totalDevolvidas,
        dado.totalCanceladas
      );
      totalGeral = Math.max(totalGeral, maiorValor);
    });
    setMaxY(dados.length ? totalGeral : 100);
  };

  const buscarResumoVendas = () => {
    nfSaidaService.getVendasPorMes().then((result) => {
      if (!result.isAxiosError) {
        gerarLegendaYDashboardMes(result.data);
        gerarDadosDashboardMes(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarResumoVendasPorHora = () => {
    nfSaidaService.getVendasPorHora().then((result) => {
      if (!result.isAxiosError) {
        const results = [
          ...result.data.ativo,
          ...result.data.cancelado,
          ...result.data.devolvido,
        ];
        gerarLegendaYDashboardHoras(results);
        const vendasExtemporanea = results.find(
          (venda) => venda.hora > 20 || venda.hora < 6
        );
        gerarDadosDashboardHora(
          result.data.ativo,
          result.data.cancelado,
          result.data.devolvido,
          vendasExtemporanea
        );
      } else {
        responseErros(result);
      }
    });
  };

  function gerarDadosDashboardMes(data) {
    setData({
      categories: data?.length ? data.map((item) => item.mesAno) : [],
      series: [
        {
          name: "Efetivadas",
          data: data?.length ? data.map((item) => item.totalEfetivadas) : [],
          color: "#2196F3",
          borderColor: null,
        },
        {
          name: "Canceladas",
          data: data?.length ? data.map((item) => item.totalCanceladas) : [],
          color: "#f72525",
          borderColor: null,
        },
        {
          name: "Devolvidas",
          data: data?.length ? data.map((item) => item.totalDevolvidas) : [],
          color: "#104870",
          borderColor: null,
        },
      ],
    });
  }

  function gerarDadosDashboardHora(
    ativos,
    cancelados,
    devolvidos,
    vendasExtemporanea
  ) {
    setData({
      categories: gerarHorasDoDia(vendasExtemporanea),
      series: [
        {
          name: "Efetivadas",
          data: gerarDadosPorHora(ativos, vendasExtemporanea),
          color: "#2196F3",
          borderColor: null,
        },
        {
          name: "Canceladas",
          data: gerarDadosPorHora(cancelados, vendasExtemporanea),
          color: "#f72525",
          borderColor: null,
        },
        {
          name: "Devolvidas",
          data: gerarDadosPorHora(devolvidos, vendasExtemporanea),
          color: "#104870",
          borderColor: null,
        },
      ],
    });
  }

  useEffect(() => {
    buscarResumo(buscaHora);
  }, [buscaHora]);

  const buscarResumo = (tipoBusca) => {
    setLoading(true);
    if (tipoBusca) {
      buscarResumoVendasPorHora();
    } else {
      buscarResumoVendas();
    }
    setBuscaHora(tipoBusca);
    setLoading(false);
  };

  return (
    <DashboardCard
      title="Vendas"
      chart={true}
      ActionIcon={
        <div className="d-flex align-items-center gap-3">
          <ButtonGroup aria-label="resumo vendas button group">
            <Button
              size="small"
              variant={buscaHora ? "contained" : "outlined"}
              onClick={() => buscarResumo(true)}
            >
              Diário
            </Button>
            <Button
              size="small"
              variant={!buscaHora ? "contained" : "outlined"}
              onClick={() => buscarResumo(false)}
            >
              Mensal
            </Button>
          </ButtonGroup>
          <i
            className="ph-fill ph-trend-up"
            style={{ color: "#494C62", fontSize: 22 }}
          ></i>
        </div>
      }
    >
      <DashboardChart
        loading={loading}
        data={data}
        options={{
          chart: {
            type: "spline",
          },
          tooltip: {
            shared: true,
            useHTML: true,
            backgroundColor: "#fff",
            borderWidth: 0,
            borderColor: null,
            borderRadius: 4,
            formatter: function () {
              const { x, points } = this;
              return `
                      <div className="chart-tip">
                        <span className="title">${x}</span>
                        ${points
                          .map(
                            ({ series, y }) => `
                            <div className='content'>
                              <span style="color: ${
                                series.color
                              }; font-size: 20px;">●</span>
                              <b>${series.name}: </b> ${
                              y !== 0 ? formatPrice(y) : formatPrice("0")
                            }
                            </div>             
                          `
                          )
                          .join("")}
                      </div>`;
            },
          },
          xAxis: {
            labels: {
              style: {
                fontSize: 10,
              },
            },
          },
          yAxis: {
            min: 0,
            max: maxY,
            labels: {
              style: {
                fontSize: 10,
              },
              formatter: function ({ value }) {
                if (value >= 1000) {
                  return value / 1000 + "k";
                }
                return value;
              },
            },
          },
          plotOptions: {
            area: {
              fillOpacity: 0.5,
            },
          },
        }}
      />
    </DashboardCard>
  );
};

export default ResumoVendasHoraMes;
