import { useEffect } from "react";
import { NfSaidaService } from "../../../../services";
import { formatPrice, responseErros } from "../../../../utils";
import { DashboardCard } from "../../../main/components";

function RelatorioDashboardTicketMedio({
  filtrosAvancados,
  relatoriosDashborad,
  setRelatoriosDashborad,
}) {
  const nfSaidaService = new NfSaidaService();

  useEffect(() => {
    dashboardQtdVendas({
      ...filtrosAvancados,
      porIntervalo: [
        {
          ...filtrosAvancados.porIntervalo[0],
          coluna: "dataLancamento",
        },
      ],
    });
  }, [
    filtrosAvancados.porIntervalo[0].de,
    filtrosAvancados.porIntervalo[0].ate,
  ]);

  async function dashboardQtdVendas(body) {
    const result = await nfSaidaService.getRelatorioDashboardTicketMedio(body);
    if (!result.isAxiosError) {
      setRelatoriosDashborad((prevValue) => ({
        ...prevValue,
        qtdVendas: result.data || 0,
      }));
    } else {
      responseErros(result);
    }
  }
  return (
    <DashboardCard
      title="Ticket Médio"
      ActionIcon={
        <i
          className="ph-fill ph-scroll"
          style={{ color: "#494C62", fontSize: 22 }}
        ></i>
      }
    >
      <b className="fs-5">
        {formatPrice(
          relatoriosDashborad.faturamento / (relatoriosDashborad.qtdVendas || 1)
        )}
      </b>
    </DashboardCard>
  );
}

export default RelatorioDashboardTicketMedio;
