import { Chip } from "@mui/material";

export default function ChipStatus({ label }) {
  const cores = [
    {
      cor: "#4FBB53",
      status: ["ABERTA", "ABERTO", "ENVIADA"],
    },
    {
      cor: "#004780",
      status: ["FECHADA", "FECHADO", "FATURADA", "BAIXADO"],
    },
    {
      cor: "#EA9D2B",
      status: [
        "EXPIRADA",
        "PENDENTE",
        "PARCIALMENTE_FECHADO",
        "BAIXADO_PARCIAL",
      ],
    },
    {
      cor: "#DC3545",
      status: ["CANCELADA", "CANCELADO"],
    },
  ];

  const { cor } = cores.find((cor) => cor.status.includes(label));

  return (
    <Chip
      label={label.replace("_", " ")}
      sx={{
        backgroundColor: `${cor}DD`,
        borderRadius: "4px",
        height: "23px",
        width: "100%",
        "& .MuiChip-label": {
          fontWeight: "600",
          fontSize: "11px",
          letterSpacing: ".2px",
          color: "white",
        },
      }}
    />
  );
}
