import { useState, useEffect } from "react";
import { format } from "date-fns";
import { ListLayout } from "../../../layouts";
import { ChipStatus, DataGrid } from "../../../components";
import {
  history,
  CondicionaisService,
  PessoaService,
  notification,
} from "../../../services";
import { formatPrice, responseErros } from "../../../utils";
import { FormFiltroAvancado } from "./components";
import { formatDate } from "../../../utils";
import { filtrosUrl, PermisoesHelper } from "../../../helpers";

const ListCondicionaisView = () => {
  const [loading, setLoading] = useState(false);
  const [condicionais, setCondicionais] = useState([]);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const pessoaService = new PessoaService();
  const condicionaisService = new CondicionaisService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("condicionais-visualizar");

  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 90,
    },
    {
      field: "dataLancamento",
      headerName: "Data de Lançamento",
      flex: 130,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "pessoaClienteId",
      headerName: "Cliente",
      flex: 350,
      sortable: false,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id == params.value)?.nomeRazaoSocial,
    },

    {
      field: "dataVencimento",
      headerName: "Data de Vencimento",
      flex: 130,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <ChipStatus label={params.value} />,
    },
    {
      field: "itens",
      headerName: "Total",
      flex: 100,
      sortable: false,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => {
        const totalCondicional = params.value
          .map((item) => item.valorUnitario * item.quantidade)
          .reduce((acumulador, total) => acumulador + total, 0);
        return formatPrice(totalCondicional);
      },
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscarCondicionais(filtros);
  }, []);

  const buscarCondicionais = async function (filtro) {
    setLoading(true);
    const result = await condicionaisService.getAll(filtro);
    if (!result.isAxiosError) {
      setCondicionais(result.data.rows);
      setRowCount(result.data.count);
      buscarRelacionamentosCondicional(result.data.rows);
      filtrosUrl.set(filtro);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarRelacionamentosCondicional = (listaDocumento) => {
    const idsPessoas = [
      ...new Set(
        listaDocumento
          .map(({ pessoaClienteId }) => (pessoaClienteId ? pessoaClienteId : 0))
          .filter(
            (pessoaClienteId, index, listaDocumento) =>
              listaDocumento.indexOf(pessoaClienteId) === index
          )
      ),
    ];
    buscarPessoas(idsPessoas);
  };

  const buscarPessoas = (listaIds) => {
    if (listaIds.length) {
      const filtro = {
        id: listaIds,
        nonPaginated: true,
      };
      pessoaService.getAll(filtro).then((result) => {
        if (!result.isAxiosError) {
          setListaPessoas(result.data);
        } else {
          responseErros(result);
        }
      });
    }
  };

  const onClickCadastrarCondicionais = function () {
    history.push("/faturamento/condicionais/create");
  };

  const onClickEditCondicionais = function (id) {
    history.push(`/faturamento/condicionais/${id}`);
  };

  const buscarPesquisaAvancada = async (filter) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    if (
      filtrosAvancadosTemp.dataLancamentoStart &&
      filtrosAvancadosTemp.dataLancamentoEnd
    ) {
      porIntervalo.push({
        coluna: "dataLancamento",
        de: filtrosAvancadosTemp.dataLancamentoStart,
        ate: filtrosAvancadosTemp.dataLancamentoEnd,
      });
    }
    if (filtrosAvancadosTemp.totalStart && filtrosAvancadosTemp.totalEnd) {
      porIntervalo.push({
        coluna: "totalVenda",
        de: parseFloat(filtrosAvancadosTemp.totalStart),
        ate: parseFloat(filtrosAvancadosTemp.totalEnd),
      });
    }
    delete filtrosAvancadosTemp.dataLancamentoStart;
    delete filtrosAvancadosTemp.dataLancamentoEnd;
    delete filtrosAvancadosTemp.totalStart;
    delete filtrosAvancadosTemp.totalEnd;
    setLoading(true);
    const result = await condicionaisService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setCondicionais(result.data.rows);
      setRowCount(result.data.count);
      buscarRelacionamentosCondicional(result.data.rows);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      if (props.filtros?.status) {
        delete props.filtros.status;
      }
      buscarCondicionais(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  const onClickCancelarCondicional = async function (id) {
    const condicional = condicionais.find((item) => item.id === id);
    if (condicional) {
      if (condicional.status === "CANCELADO") {
        return notification.alertaGenericos(
          "Essa condicional já está cancelado"
        );
      }
      const retornoAlerta = await notification.confirmacao(
        "Cancelar!",
        "Tem certeza que deseja cancelar essa condicional?"
      );
      if (retornoAlerta.isConfirmed) {
        setLoading(true);
        const response = await condicionaisService.cancelar(condicional.id);
        setLoading(false);
        if (!response.isAxiosError) {
          notification.alteracaoSucesso();
          buscarCondicionais();
        } else {
          responseErros(response);
        }
      }
    }
  };

  return (
    <ListLayout
      title="Condicionais"
      loading={loading}
      onClickCadastrar={onClickCadastrarCondicionais}
      permissaoButton={permissoesHelper.temPermisao("condicionais-cadastrar")}
    >
      {/* <Grid container spacing={2}>
        {status.map((status) => (
          <span className="d-flex m-3" key={status.id}>
            <i
              className="ph-fill ph-circle"
              style={{ color: status.cor, fontSize: 18, marginRight: 8 }}
            ></i>
            <Typography className="me-5">{status.nome}</Typography>
          </span>
        ))}
      </Grid> */}
      <DataGrid
        rows={condicionais}
        columns={colunas}
        onClickEdit={onClickEditCondicionais}
        onClickDelete={onClickCancelarCondicional}
        visualizarShow={permissoesHelper.temPermisao("condicionais-visualizar")}
        editShow={permissoesHelper.temPermisao("condicionais-editar")}
        deleteShow={permissoesHelper.temPermisao("condicionais-excluir")}
        rowCount={rowCount}
        paginationMode={"server"}
        sendServer={sendServerDatagrid}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListCondicionaisView;
