import {
  TextField as MuiTextField,
  InputAdornment,
  IconButton,
} from "@mui/material";

export default function Select({
  id,
  autoFocus,
  children,
  name,
  label,
  variant,
  margin,
  value,
  error,
  style,
  disabled,
  type,
  className,
  onChange,
  fullWidth,
  InputLabelProps,
  InputProps,
  inputProps,
  required,
  onFocus,
  onBlur,
  size,
  sx,
  disableClearable,
  placeholder,
  ...rest
}) {
  const mergedInputProps = {
    ...(InputProps || {}),
    endAdornment: (
      <>
        {InputProps?.endAdornment}
        {value !== null &&
          value !== undefined &&
          value !== "" &&
          !disableClearable && (
            <InputAdornment position="end" sx={{ mr: 3 }}>
              <IconButton
                onClick={() => {
                  if (onChange) {
                    onChange({
                      target: {
                        value: null,
                        name,
                      },
                    });
                  }
                }}
                edge="end"
                size="small"
              >
                <i
                  className="ph-bold ph-x"
                  style={{
                    fontSize: "1.125rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                ></i>
              </IconButton>
            </InputAdornment>
          )}
      </>
    ),
  };

  return (
    <MuiTextField
      id={id}
      name={name}
      label={label}
      autoFocus={autoFocus ?? false}
      className={className}
      disabled={disabled ?? false}
      type={type}
      variant={variant || "outlined"}
      margin={margin || "none"}
      value={value}
      onChange={onChange}
      select={true}
      error={error}
      style={style}
      size={size}
      sx={sx}
      onFocus={onFocus}
      onBlur={onBlur}
      fullWidth={fullWidth ?? true}
      inputProps={inputProps}
      InputLabelProps={{
        shrink: value !== null && value !== undefined,
        ...InputLabelProps,
      }}
      InputProps={mergedInputProps}
      required={required}
      placeholder={placeholder}
      {...rest}
    >
      {children}
    </MuiTextField>
  );
}
