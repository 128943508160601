import SiafLiteApiRepository from "../../external/siafLiteApi";

class ProdutosService {
  constructor() {
    this.urlBase = "/estoque/produtos";
  }

  getAll(filtros) {
    return new SiafLiteApiRepository(this.urlBase).get(filtros);
  }

  getAllFiltroAvancado(body) {
    return new SiafLiteApiRepository(this.urlBase).patch(body);
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  cadastrarListaProdutos(body) {
    return new SiafLiteApiRepository(`${this.urlBase}/lista`).post(body);
  }

  getAllProdutosFornecedores(filtros) {
    return new SiafLiteApiRepository(`${this.urlBase}/fornecedores`).get(
      filtros
    );
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }

  atualizarLista(body) {
    return new SiafLiteApiRepository(this.urlBase).putBase(
      "/lista/nf-entrada",
      body
    );
  }

  desativar(id) {
    return new SiafLiteApiRepository(this.urlBase).putBase(`/desativar/${id}`);
  }

  getByCodbar(codbar) {
    return new SiafLiteApiRepository(`${this.urlBase}/codbar/${codbar}`).get();
  }

  validarCodigoBarras(codbar) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/codigobarras/${codbar}`
    ).get();
  }

  validarEtiquetaVinculada(etiquetaId) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/etiqueta-vinculada/${etiquetaId}`
    ).get();
  }

  validarCodigoBarrasId(id, codbar) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/codigobarras/${id}/${codbar}`
    ).get();
  }

  atualizarGrade(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body, "grade");
  }

  getConfispedApi(body) {
    return new SiafLiteApiRepository(`${this.urlBase}/confisped`).patch(body);
  }

  getRelatorioResumido(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/resumido`
    ).patch(body);
  }

  getRelatorioDetalhado(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/detalhado`
    ).patch(body);
  }

  getInventarioDeEstoque(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/inventario`
    ).patch(body);
  }

  getRelatorioEstoqueFinanceiro(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/estoque-financeiro`
    ).patch(body);
  }

  getCustoMercadoriaVendida(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/custo-mercadoria-vendida`
    ).patch(body);
  }

  getRelatorioDashboardCmv(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/dashboard-cmv`
    ).patch(body);
  }

  getRelatorioClienteGrade(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/clientes-grade`
    ).patch(body);
  }

  getAnaliseProdutosVendidos(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/analise-produtos-vendidos`
    ).patch(body);
  }
}

export default ProdutosService;
