import { useEffect } from "react";
import { NfSaidaService } from "../../../../services";
import { formatPrice, responseErros } from "../../../../utils";
import { DashboardCard } from "../../../main/components";

function RelatorioDashboardFaturamento({
  filtrosAvancados,
  relatoriosDashborad,
  setRelatoriosDashborad,
}) {
  const nfSaidaService = new NfSaidaService();

  useEffect(() => {
    dashboardFaturamento({
      ...filtrosAvancados,
      porIntervalo: [
        {
          ...filtrosAvancados.porIntervalo[0],
          coluna: "dataLancamento",
        },
      ],
    });
  }, [
    filtrosAvancados.porIntervalo[0].de,
    filtrosAvancados.porIntervalo[0].ate,
  ]);

  async function dashboardFaturamento(body) {
    const result = await nfSaidaService.getRelatorioDashboardFaturamento(body);
    if (!result.isAxiosError) {
      setRelatoriosDashborad((prevValue) => ({
        ...prevValue,
        faturamento: result.data || 0,
      }));
    } else {
      responseErros(result);
    }
  }
  return (
    <DashboardCard
      title="Faturamento"
      ActionIcon={
        <i
          className="ph-bold ph-currency-dollar"
          style={{ color: "#494C62", fontSize: 22 }}
        ></i>
      }
    >
      <b className="fs-5">{formatPrice(relatoriosDashborad.faturamento)}</b>
    </DashboardCard>
  );
}

export default RelatorioDashboardFaturamento;
