import { DatePicker, Grid } from "../../../../components";
import { useFiltrosRelatoriosHandlers } from "../../components/use-filtros-relatorios-handlers";
import { value } from "../../../../helpers";

const FormFiltroAvancadoAniversario = ({
  filtrosAvancados,
  setFiltrosAvancados,
  setFiltrosAplicadosImpressao,
}) => {
  const { onChangeDate } = useFiltrosRelatoriosHandlers(
    setFiltrosAvancados,
    setFiltrosAplicadosImpressao
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePicker
            id="dataReferencia"
            name="Data"
            label="Mês"
            margin
            fullWidth
            format="MM"
            views={["month"]}
            value={value.date(filtrosAvancados?.dataReferencia)}
            onChange={(date) => onChangeDate("dataReferencia", date, "Data")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormFiltroAvancadoAniversario;
