import { useState, useEffect, createRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Box,
} from "../../../../components";
import { useDropzone } from "react-dropzone";
import { responseErros } from "../../../../utils";
import { notification, SessionService } from "../../../../services";
import CertificadoDigitalService from "../../../../services/api/certificado-digital.service";
import { format, isAfter, parse } from "date-fns";

const certificadoDigitalService = new CertificadoDigitalService();
const sessionService = new SessionService();

const CertificadoDigitalCard = () => {
  const [certificado, setCertificado] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [arquivoSelecionado, setArquivoSelecionado] = useState(null);
  const empresa = sessionService.getEmpresa();

  const fileUploadRef = createRef();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".pfx,.p12",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        handleCertificadoChange(acceptedFiles);
      }
    },
  });

  const handleCertificadoChange = (event) => {
    let files;
    if (event.dataTransfer) {
      files = event.dataTransfer.files[0];
    } else if (event.target) {
      files = event.target.files[0];
    }
    setArquivoSelecionado(files);
  };

  useEffect(() => {
    carregarCertificado();
  }, [empresa.cnpjCpf]);

  async function carregarCertificado() {
    setLoading(true);
    try {
      const result = await certificadoDigitalService.consultar(empresa.cnpjCpf);
      if (!result.isAxiosError) {
        const dataString = result.data.split(" ")[0];
        const dataVencimento = parse(dataString, "MM/dd/yyyy", new Date());
        setCertificado({
          vencimento: format(dataVencimento, "dd/MM/yyyy"),
          valido: isAfter(dataVencimento, new Date()),
        });
      }
    } catch (error) {
      responseErros(error);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async () => {
    if (!arquivoSelecionado || !password) return;

    setUploading(true);
    try {
      const formData = new FormData();
      formData.append("files", arquivoSelecionado);
      formData.append("senha", password);

      const res = await certificadoDigitalService.enviar(
        formData,
        empresa.cnpjCpf
      );
      if (!res.isAxiosError) {
        notification.sucesso("Certificado atualizado com sucesso!");
        await carregarCertificado();
      }
    } catch (error) {
      responseErros(error);
    } finally {
      setUploading(false);
    }
  };

  const renderCertificadoStatus = () => {
    if (loading) return <CircularProgress size={24} />;
    if (certificado) {
      return (
        <Box className="d-flex flex-column align-items-center justify-content-center text-center gap-2">
          <i
            style={{ fontSize: "4rem", color: "#4FBB4C" }}
            className={`ph-duotone ph-${
              certificado?.valido ? "check-circle" : "warning-circle"
            }`}
          ></i>
          <h7 className="text-muted fw-semibold">
            {" "}
            {certificado?.valido ? "Certificado Ativo" : "Certificado Expirado"}
          </h7>
          <p className="text-muted">Vencimento: {certificado?.vencimento}</p>
        </Box>
      );
    }

    return (
      <Typography variant="body2" color="text.secondary">
        Nenhum certificado cadastrado
      </Typography>
    );
  };

  const handleClearSelection = (e) => {
    if (e) e.stopPropagation();
    setArquivoSelecionado(null);
    setPassword("");
  };

  return (
    <Card sx={{ borderRadius: "18px" }}>
      <CardHeader title="Certificado Digital" />
      <CardContent>
        {renderCertificadoStatus()}
        {(!certificado || !certificado?.valido) && (
          <Box sx={{ position: "relative", width: "100%", mt: 3 }}>
            <label
              htmlFor="certificado-upload"
              {...getRootProps()}
              style={{ width: "100%" }}
            >
              <input
                {...getInputProps()}
                id="certificado-upload"
                ref={fileUploadRef}
                style={{ display: "none" }}
                onChange={handleCertificadoChange}
              />
              <Box
                sx={{
                  border: "2px dashed",
                  borderColor: isDragActive ? "primary.main" : "divider",
                  borderRadius: 1,
                  p: 4,
                  textAlign: "center",
                  cursor: "pointer",
                  backgroundColor: isDragActive
                    ? "action.hover"
                    : "background.paper",
                  ":hover": {
                    backgroundColor: "#e3f2fd",
                    border: "2px dashed grey",
                  },
                  minHeight: "120px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
                onClick={() =>
                  !arquivoSelecionado && fileUploadRef.current.click()
                }
              >
                {uploading ? (
                  <CircularProgress />
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="body1" gutterBottom>
                      {arquivoSelecionado
                        ? `Arquivo selecionado: ${arquivoSelecionado.name}`
                        : isDragActive
                        ? "Solte o certificado aqui"
                        : "Arraste e solte ou Clique para enviar um certificado"}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Formatos aceitos: .pfx ou .p12
                    </Typography>
                  </Box>
                )}
              </Box>
            </label>

            {arquivoSelecionado && (
              <IconButton
                onClick={handleClearSelection}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "error.main",
                  backgroundColor: "background.paper",
                  "&:hover": {
                    backgroundColor: "error.light",
                  },
                }}
              >
                <i className="ph-fill ph-trash" style={{ fontSize: 20 }} />
              </IconButton>
            )}
          </Box>
        )}

        {arquivoSelecionado && (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Senha do certificado"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowPassword(!showPassword);
                      }}
                      edge="end"
                    >
                      <i
                        className={
                          showPassword
                            ? "ph-fill ph-eye"
                            : "ph-fill ph-eye-slash"
                        }
                        style={{ fontSize: 22 }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={uploading || !password}
              sx={{ mt: 2 }}
            >
              {uploading ? (
                <>
                  Enviando... <CircularProgress size={24} sx={{ ml: 2 }} />
                </>
              ) : (
                "Enviar Certificado"
              )}
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CertificadoDigitalCard;
