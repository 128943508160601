import React, { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  CardContent,
  DatePicker,
  FullscreenDialog,
  Grid,
  Paper,
  Tabs,
  Tab,
  TabPanel,
  TextField,
  FormControl,
  CircularProgress,
  Button,
} from "../../../components";
import { useTheme } from "@mui/material/styles";
import { AddLayout } from "../../../layouts";
import {
  notification,
  CondicionaisService,
  CondicionaisItensService,
  PessoaService,
  ProdutosService,
  TabelaPrecoService,
  SessionService,
  ColaboradorService,
  OrigemMercadoriaService,
} from "../../../services";
import { InputErros, PermisoesHelper, value } from "../../../helpers";
import {
  responseErros,
  formatDate,
  timeoutBuscaAutocomplete,
  userLogs,
  voltar,
} from "../../../utils";
import { enderecoValidator, condicionaisValidator } from "./middlewares";
import {
  ButtonMenu,
  CondicionaisItens,
  CondicionaisCampos,
  CondicionaisDadosClientes,
  ComissaoVendedorDialog,
} from "./components";
import {
  tabProps,
  calcularValorTotal,
  totalItensLista,
  encontrarEnderecoPadrao,
  mapearEnderecos,
  mapearEndereco,
} from "./add-condicional";
import AddPessoas from "../../pessoas/addPessoa/addPessoa.view";
import { useImportContext } from "../../../contexts/import.context";
import { Condicional } from "./entities";

const sessionService = new SessionService();
const colaboradorService = new ColaboradorService();
const condicionaisService = new CondicionaisService();
const condicionaisItensService = new CondicionaisItensService();
const pessoaService = new PessoaService();
const produtosService = new ProdutosService();
const tabelaPrecoService = new TabelaPrecoService();
const origemMercadoriaService = new OrigemMercadoriaService();

let condicionalEntity = {
  dataLancamento: formatDate.toSend(new Date()),
};

const AddCondicionaisView = ({ match }) => {
  const theme = useTheme();
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const inputCliente = useRef();
  const [loading, setLoading] = useState(true);
  const [userLog, setUserLog] = useState({});
  const [isCadastroPessoaDialogOpen, setIsCadastroPessoaDialogOpen] =
    useState(false);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [abaValue, setAbaValue] = useState(0);
  const [condicional, setCondicional] = useState(condicionalEntity);
  const [itemValorComissaoVendedor, setItemValorComissaoVendedor] =
    useState(null);
  const [itemPercentualComissaoVendedor, setItemPercentualComissaoVendedor] =
    useState(null);
  const [itemTotalComissaoVendedor, setItemTotalComissaoVendedor] =
    useState(null);
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [produtoList, setProdutoList] = useState([]);
  const [produtosSimilares, setProdutosSimilares] = useState([]);
  const [cidadeId, setCidadeId] = useState(null);
  const [endereco, setEndereco] = useState({
    padrao: false,
  });
  const [enderecoCliente, setEnderecoCliente] = useState(null);
  const [enderecoClienteDataGrid, setEnderecoClienteDataGrid] = useState([]);
  const [enderecoAleterado, setEnderecoAleterado] = useState(null);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [tabelaPrecoCliente, setTabelaPrecoCliente] = useState(null);
  const [listaCondicionaisProdutos, setListaCondicionaisProdutos] = useState(
    []
  );
  const [origensMercadoriasList, setOrigensMercadoriasList] = useState([]);
  const [listaUnidadesComerciais, setListaUnidadesComerciais] = useState([]);
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("condicionais-visualizar");
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const dadosEmpresaUsuario = sessionService.getEmpresaAndUsuario();
  const containerRef = useRef(null);
  const tiposPessoa = [
    {
      tipoId: 1,
      setLista: (dados) => setClienteList(dados),
    },
    {
      tipoId: 4,
      setLista: (dados) => setVendedorList(dados),
    },
  ];

  async function buscaDadosIniciais() {
    await buscarPessoaVendedor();
    await buscaCondicionais();
    buscarOrigensMercadoria();
  }

  useEffect(() => {
    buscaDadosIniciais().then(() => {
      setLoading(false);
    });
  }, []);

  const buscarPessoaVendedor = async (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      vendedor: true,
    };
    const result = await colaboradorService.getAll(filtro);
    if (!result.isAxiosError) {
      setVendedorList(
        result.data.map((vendedor) => ({ ...vendedor.pessoa, ...vendedor }))
      );
      const colaboradorVendedorUsuario = result.data.find(
        (colaborador) =>
          colaborador.usuarioId === dadosEmpresaUsuario.usuario.id
      );
      if (colaboradorVendedorUsuario) {
        condicionalEntity.setHandleChangeInput(
          "pessoaVendedorId",
          colaboradorVendedorUsuario.pessoa.id
        );
        setCondicional(condicionalEntity);
      }
    } else {
      responseErros(result);
    }
  };

  const buscaCondicionais = async () => {
    if (!id) {
      condicionalEntity = new Condicional({
        dataLancamento: formatDate.toSend(new Date()),
      });
      return;
    }
    const result = await condicionaisService.getById(id);
    if (!result.isAxiosError) {
      if (!result.data) {
        initialCollapses();
        voltar();
        return;
      }
      condicionalEntity = new Condicional(result.data, id);
      await inserirDadosCondicional();
    } else {
      responseErros(result);
      initialCollapses();
      voltar();
    }
  };

  async function inserirDadosCondicional() {
    setCondicional(condicionalEntity);
    buscarProdutosAvancado(condicionalEntity?.itens);
    setEnderecoAleterado(condicionalEntity?.condicionalEnderecoCliente);
    buscarDadosClientes(condicionalEntity?.pessoaClienteId);
  }

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(condicional);
    setUserLog(logs);
  }

  const buscarPessoas = async (filtros, tiposPessoaId) => {
    const filtro = {
      ...filtros,
      tiposPessoaId,
      limite: 20,
      ativado: true,
    };
    const tipoPessoaTemp = tiposPessoa.find(
      ({ tipoId }) => tipoId === tiposPessoaId
    );
    pessoaService.getAll(filtro).then((res) => {
      setLoadingAutoComplete(false);
      if (!res.isAxiosError) {
        tipoPessoaTemp?.setLista(res.data.rows);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarDadosClientes = async (clienteId) => {
    if (!clienteId) return;
    pessoaService.getById(clienteId).then((result) => {
      if (!result.isAxiosError) {
        setClienteSelecionado(result.data);
        if (result.data.enderecos?.length)
          adicionarEnderecosCliente(result.data.enderecos);
      } else {
        responseErros(result);
      }
    });
  };

  async function adicionarEnderecosCliente(enderecos) {
    const enderecoPadrao = encontrarEnderecoPadrao(enderecos);
    if (enderecoPadrao) {
      const enderecoCliente = await mapearEndereco(enderecoPadrao);
      setEnderecoCliente(enderecoCliente);
      condicionalEntity.setEnderecoCliente(null, enderecoCliente);
    }
    const enderecosMapeados = await mapearEnderecos(enderecos);
    setEnderecoClienteDataGrid(enderecosMapeados);
  }

  const removerDadosCliente = () => {
    setInputErro([]);
    setEnderecoClienteDataGrid([]);
    setEnderecoCliente(null);
    setEnderecoAleterado(null);
    condicionalEntity.setEnderecoCliente();
  };

  const onChangeAutocompleteCliente = (newValue) => {
    removerDadosCliente();
    condicionalEntity.setHandleChangeAutoComplete("pessoaClienteId", newValue);
    setCondicional(condicionalEntity);
    setClienteSelecionado(newValue);
    buscarDadosClientes(newValue?.id);
    buscarTabelasPrecoCliente(newValue?.clientes[0]?.tabelaPrecoPadraoId);
  };

  const buscarOrigensMercadoria = () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriasList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarTabelasPrecoCliente = async (tabelaPrecoId) => {
    if (!tabelaPrecoId) return;
    tabelaPrecoService.getById(tabelaPrecoId).then((result) => {
      if (!result.isAxiosError) {
        setTabelaPrecoCliente(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarProdutosAvancado = (itens) => {
    const produtosIds = itens.map(({ produtoId }) => produtoId);
    const filtro = {
      id: produtosIds,
      ativado: true,
      nonPaginated: true,
    };
    produtosService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setProdutoList(result.data);
        if (itens && itens.length) {
          preencherItens(itens, result.data);
        }
      } else {
        responseErros(result);
      }
    });
  };

  const preencherItens = (itensBusca, produtos) => {
    const itens = itensBusca.map((item) => {
      const produtoBusca = produtos.find(
        (produto) => produto.id == item.produtoId
      );
      return {
        ...item,
        descricao: produtoBusca?.descricao,
        totalItem: calcularValorTotal(item),
      };
    });
    condicionalEntity.setChangeItens(itens);
    setCondicional(condicionalEntity);
    setListaCondicionaisProdutos(itens);
  };

  const buscarProdutosSimilares = async (produtoId) => {
    if (produtoId) {
      produtosService.getById(produtoId).then((result) => {
        if (!result.isAxiosError) {
          setProdutosSimilares(result.data.similares);
        } else {
          responseErros(result);
        }
      });
    }
  };

  const handleChangeAutocomplete = (name, value) => {
    condicionalEntity.setHandleChangeAutoComplete(name, value);
    setCondicional(condicionalEntity);
  };

  const handleChangeInput = (event) => {
    condicionalEntity.setHandleChangeInput(
      event.target.name,
      event.target.value
    );
    setCondicional(condicionalEntity);
  };

  const handleDateChange = (fieldName, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    condicionalEntity.setHandleChangeDate(fieldName, date);
    setCondicional(condicionalEntity);
  };

  const onSearchChangePessoas = (event, tiposPessoaId) => {
    if (
      event.key === "Enter" &&
      inputCliente.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      let filtros = {
        nomeRazaoSocial: eventValue,
        apelidoFantasia: eventValue,
      };
      if (tiposPessoaId === 4) {
        filtros = { ...filtros, vendedor: "true" };
      }
      setLoadingAutoComplete(true);
      buscarPessoas(filtros, tiposPessoaId);
    }
  };

  const handleCloseCadastroCliente = () => {
    buscarPessoas();
    condicionalEntity.setHandleChangeInput("pessoaClienteId", null);
    setCondicional(condicionalEntity);
    setIsCadastroPessoaDialogOpen(false);
  };

  const buscarProdutosCadastrados = () => {
    const filter = {
      nonPaginated: true,
      condicionalId: id,
    };
    condicionaisItensService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        preencherItens(res.data, produtoList);
      } else {
        responseErros(res);
      }
    });
  };

  const removerProdutoCadastrado = async (idProduto) => {
    setLoading(true);
    const res = await condicionaisItensService.deletar(idProduto);
    if (!res.isAxiosError) {
      buscarProdutosCadastrados();
      notification.deletadoSucesso();
    } else {
      responseErros(res);
    }
    setLoading(false);
  };

  const salvarEndereco = async () => {
    const body = {
      ...endereco,
      cidadeId: cidadeId,
      pessoaId: condicional?.pessoaClienteId,
    };
    enderecoValidator
      .validate(body, { abortEarly: false })
      .then(async () => {
        setLoading(true);
        const res = await pessoaService.cadastrarEndereco(
          condicional?.pessoaClienteId,
          body
        );
        if (!res.isAxiosError) {
          notification.cadastroSucesso();
          setEnderecoClienteDataGrid([...enderecoClienteDataGrid, body]);
        } else {
          responseErros(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    condicionaisValidator
      .validate(condicionalEntity, { abortEarly: false })
      .then(async () => {
        if (id) {
          const response = await condicionaisService.atualizar(
            id,
            condicionalEntity
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            initialCollapses();
            voltar();
          } else {
            buscaCondicionais();
            responseErros(response);
          }
        } else {
          const response = await condicionaisService.cadastrar(
            condicionalEntity
          );
          setLoading(false);
          if (!response.isAxiosError) {
            setCondicional({ ...condicional, ...response.data });
            notification.cadastroSucesso();
            if (indiceSelecionado === 0) {
              const confirmacaoAlerta = await notification.confirmacaoGenericos(
                {
                  title: "Condicional cadastrada com sucesso",
                  text: `#${response.data.codigo}`,
                  icon: "success",
                  confirmButtonText: "IMPRIMIR",
                  cancelButtonText: "FECHAR",
                  dangerMode: true,
                  showCancelButton: true,
                }
              );
              if (confirmacaoAlerta.isConfirmed) {
                document.getElementById("botaoMenuAction")?.click();
              } else {
                initialCollapses();
                voltar();
              }
            } else {
              handleCancelar();
            }
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const handleCancelar = () => {
    condicionalEntity = new Condicional({
      dataLancamento: formatDate.toSend(new Date()),
    });
    setCondicional(condicionalEntity);
    setListaCondicionaisProdutos([]);
  };

  return (
    <AddLayout
      id={id}
      showUpdate={
        condicional.status === "ABERTA" ||
        permissoesHelper.temPermisao("condicionais-editar")
      }
      title={condicional?.id ? "Editar condicionais" : "Cadastrar condicionais"}
      onClickSalvar={handleSubmit}
      loading={loading}
      codigo={
        id && condicional?.numeroDocumento
          ? `Nº Documento ${condicional?.numeroDocumento}`
          : ""
      }
      userLog={id ? userLog : null}
      onClickLog={onClickLog}
      ButtonMenu={
        <ButtonMenu
          id={id}
          data={{
            ...condicional,
            ...dadosEmpresaUsuario.empresa,
            itens: listaCondicionaisProdutos,
            vendedor: value.autoComplete(
              vendedorList,
              condicional?.pessoaVendedorId
            )?.nomeRazaoSocial,
            cliente: clienteSelecionado,
            enderecoCliente: enderecoAleterado || enderecoCliente,
            estadoCliente: enderecoCliente?.estado,
            totalCondicional: totalItensLista(listaCondicionaisProdutos),
          }}
        />
      }
      // formState={{
      //   inicial: id
      //     ? condicionalEntity
      //     : {
      //         dataLancamento: formatDate.toSend(new Date()),
      //       },
      //   final: condicional,
      // }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <TextField
            id="codigo"
            name="codigo"
            label="Código"
            variant="filled"
            disabled
            value={condicional.codigo ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            id="dataLancamento"
            name="dataLancamento"
            label="Data de Lançamento"
            format="dd/MM/yyyy"
            variant="filled"
            disabled={true}
            value={value.date(condicional?.dataLancamento)}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            id="dataVencimento"
            name="dataVencimento"
            label="Data de Vencimento"
            format="dd/MM/yyyy"
            onChange={(date) => {
              setInputErro([]);
              handleDateChange("dataVencimento", date);
            }}
            required
            error={inputErros.get("dataVencimento")}
            value={value.date(condicional?.dataVencimento)}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="pessoaClienteId"
              name="pessoaClienteId"
              disabled={id && condicional.status !== "ABERTO"}
              options={clienteList}
              autoHighlight
              getOptionLabel={(option) => {
                let descricaoProduto = `${option?.codigo} - ${option?.nomeRazaoSocial}`;
                return option ? descricaoProduto : "";
              }}
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText={
                document.getElementById("pessoaClienteId")?.value.length >=
                1 ? (
                  <Grid container spacing={1}>
                    <Grid item xs={7} className="mt-2" fullWidth>
                      Nenhum cliente encontrado
                    </Grid>
                    <Grid item xs={5} className="d-flex justify-content-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setIsCadastroPessoaDialogOpen(true)}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  "Digite e pressione Enter"
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  variant="outlined"
                  required
                  onKeyDown={(e) => onSearchChangePessoas(e, 1)}
                  inputRef={inputCliente}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <React.Fragment>
                          {loadingAutoComplete ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      </>
                    ),
                  }}
                />
              )}
              onChange={(e, newValue) => onChangeAutocompleteCliente(newValue)}
              value={clienteSelecionado}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="pessoaVendedorId"
              name="pessoaVendedorId"
              disabled={id && condicional.status !== "ABERTA"}
              options={vendedorList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendedor"
                  variant="outlined"
                  error={inputErros.get("pessoaVendedorId")}
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                setInputErro([]);
                handleChangeAutocomplete("pessoaVendedorId", newValue);
              }}
              value={value.autoComplete(
                vendedorList,
                condicional?.pessoaVendedorId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <hr className="mt-4 mb-2" />
      <Paper>
        <Tabs
          value={abaValue}
          onChange={(e, value) => {
            e.persist();
            setAbaValue(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="ITENS DA NOTA" {...tabProps(0)} />
          <Tab
            label="DADOS DO CLIENTE"
            {...tabProps(1)}
            disabled={!clienteSelecionado}
          />
          <Tab label="DETALHES" {...tabProps(2)} />
        </Tabs>
      </Paper>
      <CardContent ref={containerRef}>
        <TabPanel value={abaValue} index={0} dir={theme.direction}>
          <CondicionaisItens
            id={id}
            condicional={condicional}
            setCondicional={setCondicional}
            loadingAutoComplete={loadingAutoComplete}
            timeoutBuscaAutocomplete={timeoutBuscaAutocomplete}
            setLoadingAutoComplete={setLoadingAutoComplete}
            produtoList={produtoList}
            setProdutoList={setProdutoList}
            buscarProdutosSimilares={buscarProdutosSimilares}
            produtosSimilares={produtosSimilares}
            setProdutosSimilares={setProdutosSimilares}
            listaCondicionaisProdutos={listaCondicionaisProdutos}
            setListaCondicionaisProdutos={setListaCondicionaisProdutos}
            tabelaPrecoCliente={tabelaPrecoCliente}
            removerProdutoCadastrado={removerProdutoCadastrado}
            origensMercadoriasList={origensMercadoriasList}
            listaUnidadesComerciais={listaUnidadesComerciais}
            setListaUnidadesComerciais={setListaUnidadesComerciais}
            condicionalEntity={condicionalEntity}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={1} dir={theme.direction}>
          {abaValue == 1 ? (
            <CondicionaisDadosClientes
              condicional={condicional}
              setCondicional={setCondicional}
              enderecoAleterado={enderecoAleterado}
              setEnderecoAleterado={setEnderecoAleterado}
              clienteList={clienteList}
              enderecoCliente={enderecoCliente}
              buscarDadosClientes={buscarDadosClientes}
              enderecoClienteDataGrid={enderecoClienteDataGrid}
              clienteSelecionado={clienteSelecionado}
              cidadeId={cidadeId}
              setCidadeId={setCidadeId}
              endereco={endereco}
              setEndereco={setEndereco}
              salvarEndereco={salvarEndereco}
              inputErros={inputErros}
              condicionalEntity={condicionalEntity}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={abaValue} index={2} dir={theme.direction}>
          <CondicionaisCampos
            condicional={condicional}
            handleChangeInput={handleChangeInput}
          />
        </TabPanel>
        <FullscreenDialog
          open={isCadastroPessoaDialogOpen}
          handleClose={handleCloseCadastroCliente}
          content={
            <AddPessoas
              match={{ params: { id: null } }}
              isFullscreenDialog
              handleCloseFullscreenDialog={handleCloseCadastroCliente}
            />
          }
        />
      </CardContent>
      <ComissaoVendedorDialog
        itemValorComissaoVendedor={itemValorComissaoVendedor}
        setItemValorComissaoVendedor={setItemValorComissaoVendedor}
        itemPercentualComissaoVendedor={itemPercentualComissaoVendedor}
        setItemPercentualComissaoVendedor={setItemPercentualComissaoVendedor}
        itemTotalComissaoVendedor={itemTotalComissaoVendedor}
        setItemTotalComissaoVendedor={setItemTotalComissaoVendedor}
        listaCondicionaisProdutos={listaCondicionaisProdutos}
      />
    </AddLayout>
  );
};

export default AddCondicionaisView;
