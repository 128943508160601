import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  Autocomplete,
  CardHeader,
  Checkbox,
  Card,
} from "../../../../components";
import { constantes } from "../resources";
import { value } from "../../../../helpers";
import { CardContent } from "@mui/material";
import CertificadoDigitalCard from "./certificado-digital.component";

const CadastroNfNfc = ({
  empresa,
  setEmpresa,
  handleInputChange,
  handleAutocompleteInput,
}) => {
  return (
    <>
      <Grid container spacing={2} className="mb-2">
        <Grid item xs={3}>
          <Card sx={{ borderRadius: "18px", height: "100%" }}>
            <CardHeader title="tipo ambiente" />
            <CardContent className="d-flex flex-column align-items-center gap-2">
              <FormControlLabel
                sx={{ width: "130px" }}
                control={
                  <Switch
                    checked={empresa.producao ?? false}
                    onChange={() =>
                      setEmpresa((prevState) => ({
                        ...prevState,
                        producao: !empresa?.producao,
                      }))
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    id="producao"
                    name="producao"
                    color="primary"
                  />
                }
                label={empresa?.producao ? "Produção" : "Homologação"}
              />
              <FormControlLabel
                sx={{ width: "130px" }}
                control={
                  <Checkbox
                    checked={empresa.emiteNfe ?? false}
                    onChange={() =>
                      setEmpresa((prevState) => ({
                        ...prevState,
                        emiteNfe: !empresa?.emiteNfe,
                      }))
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    id="emiteNfe"
                    name="emiteNfe"
                    color="primary"
                  />
                }
                label="Emite NF-e"
              />
              <FormControlLabel
                sx={{ width: "130px" }}
                control={
                  <Checkbox
                    checked={empresa.emiteNfce ?? false}
                    onChange={() =>
                      setEmpresa((prevState) => ({
                        ...prevState,
                        emiteNfce: !empresa?.emiteNfce,
                      }))
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    id="emiteNfce"
                    name="emiteNfce"
                    color="primary"
                  />
                }
                label="Emite NFC-e"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={9}>
          <CertificadoDigitalCard />
        </Grid>
      </Grid>
      {empresa?.emiteNfce && (
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                required
                id="tipoEmissaoNfce"
                name="tipoEmissaoNfce"
                options={constantes.tipoEmissaoNfceList}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => (option.nome ? option.nome : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo de emissão NFC-e"
                    variant="outlined"
                  />
                )}
                onChange={(_event, newValue) =>
                  handleAutocompleteInput("tipoEmissaoNfce", newValue)
                }
                value={value.autoComplete(
                  constantes.tipoEmissaoNfceList,
                  empresa.tipoEmissaoNfce
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="idCodigoSegurancaContribuinte"
              name="idCodigoSegurancaContribuinte"
              label="Token (CSC)"
              variant="outlined"
              margin="normal"
              value={
                empresa?.idCodigoSegurancaContribuinte
                  ? empresa.idCodigoSegurancaContribuinte
                  : ""
              }
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="codigoSegurancaContribuinte"
              name="codigoSegurancaContribuinte"
              label="Código de Segurança (CSC)"
              variant="outlined"
              margin="normal"
              value={
                empresa?.codigoSegurancaContribuinte
                  ? empresa.codigoSegurancaContribuinte
                  : ""
              }
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        {empresa?.emiteNfe && (
          <Grid item xs={5}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                required
                id="tipoEmissaoNfe"
                name="tipoEmissaoNfe"
                options={constantes.tipoEmissaoList}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => (option.nome ? option.nome : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo de emissão NF-e"
                    variant="outlined"
                  />
                )}
                onChange={(_event, newValue) =>
                  handleAutocompleteInput("tipoEmissaoNfe", newValue)
                }
                value={value.autoComplete(
                  constantes.tipoEmissaoList,
                  empresa.tipoEmissaoNfe
                )}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={empresa?.emiteNfe ? 7 : 12}>
          <TextField
            id="informacoes"
            name="informacoes"
            label="Informações Complementares"
            variant="outlined"
            margin="normal"
            value={empresa?.informacoes ? empresa.informacoes : ""}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CadastroNfNfc;
