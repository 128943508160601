import shortid from "shortid";
import { formatDate } from "../../../../utils";
import { calculoDesconto, totalItensLista } from "../add-condicional";

export class Condicional {
  codigo = null;
  dataLancamento = null;
  dataVencimento = null;
  pessoaVendedorId = null;
  pessoaClienteId = null;
  informacaoAdicional = null;
  itens = null;
  condicionalEnderecoCliente = null;
  totalCondicional = 0;

  constructor(condicional, id) {
    if (id) {
      this.codigo = condicional.codigo;
      this.pessoaVendedorId = condicional.pessoaVendedorId;
      this.pessoaClienteId = condicional.pessoaClienteId;
      this.condicionalEnderecoCliente = condicional.condicionalEnderecoCliente;
      this.informacaoAdicional = condicional.informacaoAdicional;
      this.itens = condicional.itens?.length
        ? condicional.itens.map((item) => new CondicionalItens(item))
        : [];
    }
    this.dataLancamento = condicional.dataLancamento;
    this.dataVencimento = condicional.dataVencimento;
  }

  setHandleChangeInput(name, value) {
    this[name] = value;
  }

  setHandleChangeDate(name, date) {
    this[name] = date != "Invalid Date" ? formatDate.toSend(date) : undefined;
  }

  setHandleChangeAutoComplete(name, value) {
    this[name] = value ? value.id : null;
  }

  setChangeItens(produtos) {
    this.itens = produtos?.length
      ? produtos.map((produto) => new CondicionalItens(produto))
      : [];
    this.descontoPorcentagem = calculoDesconto(produtos, this, false);
    this.totalCondicional = totalItensLista(produtos);
  }

  setEnderecoCliente(enderecoAlterado, enderecoCliente) {
    if (this.pessoaClienteId) {
      const endereco = enderecoAlterado || enderecoCliente;
      if (endereco) {
        this.pedidoEnderecoCliente = {
          ...endereco,
          pessoaId: this.pessoaClienteId,
        };
        return;
      }
    }
    this.pedidoEnderecoCliente = null;
  }
}

class CondicionalItens {
  produtoId = null;
  unidade = null;
  quantidade = null;
  codigo = null;
  valorUnitario = null;
  ncm = null;
  tributosIcmsOrigem = null;

  constructor(itens) {
    this.id = itens.id ? itens.id : shortid.generate();
    this.produtoId = itens.produtoId;
    this.unidade = itens.unidade;
    this.quantidade = itens.quantidade;
    this.codigo = itens.codigo;
    this.valorUnitario = itens.valorUnitario;
    this.ncm = itens.ncm;
    this.tributosIcmsOrigem = itens.tributosIcmsOrigem;
  }
}
