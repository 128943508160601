import { useEffect } from "react";
import { NfSaidaService } from "../../../../services";
import { formatPrecisaoFloat, responseErros } from "../../../../utils";
import { DashboardCard } from "../../../main/components";

function RelatorioDashboardQtdVendas({
  filtrosAvancados,
  relatoriosDashborad,
  setRelatoriosDashborad,
}) {
  const nfSaidaService = new NfSaidaService();

  useEffect(() => {
    dashboardQtdVendas({
      ...filtrosAvancados,
      porIntervalo: [
        {
          ...filtrosAvancados.porIntervalo[0],
          coluna: "dataLancamento",
        },
      ],
    });
  }, [
    filtrosAvancados.porIntervalo[0].de,
    filtrosAvancados.porIntervalo[0].ate,
  ]);

  async function dashboardQtdVendas(body) {
    const result = await nfSaidaService.getRelatorioDashboardQtdVendas(body);
    if (!result.isAxiosError) {
      setRelatoriosDashborad((prevValue) => ({
        ...prevValue,
        qtdVendas: result.data || 0,
      }));
    } else {
      responseErros(result);
    }
  }

  return (
    <DashboardCard
      title="Quantidade de Vendas"
      ActionIcon={
        <i
          className="ph-fill ph-list-numbers"
          style={{ color: "#494C62", fontSize: 22 }}
        ></i>
      }
    >
      <b className="fs-5">
        {formatPrecisaoFloat(relatoriosDashborad.qtdVendas, 2)}
      </b>
    </DashboardCard>
  );
}

export default RelatorioDashboardQtdVendas;
