import {
  DatePicker,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "../../../../components";
import { value } from "../../../../helpers";
import { useFiltrosRelatoriosHandlers } from "../../components/use-filtros-relatorios-handlers";

const FormFiltroAvancadoContador = ({
  filtrosAvancados,
  setFiltrosAvancados,
  setFiltrosAplicadosImpressao,
}) => {
  const { onChangeInput, onChangeDate } =
    useFiltrosRelatoriosHandlers(setFiltrosAvancados, setFiltrosAplicadosImpressao);

  const listaStatusNf = [
    {
      label: "Pendente",
      value: "PENDENTE",
      cor: "#EA9D2B",
    },
    {
      label: "Faturada",
      value: "FATURADA",
      cor: "#004780",
    },
    {
      label: "Enviada",
      value: "ENVIADA",
      cor: "#4FBB53",
    },
    {
      label: "Em Contingência",
      value: "CONTINGENCIA",
      cor: "#BFBFBF",
    },
    {
      label: "Cancelada",
      value: "CANCELADA",
      cor: "#DC3545",
    },
    {
      label: "Denegada",
      value: "DENEGADA",
      cor: "#70309F",
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="numeroDocumento"
            name="Número-Documento"
            label="Nº Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={(e) => onChangeInput(e, "Número-Documento")}
            value={value.text(filtrosAvancados?.numeroDocumento)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="serieDocumento"
            name="Série-Documento"
            label="Série Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={(e) => onChangeInput(e, "Série-Documento")}
            value={value.text(filtrosAvancados?.serieDocumento)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoStart"
            name="Data-Lançamento-De"
            label="Data Lançamento De"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataLancamentoStart)}
            onChange={(date) =>
              onChangeDate("dataLancamentoStart", date, "Data-Lançamento-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoEnd"
            name="Data-Lançamento-Até"
            label="Data Lançamento Até"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataLancamentoEnd)}
            onChange={(date) =>
              onChangeDate("dataLancamentoEnd", date, "Data-Lançamento-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Select
            label="Modelo Doc."
            id="modeloDocumento"
            name="modeloDocumento"
            margin="normal"
            required
            onChange={(event) => onChangeInput(event, "Modelo Documento")}
            value={value.text(filtrosAvancados?.modeloDocumento)}
          >
            <MenuItem value={"55"}>55 - (NF-e) Nota Fiscal Eletrônica</MenuItem>
            <MenuItem value={"65"}>
              65 - (NFC-e) Nota Fiscal Eletrônica ao Consumidor
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select
            id="status"
            name="status"
            label="Status"
            margin="normal"
            fullWidth
            onChange={(event) => onChangeInput(event, "Status")}
            value={value.text(filtrosAvancados?.status)}
          >
            {listaStatusNf.map((status, index) => (
              <MenuItem key={index} value={status.value}>
                <i
                  className="ph-fill ph-circle"
                  style={{ color: `${status.cor}` }}
                ></i>
                &nbsp;{status.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoContador;
