import { useEffect, useState } from "react";
import { Grid, TextField, Select, MenuItem } from "../../../../components";
import { EtiquetasService, TiposPessoasService } from "../../../../services";
import { value } from "../../../../helpers";
import { responseErros } from "../../../../utils";
import { Autocomplete } from "@mui/material";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  valueAutocomplete,
  onChangeAutocomplete,
  onChangeMultiple,
}) => {
  const tiposPessoasService = new TiposPessoasService();
  const etiquetasService = new EtiquetasService();
  const [listaTiposPessoa, setListaTiposPessoa] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);

  useEffect(() => {
    buscaTipoPessoa();
    buscarEtiquetas();
  }, []);

  const buscaTipoPessoa = async () => {
    const res = await tiposPessoasService.getAll();
    if (!res.isAxiosError) {
      const filterCredenciadora = res.data.filter(
        (objeto) => objeto.descricao !== "Credenciadora"
      );
      setListaTiposPessoa(filterCredenciadora);
    }
  };

  const buscarEtiquetas = async function () {
    const filtros = {
      nonPaginated: true,
      ativado: true,
      tipo: "PESSOA",
    };
    const result = await etiquetasService.getAll(filtros);
    if (!result.isAxiosError) {
      setEtiquetas(result.data);
    } else {
      responseErros(result);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          id="ativado"
          name="ativado"
          value={valueInput("ativado")}
          label="Status"
          onChange={(event) => onChangeInput(event)}
          fullWidth
        >
          <MenuItem value={"true"}>Ativado</MenuItem>
          <MenuItem value={"false"}>Desativado</MenuItem>
          <MenuItem value={"-"}>Ambos</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="nomeRazaoSocial"
          name="nomeRazaoSocial"
          label="Nome / Razão Social"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("nomeRazaoSocial")}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          id="naturezaSocial"
          name="naturezaSocial"
          value={valueInput("naturezaSocial")}
          label="Natureza Social"
          onChange={(event) => onChangeInput(event)}
          fullWidth
        >
          <MenuItem value={"fisica"}>Física</MenuItem>
          <MenuItem value={"juridica"}>Jurídica</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="cnpjCpf"
          name="cnpjCpf"
          label="CNPJ / CPF"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("cnpjCpf")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="email"
          name="email"
          label="E-mail"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("email")}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="tiposPessoaId"
          name="tiposPessoaId"
          options={listaTiposPessoa}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) =>
            option.descricao ? option.descricao : ""
          }
          renderInput={(params) => (
            <TextField {...params} label="Tipos de Pessoa" variant="outlined" />
          )}
          onChange={(e, value) => onChangeAutocomplete("tiposPessoaId", value)}
          value={valueAutocomplete(listaTiposPessoa, "tiposPessoaId")}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="etiquetasId"
          name="etiquetasId"
          options={etiquetas}
          noOptionsText="Sem opções"
          autoHighlight
          filterSelectedOptions
          getOptionLabel={(option) => {
            return (
              <div>
                <i
                  className="ph-fill ph-bookmark-simple"
                  style={{ color: `${option?.cor}`, marginRight: "10px" }}
                ></i>
                {option?.descricao}
              </div>
            );
          }}
          onChange={(_, newValue) =>
            onChangeMultiple(newValue, "etiquetasId", etiquetas, setEtiquetas)
          }
          value={value.multiple(etiquetas, valueInput("etiquetasId"))}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              required
              margin="normal"
              label="Etiquetas"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
