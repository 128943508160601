import { format } from "date-fns";
import { formatDate, formatNomes, formatPrice } from "../../../../../../utils";

export default function HeaderRelatorioNfSaida(nfSaida) {
  const columns = [
    {
      header: "Doc.",
      field: "serieDocumento",
      width: "80px",
      render: (value) => `${value.serieDocumento}/${value.numeroDocumento}`,
    },
    {
      header: "Data Lanc.",
      field: "dataLancamento",
      render: ({ dataLancamento }) =>
        format(formatDate.received(dataLancamento), "dd/MM/yyyy"),
    },
    {
      header: "Cliente",
      field: "cliente",
      width: "140px",
      render: ({ cliente }) => cliente && formatNomes(cliente.substring(0, 30)),
    },
    {
      header: "Vendedor",
      field: "vendedor",
      width: "110px",
      render: ({ vendedor }) =>
        vendedor && formatNomes(vendedor.substring(0, 20)),
    },
    {
      header: "Valor Merc.",
      field: "valorMercadoria",
      fieldTotal: "totalValorMercadoria",
      render: ({ valorMercadoria }) => formatPrice(valorMercadoria, false),
    },
    {
      header: "Acrésc.",
      field: "acrescimos",
      fieldTotal: "totalAcrescimos",
      width: "85px",
      align: "center",
      render: ({ acrescimos }) => formatPrice(acrescimos, false),
    },
    {
      header: "Despe.",
      field: "despesas",
      fieldTotal: "totalDespesas",
      width: "85px",
      align: "center",
      render: ({ despesas }) => formatPrice(despesas, false),
    },
    {
      header: "Desc.",
      field: "descontos",
      fieldTotal: "totalDescontos",
      width: "85px",
      align: "center",
      render: ({ descontos }) => formatPrice(descontos, false),
    },
    {
      header: "Total",
      field: "valorTotal",
      fieldTotal: "total",
      align: "end",
      render: ({ valorTotal }) => formatPrice(valorTotal, false),
    },
  ];

  return (
    <table id="table-relatorio" className="table table-borderless mb-2">
      <thead className="table-primary">
        <tr>
          {columns.map((column, index) => (
            <th
              key={index}
              style={{
                textAlign: column?.align || "start",
                width: column?.width,
              }}
            >
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {columns.map((column, columnIndex) => (
            <td key={columnIndex}>
              <div
                style={{
                  textAlign: column?.align || "start",
                }}
                className={
                  columnIndex + 1 === columns.length || column.end
                    ? "cell-content-end"
                    : "cell-content"
                }
              >
                {column.render ? column.render(nfSaida) : nfSaida[column.field]}
              </div>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
