import { useState, useEffect, useRef } from "react";
import {
  Grid,
  FormControl,
  TextField,
  Button,
  Tooltip,
  Autocomplete,
  DataGrid,
  CurrencyInput,
} from "../../../../../components";
import { ProdutoSimilarDialog, ImpostoProdutoDialog } from "..";
import {
  notification,
  ProdutosService,
  UnidadeComercialService,
  CsoSimplesService,
  GruposTributarioService,
  ModeloTributacaoService,
  EnquadramentoLegalService,
  GruposTributarioModelosTributacaoService,
} from "../../../../../services";
import { value } from "../../../../../helpers";
import {
  responseErros,
  formatPrice,
  unformatPrice,
  timeoutBuscaAutocomplete,
} from "../../../../../utils";
import { NfSaidaItem, NfSaidaItemImpostos } from "../../../entities";
import { calcularPrecoProduto } from "../../add-nf-saida";
import shortid from "shortid";

const produtoService = new ProdutosService();
const unidadeComercialService = new UnidadeComercialService();
const gruposTributarioService = new GruposTributarioService();
const csoSimplesService = new CsoSimplesService();
const modeloTributacaoService = new ModeloTributacaoService();
const enquadramentoLegalService = new EnquadramentoLegalService();
const gruposTributarioModelosTributacaoService =
  new GruposTributarioModelosTributacaoService();

const NfSaidaItens = ({
  nfSaidaId,
  nfSaida,
  setNfSaida,
  nfSaidaEntity,
  nfSaidaProdutos,
  tabelaPrecoCliente,
  produtosSimilares,
  buscarProdutosSimilares,
  setNfSaidaProdutos,
  setNfSaidaPagamentos,
  setLoading,
  permitirVendaEstoqueNegativo,
  situacaoIpiList,
  origensMercadoriasList,
  operacaoFiscal,
  unidadeList,
  setUnidadeList,
  gruposTributacaoList,
  setGruposTributacaoList,
  csoSimplesList,
  setCsoSimplesList,
  enquadramentoLegalList,
  setEnquadramentoLegalList,
  permissaoEditarPrecoVenda,
}) => {
  const inputRef = useRef();
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [item, setItem] = useState(null);
  const [produto, setProduto] = useState(null);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [modeloTributacao, setModeloTributacao] = useState({});
  const [openProdutoSimilarDialog, setOpenProdutoSimilarDialog] =
    useState(false);
  const [openImpostoProdutoDialog, setImpostoProdutoDialog] = useState(false);
  const [isProdutoEdit, setIsProdutoEdit] = useState(false);

  const produtosColunasSimilares = [
    {
      field: "codigo",
      headerName: "Cod.",
      flex: 280,
      valueGetter: (params) => formatPrice(params.row.produto.codigo),
    },
    {
      field: "descricao",
      headerName: "Produtos",
      flex: 700,
      valueGetter: (params) => formatPrice(params.row.produto.descricao),
    },
    {
      field: "estoqueAtual",
      headerName: "Estoque",
      flex: 350,
      valueGetter: (params) => params.row.produto.estoqueAtual,
    },
    {
      field: "precoCompra",
      headerName: "Preço Compra",
      flex: 500,
      valueGetter: (params) => formatPrice(params.row.produto.precoCompra),
    },
    {
      field: "precoVenda",
      headerName: "Preço Venda",
      flex: 500,
      valueGetter: (params) => formatPrice(params.row.produto.precoVenda),
    },
    {
      field: "acoes",
      sortable: false,
      disableClickEventBubbling: true,
      renderHeader: () => {
        return (
          <i
            className="ph-fill ph-note-pencil"
            style={{ fontSize: 20, marginLeft: 14 }}
          ></i>
        );
      },
      renderCell: (params) => {
        return (
          <i
            className="ph-fill ph-paper-plane-right"
            style={{ fontSize: 20, marginLeft: 14 }}
            onClick={() => {
              setOpenProdutoSimilarDialog(false);
              const filtros = {
                id: params.row.similarId,
              };
              buscarProdutos(filtros, true);
            }}
          ></i>
        );
      },
    },
  ];

  const produtosColunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 120,
    },
    {
      field: "descricao",
      headerName: "Produto",
      flex: 700,
    },
    {
      field: "quantidade",
      headerName: "Qtd.",
      flex: 200,
    },
    {
      field: "valor",
      headerName: "Valor (R$)",
      flex: 200,
      valueGetter: (params) => formatPrice(parseFloat(params.value)),
    },
    {
      field: "subtotal",
      headerName: "Subtotal (R$)",
      flex: 200,
      valueGetter: (params) => formatPrice(parseFloat(params.value)),
    },
  ];

  useEffect(() => {
    buscarUnidadesComerciais();
    buscarGruposTributario();
    buscaCsoSimples();
    buscaEnquadramentoLegal();
  }, []);

  const buscarProdutos = (filtros, isProdutoSimilar, isCodBar = false) => {
    const filtro = {
      ...filtros,
      orderBy: "codigo",
      order: "asc",
      ativado: true,
      limite: 20,
    };
    produtoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setLoadingAutoComplete(false);
        if (isProdutoSimilar) {
          setProduto(result.data.rows[0]);
          setItem({
            ...item,
            valor: result.data.rows[0].precoVenda,
          });
        }
        setListaProdutos(result.data.rows);
        if (result.data.rows?.length === 1 && isCodBar) {
          handleChangeAutocompleteProduto(result.data.rows[0], true);
        }
      } else {
        setLoadingAutoComplete(false);
        responseErros(result);
      }
    });
  };

  const buscaEnquadramentoLegal = () => {
    if (enquadramentoLegalList?.length) return;
    enquadramentoLegalService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setEnquadramentoLegalList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaCsoSimples = () => {
    if (csoSimplesList?.length) return;
    csoSimplesService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setCsoSimplesList(result.data);
      }
    });
  };

  const buscarGruposTributario = () => {
    if (gruposTributacaoList?.length) return;
    const filter = {
      ativado: true,
      nonPaginated: true,
    };
    gruposTributarioService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        setGruposTributacaoList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarModeloTributacao = async (id) => {
    const result = await modeloTributacaoService.getById(id);
    if (!result.isAxiosError) {
      setModeloTributacao(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscarUnidadesComerciais = async () => {
    if (unidadeList?.length) return;
    const filtros = {
      nonPaginated: true,
    };
    unidadeComercialService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setUnidadeList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const handleInputChange = (e) => {
    setItem((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputBlur = (e) => {
    setItem((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  const onInputChangeProdutos = (event) => {
    const eventValue = event.target?.value.includes(",")
      ? event.target?.value.split(",")[0]
      : event.target?.value;
    if (
      event.key === "Backspace" &&
      inputRef.current === document.activeElement &&
      eventValue === ""
    ) {
      setListaProdutos([]);
    }
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const filtros = {
        codigo: eventValue,
        descricao: eventValue,
      };
      let isCodeBar = false;
      if (
        Number.isFinite(parseFloat(eventValue)) &&
        eventValue?.length >= 8 &&
        eventValue?.length <= 13
      ) {
        filtros.codigoBarra = eventValue;
        isCodeBar = true;
      }
      setLoadingAutoComplete(true);
      timeoutBuscaAutocomplete(buscarProdutos(filtros, false, isCodeBar));
    }
  };

  function verificaEstoque(produto) {
    if (!nfSaidaEntity?.operacaoEntrada && !permitirVendaEstoqueNegativo) {
      const {
        estoqueProduto: { estoque },
        estoqueMinimo,
      } = produto;

      if (!estoque || estoque < 0) {
        notification.alertaGenericos("Produto fora de estoque");
        setProduto(null);
        return true;
      }

      if (estoque <= estoqueMinimo) {
        notification.alertaGenericos("Produto no limite de estoque minimo");
        return false;
      }
    }
    return false;
  }

  const handleChangeAutocompleteProduto = (produto, isBuscaCodBar) => {
    document.getElementById("produtoId").blur();
    if (produto) {
      const estoque = verificaEstoque(produto);
      if (estoque) return;
      const itemTemp = {
        ...item,
        ...produto,
        id: shortid.generate(),
        produtoId: produto.id,
        valor: calcularPrecoProduto(
          produto,
          produto?.precoVenda,
          tabelaPrecoCliente,
          nfSaida?.condicaoPagamentoId
        ),
        estoqueAtual: produto?.estoqueProduto?.estoque
          ? `${produto.estoqueProduto.estoque}`
          : "0",
        unidade: unidadeList.find(
          (item) => item.id == produto?.unidadeComercialVendaId
        )?.codigo,
        tributosIcmsOrigem: origensMercadoriasList.find(
          (item) => item.id == produto.origemMercadoriaId
        )?.codigo,
        quantidade: 1,
        codigo: produto?.codigo,
        ncm: produto?.ncmCodigo,
      };
      setProduto(produto);
      setItem(itemTemp);
      /* implementar regras produtos similares
      buscarProdutosSimilares(value.id);*/
      if (!isBuscaCodBar) {
        document.getElementById("buttonAdicionar").focus();
      } else {
        handleAdicionarProduto(produto, itemTemp);
      }
    } else {
      setProduto(null);
      setItem(null);
    }
  };

  useEffect(() => {
    if (nfSaidaProdutos.length > 0 && produto) {
      const precoProduto = calcularPrecoProduto(
        produto,
        produto.valor,
        tabelaPrecoCliente,
        nfSaida?.condicaoPagamentoId
      );
      const produtosAtualizados = nfSaidaProdutos.map((produto) => ({
        ...produto,
        valor: precoProduto,
        subtotal: precoProduto * produto.quantidade,
      }));
      setNfSaidaProdutos(produtosAtualizados);
      nfSaidaEntity.setChangeItens(nfSaidaId, produtosAtualizados);
      setNfSaida(nfSaidaEntity);
    }
  }, [tabelaPrecoCliente]);

  const validarAddProduto = (produto, item) => {
    if (!produto) {
      notification.alertaGenericos("Selecione um produto para adicionar");
      return true;
    }
    if (!item.unidade) {
      notification.erroValidacao("Unidade do produto");
      return true;
    }
    if (!item.quantidade) {
      notification.erroValidacao("Quantidade do produto");
      return true;
    }
    if (!item.valor) {
      notification.erroValidacao("Valor Unitário do produto");
      return true;
    }
  };

  const handleAdicionarProduto = async (produto, item) => {
    const produtoIsValid = validarAddProduto(produto, item);
    if (produtoIsValid) return;
    if (nfSaidaProdutos.length == 1 && nfSaida.chaveAcessoReferencia) {
      return notification.alertaGenericos(
        "É permitido adicionar apenas um produto"
      );
    } else if (item?.itemEditado) {
      const produtoIndex = nfSaidaProdutos.findIndex(
        (obj) => obj.id == produto.id
      );
      const nfSaidaProdutosTemp = [
        ...nfSaidaProdutos.map((obj, index) => {
          if (index == produtoIndex) {
            return {
              ...obj,
              ...item,
              subtotal: item.quantidade * item.valor,
            };
          }
          return obj;
        }),
      ];
      setNfSaidaProdutos(nfSaidaProdutosTemp);
      setNfSaidaPagamentos([]);
      nfSaidaEntity.setChangePagamentos([]);
      nfSaidaEntity.setChangeItens(nfSaidaId, nfSaidaProdutosTemp);
      setNfSaida(nfSaidaEntity);
      setItem({});
    } else {
      const nfSaidaItem = new NfSaidaItem(
        item,
        nfSaida.nfSaidaItens?.length,
        operacaoFiscal,
        origensMercadoriasList,
        unidadeList
      );
      if (nfSaidaItem === null) {
        return notification.alertaGenericos("Produto sem imposto");
      }
      const nfSaidaProdutosTemp = [
        ...nfSaidaProdutos,
        {
          ...item,
          ...nfSaidaItem,
          produto: produto?.descricao,
          quantidade: item?.quantidade ?? 1,
          subtotal: item?.valor * (item?.quantidade ?? 1),
        },
      ];
      setNfSaidaProdutos(nfSaidaProdutosTemp);
      nfSaidaEntity.setChangeItens(nfSaidaId, nfSaidaProdutosTemp);
      setNfSaidaPagamentos([]);
      nfSaidaEntity.setChangePagamentos([]);
      setNfSaida(nfSaidaEntity);
      setItem({});
    }
    document.getElementById("produtoId").focus();
    setIsProdutoEdit(false);
    setProduto(null);
    setItem(null);
    setListaProdutos([]);
  };

  const handleRemoverProduto = async (id) => {
    const data = nfSaidaProdutos.filter((item) => item.id !== id);
    const produtoSelecionado = nfSaidaProdutos.find(
      (item) => item.id === id
    ).descricao;
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${produtoSelecionado}?`
    );
    if (retornoAlerta.isConfirmed) {
      setNfSaidaProdutos(data);
      nfSaidaEntity.setChangeItens(nfSaidaId, data);
      setNfSaidaPagamentos([]);
      nfSaidaEntity.setChangePagamentos([]);
      setNfSaida(nfSaidaEntity);
    }
  };

  const handleEditarProduto = async (id) => {
    const item = nfSaidaProdutos.find((item) => item.id === id);
    if (item) {
      setIsProdutoEdit(true);
    }
    setProduto(item);
    setItem({
      ...item,
      unidade: unidadeList.find(
        (unidade) =>
          unidade.codigo == item.unidade ||
          unidade.id == item.unidadeComercialVendaId
      )?.codigo,
      itemEditado: true,
    });
  };

  const handleViewImposto = async (id) => {
    const item = nfSaidaProdutos.find((item) => item.id === id);
    if (!item) return notification.alertaGenericos("Sem produto selecionado");
    let itemTemp = { ...item };
    if (!nfSaidaId && !item?.itemEditado) {
      const impostoModelo = await buscaImpostoProduto(
        itemTemp.grupoTributacaoId,
        nfSaida?.operacaoFiscalId
      );
      if (impostoModelo) {
        const nfSaidaItemImpostos = new NfSaidaItemImpostos(
          impostoModelo,
          item,
          nfSaida
        );
        itemTemp = { ...item, ...nfSaidaItemImpostos };
      }
    } else {
      itemTemp.id = nfSaidaEntity.nfSaidaItens[item.ordem - 1].id;
    }
    setProduto(itemTemp);
    buscarModeloTributacao(itemTemp.modeloTributacaoId);
    setImpostoProdutoDialog(true);
  };

  const buscaImpostoProduto = async (grupoTributarioId, operacaoFiscalId) => {
    setLoading(true);
    const filtro = {
      grupoTributarioId: grupoTributarioId,
      operacaoFiscalId,
    };
    const result =
      await gruposTributarioModelosTributacaoService.getAllFiltroAvancado(
        filtro
      );
    setLoading(false);
    if (!result.isAxiosError) {
      if (result.data.rows.length) {
        return result.data.rows[0];
      }
    } else {
      responseErros(result);
    }
    return null;
  };

  return (
    <Grid container className="position-relative">
      <Grid
        container
        spacing={2}
        className="mb-2"
        alignItems="center"
        columns={15}
      >
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="produtoId"
              name="produtoId"
              options={listaProdutos}
              autoHighlight
              disabled={nfSaidaId || !nfSaida?.operacaoFiscalId ? true : false}
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderOption={(props, option) => {
                let descricaoProduto = `${option?.codigo} - ${option?.descricao}`;
                return option ? (
                  <li {...props}>
                    <div className="d-flex justify-content-between w-100">
                      <div>{descricaoProduto}</div>
                      <div>{option?.estoqueProduto?.estoque}</div>
                    </div>
                  </li>
                ) : (
                  ""
                );
              }}
              getOptionLabel={(option) => {
                let descricaoProduto = `${option?.codigo} - ${option?.descricao}`;
                if (option?.codigoBarra) {
                  descricaoProduto = `${option?.codigoBarra} - ${option?.codigo} - ${option?.descricao}`;
                }
                return option ? descricaoProduto : "";
              }}
              filterSelectedOptions
              onChange={(_, newValue) =>
                handleChangeAutocompleteProduto(newValue)
              }
              value={produto}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"outlined"}
                  label="Buscar produto"
                  onKeyDown={(e) => onInputChangeProdutos(e)}
                  inputRef={inputRef}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Quantidade em estoque" placement="bottom">
            <TextField
              id="estoque"
              name="estoque"
              label="Estoque"
              variant="outlined"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              value={item?.estoqueAtual ? item.estoqueAtual : ""}
              disabled={true}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Unidade de venda" placement="bottom">
            <TextField
              id="unidade"
              name="unidade"
              label="Unidade"
              variant="outlined"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              value={item?.unidade ? item.unidade : ""}
              onChange={handleInputChange}
              disabled={true}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="quantidade"
            name="quantidade"
            label="Quantidade"
            variant={"outlined"}
            disabled={
              nfSaidaId || !produto || !nfSaida?.operacaoFiscalId ? true : false
            }
            margin="normal"
            type="number"
            InputProps={
              item?.fracionavel
                ? { inputProps: { min: 0.01, step: 0.01 } }
                : { inputProps: { min: 1, step: 1 } }
            }
            value={value.text(item?.quantidade)}
            onChange={(e) => {
              if (e.target.value <= 0) e.target.value = "";
              if (produto?.fracionavel) {
                e.target.value = Number(e.target.value);
              } else {
                e.target.value = Math.trunc(Number(e.target.value));
              }
              handleInputChange(e);
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valor"
            name="valor"
            label="Valor Unitário R$"
            value={item?.valor}
            variant={"outlined"}
            disabled={
              nfSaidaId ||
              !produto ||
              !nfSaida?.operacaoFiscalId ||
              !permissaoEditarPrecoVenda
            }
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="subTotal"
            name="subTotal"
            label="Total Item R$"
            variant={"outlined"}
            disabled={true}
            value={value.text(item?.valor * item?.quantidade)}
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={1} sx={{ mt: "0.5em" }}>
          {isProdutoEdit ? (
            <Button
              id="buttonAdicionar"
              variant="contained"
              disabled={nfSaidaId || !nfSaida?.operacaoFiscalId ? true : false}
              size="small"
              sx={{
                height: "35px",
                borderRadius: "50%",
                minWidth: "15px",
                boxShadow: "none",
              }}
              onClick={() => handleAdicionarProduto(produto, item)}
            >
              <i
                className="ph-bold ph-arrows-clockwise"
                style={{ color: "#fff", fontSize: 15 }}
              ></i>
            </Button>
          ) : (
            <Button
              id="buttonAdicionar"
              variant="contained"
              color="success"
              disabled={nfSaidaId || !nfSaida?.operacaoFiscalId ? true : false}
              size="small"
              sx={{
                height: "35px",
                borderRadius: "50%",
                minWidth: "15px",
                boxShadow: "none",
              }}
              onClick={() => handleAdicionarProduto(produto, item)}
            >
              <i
                className="ph-bold ph-plus"
                style={{ color: "#fff", fontSize: 15 }}
              ></i>
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid style={{ marginTop: 32 }} item xs={12}>
          <DataGrid
            style={{ zIndex: 1 }}
            rows={nfSaidaProdutos}
            columns={produtosColunas}
            rowCount={nfSaidaProdutos.length}
            deleteShow={!nfSaidaId}
            editShow={!nfSaidaId}
            imposto={operacaoFiscal?.modeloDocumento === "01" ? false : true}
            onClickViewImposto={handleViewImposto}
            onClickEdit={handleEditarProduto}
            onClickDelete={handleRemoverProduto}
          />
        </Grid>
      </Grid>
      <ProdutoSimilarDialog
        produto={produto}
        openProdutoSimilarDialog={openProdutoSimilarDialog}
        setOpenProdutoSimilarDialog={setOpenProdutoSimilarDialog}
        buscarProdutosSimilares={buscarProdutosSimilares}
        produtosColunasSimilares={produtosColunasSimilares}
        produtosSimilares={produtosSimilares}
      />
      <ImpostoProdutoDialog
        open={openImpostoProdutoDialog}
        setOpen={setImpostoProdutoDialog}
        produto={produto}
        setProduto={setProduto}
        origensMercadoriasList={origensMercadoriasList}
        situacaoIpiList={situacaoIpiList}
        enquadramentoLegalList={enquadramentoLegalList}
        gruposTributacaoList={gruposTributacaoList}
        csoSimplesList={csoSimplesList}
        modeloTributacao={modeloTributacao}
        nfSaida={nfSaida}
        nfSaidaId={nfSaidaId}
        nfSaidaProdutos={nfSaidaProdutos}
        setNfSaidaProdutos={setNfSaidaProdutos}
        nfSaidaEntity={nfSaidaEntity}
      />
    </Grid>
  );
};

export default NfSaidaItens;
