import { useRef, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "../../../../components";

const FiltroCustom = ({
  buscaFiltro,
  setBuscaFiltro,
  sendServer,
  columns,
  filtrarListaPorCodigo,
}) => {
  let colunaFiltroSimples = columns.find((column) => column.filtrar);
  const inputCodigo = useRef();
  const [tipoBusca, setTipoBusca] = useState(colunaFiltroSimples.field);
  const filtros = {
    orderBy: "updatedAt",
    order: "desc",
    page: "1",
  };

  const handleChange = (event) => {
    setTipoBusca(event.target.value);
  };

  const onSearchChangeCodigo = (event) => {
    if (
      event.key === "Enter" &&
      inputCodigo.current === document.activeElement
    ) {
      if (event.target?.value?.length > 2) {
        filtrarListaPorCodigo(event.target?.value);
      }
    }
  };

  const labelPesquisar = () => {
    return colunaFiltroSimples
      ? `Pesquisar por ${colunaFiltroSimples.headerName}`
      : "Pesquisar";
  };

  const pesquisar = (e) => {
    e.preventDefault();
    const tipoFiltro = "simples";
    filtros.page = "1";
    if (buscaFiltro) {
      let queryBusca = {};
      if (colunaFiltroSimples) {
        queryBusca[colunaFiltroSimples.field] = buscaFiltro;
      }
      const retorno = {
        tipoFiltro,
        filtros: {
          ...filtros,
          ...queryBusca,
        },
      };
      sendServer(retorno);
    } else {
      sendServer({ tipoFiltro, filtro: filtros });
    }
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <div>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={tipoBusca}
            onChange={handleChange}
          >
            <FormControlLabel
              value="codigo"
              control={<Radio />}
              label="Código"
            />
            <FormControlLabel
              value={colunaFiltroSimples.field}
              control={<Radio />}
              label={colunaFiltroSimples.headerName}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="p-2">
        {tipoBusca === "codigo" ? (
          <TextField
            id="pesquisaCodigo"
            label="Pesquisar por Codigo"
            variant="outlined"
            margin="normal"
            onKeyDown={(e) => onSearchChangeCodigo(e)}
            inputRef={inputCodigo}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      filtrarListaPorCodigo(
                        document.getElementById("pesquisaCodigo")?.value
                      );
                    }}
                  >
                    <i
                      className="ph-bold ph-magnifying-glass"
                      style={{ color: "#494C62", fontSize: 18 }}
                    ></i>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <form onSubmit={(e) => pesquisar(e)}>
            <TextField
              id="pesquisar"
              label={labelPesquisar()}
              fullWidth
              value={buscaFiltro}
              margin="normal"
              onChange={(e) => setBuscaFiltro(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={pesquisar}>
                      <i
                        className="ph-bold ph-magnifying-glass"
                        style={{ color: "#494C62", fontSize: 18 }}
                      ></i>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        )}
      </div>
    </div>
  );
};

export default FiltroCustom;
