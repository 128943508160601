import { useReactToPrint } from "react-to-print";
import { IconButton, Loading } from "../../../../components";
import { useRef, useState } from "react";
import MainPrintLayout from "./MainPrintLayout";
import { useImportContext } from "../../../../contexts/import.context";
import { voltar } from "../../../../utils";

const ButtonMenu = ({ id, data }) => {
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
        @page {
            size: auto;
            margin: 8mm;
        }
    `,
  });

  const escolhaImpressao = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      handlePrint();
      if (!id) {
        initialCollapses();
        voltar();
      }
    }, 1000);
    return;
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className={!id && "d-none"}>
        <div className="d-none">
          <div ref={componentRef}>
            <MainPrintLayout data={data} />
          </div>
        </div>
        <IconButton
          id="botaoMenuAction"
          onClick={escolhaImpressao}
          size="small"
          sx={{ marginLeft: 2 }}
        >
          <i className="ph-fill ph-printer" style={{ fontSize: 18 }}></i>
        </IconButton>
      </div>
      <Loading loading={loading} />
    </div>
  );
};

export default ButtonMenu;
