import SiafLiteApiRepository from "../../external/siafLiteApi";

class CaixaService {
  constructor() {
    this.urlBase = "/financeiro/caixa";
  }

  getRelatorios(data, bancoCaixaId, filter) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${data}/${bancoCaixaId}/relatorios`
    ).get(filter);
  }

  getTotaisDia(data, bancoCaixaId, filter) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${data}/${bancoCaixaId}/totais`
    ).get(filter);
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  caixaAberto(data, bancoCaixaId) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${data}/${bancoCaixaId}/aberto`
    ).get();
  }

  movimentar(id, movimentacaoCaixa) {
    return new SiafLiteApiRepository(`${this.urlBase}/${id}/movimentar`).post(
      movimentacaoCaixa
    );
  }

  getRelatorioDetalhado(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/detalhado`
    ).patch(body);
  }

  tranferirSaldo(movimentacaoCaixa) {
    return new SiafLiteApiRepository(`${this.urlBase}/transferir-saldo`).post(
      movimentacaoCaixa
    );
  }

  removerTransferenciaSaldo(codigo) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/remover-transferencia-saldo/${codigo}`
    ).patch();
  }

  removerSuprimento(codigo) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/remover-suprimento/${codigo}`
    ).patch();
  }

  removerSangria(codigo) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/remover-sangria/${codigo}`
    ).patch();
  }
}

export default CaixaService;
