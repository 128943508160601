import { Card, CardContent } from "../../../components";
import { CardHeader as CardHeaderMui } from "@mui/material";

const DashboardCard = ({ title, ActionIcon, chart, children }) => {
  return (
    <Card className={`p-2 rounded-4 ${!chart && "mainAtalhos"}`}>
      <CardHeaderMui
        title={<b className={chart ? "fw-bold" : "fw-medium"}>{title}</b>}
        action={ActionIcon}
      />
      <CardContent
        style={{ textTransform: "none" }}
        className={`d-flex flex-column pt-0 ${
          chart && "align-items-center pt-3"
        }`}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
