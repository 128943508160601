import { format } from "date-fns";
import { formatDate } from "../../../utils";

export function useFiltrosRelatoriosHandlers(
  setFiltrosAvancados,
  setFiltrosAplicadosImpressao
) {
  const onChangeInput = (event, name) => {
    const value = event.target.value || null;
    setFiltrosAvancados((prev) => ({ ...prev, [event.target.name]: value }));
    setFiltrosAplicadosImpressao((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeAutocomplete = (id, value, name) => {
    const newValue = value ? value.id : undefined;
    const displayValue = value
      ? value.nomeRazaoSocial ?? value.descricao
      : undefined;
    setFiltrosAvancados((prev) => ({ ...prev, [id]: newValue }));
    setFiltrosAplicadosImpressao((prev) => ({ ...prev, [name]: displayValue }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (d) => d instanceof Date && !isNaN(d);
    if (!date || !isValidDate(date)) date = null;

    if (date?.getFullYear() > 2000) {
      const formattedDate = formatDate.toSend(date);
      const formattedDisplay = format(date, "dd/MM/yyyy");

      setFiltrosAvancados((prev) => {
        const newFiltro = { ...prev, [id]: formattedDate };
        const isStart = id.endsWith("Start");
        const oppositeId = isStart
          ? id.replace("Start", "End")
          : id.replace("End", "Start");

        if (!newFiltro[oppositeId]) {
          newFiltro[oppositeId] = formattedDate;
        }

        const coluna = isStart ? id.slice(0, -5) : id.slice(0, -3);
        newFiltro.porIntervalo = [
          {
            coluna,
            de: newFiltro[`${coluna}Start`],
            ate: newFiltro[`${coluna}End`],
          },
        ];
        return newFiltro;
      });

      setFiltrosAplicadosImpressao((prev) => {
        const newImpressao = { ...prev, [name]: formattedDisplay };
        const oppositeName = name.includes("De")
          ? name.replace("De", "Até")
          : name.replace("Até", "De");

        if (!prev[oppositeName]) {
          newImpressao[oppositeName] = formattedDisplay;
        }
        return newImpressao;
      });
    }
  };

  const onChangeCheckbox = (event, name) => {
    setFiltrosAvancados((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked ?? null,
    }));
    setFiltrosAplicadosImpressao((prev) => ({
      ...prev,
      [name]: event.target.checked
        ? `${event.target.checked ? "Sim" : "Não"}`
        : null,
    }));
  };

  return {
    onChangeInput,
    onChangeAutocomplete,
    onChangeDate,
    onChangeCheckbox,
  };
}
