import { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
} from "../../../../components";
import {
  OperacaoFiscalService,
  PessoaService,
  MeioPagamentoService,
} from "../../../../services";
import { InputErros, value } from "../../../../helpers";
import { responseErros, timeoutBuscaAutocomplete } from "../../../../utils";
import { useFiltrosRelatoriosHandlers } from "../../components/use-filtros-relatorios-handlers";

const operacaoFiscalService = new OperacaoFiscalService();
const pessoaService = new PessoaService();
const meioPagamentoService = new MeioPagamentoService();

const FormFiltroAvancadoNfEntrada = ({
  filtrosAvancados,
  setFiltrosAvancados,
  setFiltrosAplicadosImpressao,
}) => {
  const inputRef = useRef();
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [fornecedorList, setFornecedorList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const { onChangeInput, onChangeAutocomplete, onChangeDate } =
    useFiltrosRelatoriosHandlers(
      setFiltrosAvancados,
      setFiltrosAplicadosImpressao
    );

  useEffect(() => {
    buscarOperacoesFiscais();
    buscarMeiosPagamento();
  }, []);

  const buscarOperacoesFiscais = () => {
    const filtrosOperacoesFiscais = {
      ativado: true,
      tipoOperacao: "ENTRADA",
      restritiva: true,
      porIntervalo: [],
      finalidade: [
        "NAO_SE_APLICA",
        "NFE_NORMAL",
        "NFE_COMPLEMENTAR",
        "NFE_AJUSTE",
      ],
    };
    operacaoFiscalService
      .getAllFiltroAvancado(filtrosOperacoesFiscais)
      .then((result) => {
        if (!result.isAxiosError) {
          setOperacaoFiscalList(result.data.rows);
        } else {
          responseErros(result);
        }
      });
  };

  const buscarMeiosPagamento = () => {
    meioPagamentoService.getAll({ ativado: true }).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarFornecedores = () => {
    const filtros = {
      nonPaginated: true,
      tiposPessoaId: 2,
    };
    pessoaService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setFornecedorList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const onInputChangeFornecedor = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      if (event.target?.value?.length >= 2) {
        const filtros = {
          nomeRazaoSocial: event.target.value,
          apelidoFantasia: event.target.value,
        };
        setLoadingAutoComplete(true);
        timeoutBuscaAutocomplete(buscarFornecedores(filtros));
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="numeroNota"
            name="Número-Nota"
            type="number"
            label="Nº Nota"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={(e) => onChangeInput(e, "Número-Nota")}
            value={value.text(filtrosAvancados?.numeroNota)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="serie"
            name="Série"
            type="number"
            label="Série"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={(e) => onChangeInput(e, "Série")}
            value={value.text(filtrosAvancados?.serie)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="fornecedorId"
              name="Fornecedor"
              options={fornecedorList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Fornecedor"
                  variant="outlined"
                  onKeyDown={onInputChangeFornecedor}
                  inputRef={inputRef}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("fornecedorId", value, "Fornecedor")
              }
              value={value.autoComplete(
                fornecedorList,
                filtrosAvancados?.fornecedorId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="operacaoFiscalId"
              name="Operação-Fiscal"
              options={operacaoFiscalList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option.descricao : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Operação Fiscal"
                  variant="outlined"
                  error={inputErros.get("operacaoFiscalId")}
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                onChangeAutocomplete(
                  "operacaoFiscalId",
                  newValue,
                  "Operação-Fiscal"
                );
              }}
              value={value.autoComplete(
                operacaoFiscalList,
                filtrosAvancados?.operacaoFiscalId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="meioPagamentoId"
              name="Meio-de-Pagamento"
              options={meioPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.descricao
                  ? `${option.id} - ${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meios de pagamento"
                  variant="outlined"
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete(
                  "meioPagamentoId",
                  value,
                  "Meio-de-Pagamento"
                )
              }
              value={value.autoComplete(
                meioPagamentoList,
                filtrosAvancados?.meioPagamentoId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoStart"
            name="Data-Emissão-De"
            label="Data Emissão De"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataEmissaoStart)}
            onChange={(date) =>
              onChangeDate("dataEmissaoStart", date, "Data-Emissão-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoEnd"
            name="Data-Emissão-Até"
            label="Data Emissão Até"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataEmissaoEnd)}
            onChange={(date) =>
              onChangeDate("dataEmissaoEnd", date, "Data-Emissão-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataChegadaStart"
            name="Data-Chegada-De"
            label="Data Chegada De"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataChegadaStart)}
            onChange={(date) =>
              onChangeDate("dataChegadaStart", date, "Data-Chegada-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataChegadaEnd"
            name="Data-Chegada-Até"
            label="Data Chegada Até"
            margin
            format="dd/MM/yyyy"
            value={value.date(filtrosAvancados?.dataChegadaEnd)}
            onChange={(date) =>
              onChangeDate("dataChegadaEnd", date, "Data-Chegada-Até")
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoNfEntrada;
