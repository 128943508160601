import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "../../../../../../components";

const formatData = (dataObj = {}) => {
  return Object.entries(dataObj).map(([name, value]) => ({
    name,
    y: value,
  }));
};

const FooterDashboardClienteGrade = ({ dataTotalizador }) => {
  const baseOptions = {
    chart: {
      type: "pie",
      backgroundColor: null,
      height: 200,
      width: 400,
      style: {
        fontFamily: "'Unica One', sans-serif",
      },
      plotBorderColor: "#606063",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "",
      },
    },
    legend: {
      enabled: true,
      itemStyle: {
        color: "#505053",
      },
      itemHoverStyle: {
        color: "#505053",
      },
      itemHiddenStyle: {
        color: "#606063",
      },
      title: {
        style: {
          color: "#505053",
        },
      },
    },
    plotOptions: {
      pie: {
        cursor: "pointer",
        dataLabels: {
          padding: 0,
          enabled: true,
          format: "<b>{point.name}</b>: {point.y}",
          style: {
            color: "#000000",
          },
        },
      },
    },
    series: [
      {
        name: "Quantidade",
        colorByPoint: true,
        data: [],
      },
    ],
  };

  let tamanhoOptions = {};
  _.merge(tamanhoOptions, baseOptions, {
    series: [
      {
        name: "Quantidade",
        data: formatData(dataTotalizador.totalizadorTamanho),
        colorByPoint: true,
      },
    ],
  });

  let corOptions = {};
  _.merge(corOptions, baseOptions, {
    series: [
      {
        name: "Quantidade",
        data: formatData(dataTotalizador.totalizadorCor),
        colorByPoint: true,
      },
    ],
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <CardHeader title="Produtos por Tamanho" />
            <HighchartsReact highcharts={Highcharts} options={tamanhoOptions} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <CardHeader title="Produtos por Cor" />
            <HighchartsReact highcharts={Highcharts} options={corOptions} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FooterDashboardClienteGrade;
