import {
  Grid,
  Tooltip,
  CurrencyInput,
  TextFielDecimalNumbers,
} from "../../../../components";

const FormPrecoCusto = ({
  produto,
  inputErros,
  handleInputChangeVenda,
  configuracoes,
}) => {
  const nextFocusInput = (event) => {
    let inputCount = 2;
    if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
      const { form } = event.target;
      const index = Array.from(form.elements).indexOf(event.target);
      let element = form.elements[index + inputCount];
      if (element?.disabled) {
        element = form.elements[index + inputCount + 2];
      }
      if (element) {
        element.focus();
      }
      event.preventDefault();
    }
  };

  return (
    <form>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <CurrencyInput
            id="precoCompra"
            name="precoCompra"
            label="Preço Compra"
            disabled={produto.tipo.id === "kit" ? true : false}
            error={inputErros.get("precoCompra")}
            onKeyDown={(e) => nextFocusInput(e)}
            value={produto.precoCompra ?? ""}
            onBlur={handleInputChangeVenda}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <Tooltip
            title={
              <>
                Preço de custo deve ser igual ou
                <br />
                maior que o preço de compra
              </>
            }
            arrow
            placement="bottom"
            enterTouchDelay={0}
            enterDelay={500}
          >
            <CurrencyInput
              id="precoCusto"
              name="precoCusto"
              label="Preço Custo"
              required
              value={produto.precoCusto ?? ""}
              onKeyDown={(e) => nextFocusInput(e)}
              onBlur={handleInputChangeVenda}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="precoMedio"
            name="precoMedio"
            label="Preço Médio"
            disabled={true}
            onKeyDown={(e) => nextFocusInput(e)}
            value={produto.precoMedio ?? ""}
            onBlur={handleInputChangeVenda}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip
            title={
              <>
                Lucro deve ser menor que 100%
                <br />
                em cálculo de margem inversa (MI)
              </>
            }
            arrow
            placement="bottom"
            enterTouchDelay={0}
            enterDelay={500}
          >
            <TextFielDecimalNumbers
              id="lucroDesejado"
              name="lucroDesejado"
              label="Lucro Desejado"
              variant="outlined"
              onKeyDown={(e) => nextFocusInput(e)}
              InputProps={{
                inputProps: {
                  max:
                    configuracoes?.margemCalculo === "CONVENCIONAL" && 100000,
                },
              }}
              value={produto.lucroDesejado ?? ""}
              onBlur={handleInputChangeVenda}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <CurrencyInput
            id="precoVenda"
            name="precoVenda"
            label="Preço Venda"
            error={inputErros.get("precoVenda")}
            value={produto.precoVenda ?? ""}
            onKeyDown={(e) => nextFocusInput(e)}
            onBlur={handleInputChangeVenda}
            required
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default FormPrecoCusto;
